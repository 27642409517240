import http from 'utils/request';

export default {
  fetchList(data) {
    return http.request('/theme/webThemePage', data);
  },
  update(data) {
    return http.request('/theme/webThemeUpdate', data);
  },
  add(data) {
    return http.request('/theme/webThemeInsert', data);
  },
  /**
   * 获取web主题详情
   * @param {Number} id web主题id
   */
  detail(id) {
    return http.request('/theme/webThemeDetail', { id });
  },
  /**
   * 删除web主题
   * @param {*} id 主题id
   * @param {*} handlerName 操作人名字
   */
  delete(id, handlerName) {
    return http.request('/theme/webDelete', {
      id,
      status: 'DEL',
      handlerName,
    })
  }
};
