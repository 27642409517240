import React from 'react';
import { Form, Button, message, Input, InputNumber, Spin } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'components/Select2';
import CommonEdit from '../../common/Edit';
import reaction from 'reaction';
import tradeSteelPrice from 'api/tradeSteelPrice';
import style from '../../page.module.scss';
import { funcType } from 'reaction/tradeSteelPrice';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16
  }
};

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 }
  }
};

const showFlagOptions = [
  { value: '1', label: '有效' },
  { value: '0', label: '无效' }
];
@connect(({ tradeSteelPrice, user }) => ({ tradeSteelPrice, user }))
@Form.create()
@withRouter
class VarietiesEdit extends CommonEdit {
  mode = 'edit';
  componentDidMount() {
    reaction.tradeSteelPrice
      .fetchData({ id: 0 }, funcType.fetchGoodsBigTypeNameList)
      .then(err => {
        this.setState({
          bigType: null
        });
      });
    this.setState({
      loading: true
    });
    this.loadData(`${this.props.match.params.id}`);
  }
  loadData(id) {
    reaction.tradeSteelPrice
      .fetchData({ id }, funcType.fetchVarietyDetail)
      .then(res => {
        reaction.tradeSteelPrice
          .fetchData(
            {
              id: res.goodsBigTypeId
            },
            funcType.fetchGoodsSmallTypeNameList
          )
          .then(() => {
            this.setState({
              loading: false
            });
          });
      });
  }
  handleChangeBigType = (value, option) => {
    if (value) {
      reaction.tradeSteelPrice
        .fetchData({ id: value }, funcType.fetchGoodsSmallTypeNameList)
        .then(res => {
          this.props.form.setFieldsValue({ goodsSmallTypeId: null });
        });
    }
  };
  submitData(data) {
    tradeSteelPrice
      .updateVariety(data)
      .then(err => {
        this.setState({
          submitting: false
        });
        message.success('编辑成功');
        this.props.history.replace('/steelpricevarieties');
      })
      .catch(err => {
        this.setState({
          submitting: false
        });
      });
  }
  render() {
    const {
      form: { getFieldDecorator },
      tradeSteelPrice: {
        varietyDetail = {},
        goodsBigTypeNameList = [],
        goodsSmallTypeNameList = []
      }
    } = this.props;

    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>编辑钢价品种</span>
        </div>
        <Spin spinning={this.state.loading}>
          <div className={`${style.form} page-form`}>
            <Form>
              {getFieldDecorator('id', {
                initialValue: varietyDetail.id
              })(<Input hidden />)}
              <Form.Item
                label="商品大类"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('goodsBigTypeId', {
                  initialValue: !this.state.loading
                    ? varietyDetail.goodsBigTypeId
                    : '',
                  rules: [
                    {
                      required: true,
                      message: '请选择商品大类'
                    }
                  ]
                })(
                  <Select
                    placeholder="请选择"
                    options={goodsBigTypeNameList}
                    style={{ width: 200 }}
                    showSearch
                    optionFilterProp="children"
                    onChange={this.handleChangeBigType}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="商品小类"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('goodsSmallTypeId', {
                  initialValue: !this.state.loading
                    ? varietyDetail.goodsSmallTypeId
                    : '',
                  rules: [
                    {
                      required: true,
                      message: '请选择商品小类'
                    }
                  ]
                })(
                  <Select
                    placeholder="请选择"
                    options={goodsSmallTypeNameList}
                    style={{ width: 200 }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="品名"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('name', {
                  initialValue: varietyDetail.name,
                  rules: [
                    {
                      required: true,
                      message: '请输入品名'
                    },
                    {
                      max: 20,
                      message: '最多输入20位字符'
                    }
                  ]
                })(<Input placeholder="请输入品名" style={{ width: 200 }} />)}
              </Form.Item>
              <Form.Item
                label="助记码"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('mnemonicCode', {
                  initialValue: varietyDetail.mnemonicCode,
                  rules: [
                    {
                      required: true,
                      message: '请输入助记码'
                    },
                    {
                      max: 10,
                      message: '最多输入10位字符'
                    }
                  ]
                })(<Input placeholder="请输入助记码" style={{ width: 200 }} />)}
              </Form.Item>
              <Form.Item
                label="状态"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('showFlag', {
                  initialValue: varietyDetail.showFlag
                })(<Select options={showFlagOptions} style={{ width: 200 }} />)}
              </Form.Item>
              <Form.Item
                label="前台显示排序"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('sort', {
                  initialValue: varietyDetail.sort,
                  rules: [
                    {
                      required: true,
                      message: '请输入排序'
                    }
                  ]
                })(
                  <InputNumber
                    min={0}
                    style={{ width: 200 }}
                    placeholder="排序"
                  />
                )}
              </Form.Item>
              <Form.Item label="" {...formTailLayout} required>
                <Button
                  type="primary"
                  className={style.button}
                  onClick={this.handlePublish}
                  loading={this.state.submitting}
                >
                  保存
                </Button>
                <Link
                  className={`ant-btn ${style.button} ${style['button-back']}`}
                  to="/steelpricevarieties"
                >
                  返回
                </Link>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </div>
    );
  }
}

export default VarietiesEdit;
