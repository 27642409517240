import user from './user';
import banner from './banner';
import category from './category';
import news from './news';
import notice from './notice';
import tradeSteelPrice from './tradeSteelPrice';
import common from './common';

export default {
  user, //
  banner,
  category,
  news,
  notice,
  common,
  tradeSteelPrice
};
