import React, { Component } from 'react';
import { Icon, Progress } from 'antd';
import Upload2 from 'components/Upload2';
import OSSUpload from 'components/OssUpload';
import api from 'api';

import style from './editor.module.scss';

export default class UploadVideo extends Component {
  state = {
    video: {},
    image: {},
  };
  saveFileInfo(name, file){
    this.setState({
      [name]: file,
    }, () => {
      file.url && this.triggerChange();
    });
  }
  removeFile(type) {
    this.setState({
      [type]: {},
    });
  }

  triggerChange() {
    const { onChange = () => {} } = this.props;
    const { video, image } = this.state;
    let isComplete = true;

    if (!video.url || !image.url) {
      isComplete = false;
    }

    onChange({
      data: {
        videoUrl: video.url,
        imageUrl: image.url
      },
      isComplete,
    });
  }
  renderProgress(file) {
    // 因为后台还需要在上传到阿里云服务器，所以上传完成还会等待一段时间...
    // const percent = file.percent >= 90 ? 90 : (file.percent || 0).toFixed(2);
    const percent = (file.percent || 0).toFixed(2);
    return (
      <div className={style.viewer}>
        <Progress
          type="circle"
          percent={+percent}
          format={() => {
            return `${percent}%`;
          }}
          status={file.status === 'error' ? 'exception' : file.status === 'done' ? 'success' : 'active'}
        >
        </Progress>
      </div>
    );
  }
  render() {
    const {
      video,
      image
    } = this.state;
    return (
      <div className={style.upload}>
        <div className={style.item}>
          {
            video.status === 'done' ? (
              <div className={style.viewer}>
                <a className={style.close} onClick={() => this.removeFile('video')}><Icon type="close" /></a>
                {
                  video.url === '' ? (
                    <p className={`${style.fileError}`}>文件【{video.name}】上传失败，{video.response.msg}</p>
                  ) : (
                    <video controls height="100%" width="100%">
                      <source src={video.url}></source>
                    </video>
                  )
                }
              </div>
            ) : (
              <div className={style.content}>
                <OSSUpload
                  action={api.common.uploadVideoFile}
                  showUploadList={false}
                  onChange={(v, f) => this.saveFileInfo('video', f)}
                  accept=".mp4"
                  listType="text"
                  buttonText="上传视频"
                  fileListRender={() => this.renderProgress(video)}
                  maxSize={500}
                  // ref={ref => this.oss = ref}
                >
                </OSSUpload>
                {/* <Upload2
                  action={api.common.uploadVideoFile}
                  showUploadList={false}
                  onChange={(v, f) => this.saveFileInfo('video', f)}
                  accept=".mp4"
                  listType="text"
                  buttonText="上传视频"
                  fileListRender={() => this.renderProgress(video)}
                  maxSize={500}
                  ref={ref => this.videoUploader = ref}
                >
                </Upload2> */}
              </div>
            )
          }
        </div>
        <div className={style.item}>
          {
            image.status === 'done' ? (
              <div className={`${style.viewer} ${style['viewer-image']}`}>
                <a className={style.close} onClick={() => this.removeFile('image')}><Icon type="close" /></a>
                {
                  image.url === '' ? (
                    <p className={`${style.fileError}`}>文件【{image.name}】上传失败，{image.response.msg}</p>
                  ) : (
                    <img src={image.url} alt="" width="100%" />
                  )
                }
              </div>
            ) : (
              <div className={style.content}>
                {
                  <Upload2
                    showUploadList={false}
                    onChange={(v, f) => this.saveFileInfo('image', f)}
                    listType="text"
                    buttonText="上传封面"
                    fileListRender={() => this.renderProgress(image)}
                    ref={ref => this.imageUploader = ref}
                  >
                  </Upload2>
                }

              </div>
            )
          }
        </div>
      </div>
    )
  }
}
