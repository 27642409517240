import http from 'utils/request';
import { API_PREFIX } from 'config/env';

export default {
  /**
   * 列表，分页
   * @param {Object} data 请求参数，pagination: { current, pageSize }
   */
  fetchList(data) {
    return http.request('/priceTrendThird/getThirdList', data);
  },
  /**
   * 合约号列表
   * @param {Object} data 请求参数
   */
  fetchCodesList(data) {
    return http.request('/priceTrendThird/selectAllCodes', data);
  },
  /**
   * 新增各港口的合约对比数据
   * @param {Object} data 提交的字段
   */
  add(data) {
    return http.request('/priceTrendThird/add', data);
  },
  /**
   * 修改或新增日其他各港口合约基差信息
   * @param {Object} data 提交数据
   */
  save(data) {
    return http.request('/priceTrendThird/update', data);
  },
  /**
   * 获取日其他各港口合约基差信息详情
   * @param {Number} id 日其他各港口合约基差信息id
   */
  detail(id) {
    return http.request('/priceTrendThird/getById', { id });
  },
  /**
   * 删除日其他各港口合约基差信息
   * @param {Number} id 日其他各港口合约基差信息id
   * @param {*} handlerName 操作人
   */
  delete(ids = [], handlerName) {
    return http.request('/priceTrendThird/deleteList', {
      ids,
      handlerName
    });
  },
  /**
   * 获取模版下载地址
   */
  fetchTemplateUrl() {
    return http.request('/priceTrendThird/download', {}, { method: 'get' });
  },
  importData: `${API_PREFIX}/priceTrendThird/import`
};
