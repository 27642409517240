import http from 'utils/request';
import { API_PREFIX } from 'config/env';

export default {
  uploadFile: `${API_PREFIX}/common/fileUpload`,
  uploadVideoFile: `${API_PREFIX}/common/videoUpload`,
  exportCategoryData: `${API_PREFIX}/common/exportCategory`,
  exportNewsData: `${API_PREFIX}/common/exportNews`,
  /**
   * 获取oss的sts配置信息
   */
  getOSSConfig(fileInfo) {
    return http.request('/common/getOssTemporaryOssAccessKey', {
      params: fileInfo
    });
  },
  delete(id, handlerName) {
    return http.request('/common/info/status', {
      id,
      status: 'DEL',
      handlerName,
    });
  },
  deleteTheme(id) {
    return http.request('/theme/delete', {
      id,
    });
  },
  setFilePermission(fileKey) {
    return http.request('/common/setFileAclPublicRead', {
      params: {
        fileKey
      }
    });
  }
}
