import React from 'react';
import { Form, Input, Button, DatePicker, Modal, message, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeader from 'modules/PageHeader';
import FormItem from 'components/FormItem';
import Table from 'components/Table2';
import WrapAuth from 'components/WrapAuth';
import CommonList from 'pages/common/List';
import { path } from 'config';
import { resolveTimeText } from 'utils';
import reaction from 'reaction';
import ImportData from 'modules/ImportData';
import Select from 'components/Select2';
import commonStyle from 'pages/page.module.scss';

import style from './index.module.scss';
import purchasePorts from 'api/purchase-ports';
import DownloadTemplate from 'modules/DownloadTemplate';

/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */
const { Column } = Table;
const { FormItemGroup } = FormItem;
const { confirm } = Modal;

const { RangePicker } = DatePicker;
const priceTrendThird = path.cms.priceTrendThird;

@connect(({ purchasePorts, user }) => ({ purchasePorts, user }))
@Form.create()
class PortsPriceInfo extends CommonList {
  loadData(params) {
    if (params.createTime) {
      params.createTime = params.createTime.format('YYYY-MM-DD');
    }
    reaction.purchasePorts.fetchCodes();
    reaction.purchasePorts.fetchList(params).then(err => {
      this.setState({
        loading: false
      });
    });
  }
  handleDelete(id) {
    confirm({
      title: '确定删除该数据吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        reaction.purchasePorts
          .delete([id], this.props.user.username)
          .then(() => {
            message.success('删除成功');
            this.reloadData(!this.props.purchasePorts.list.length);
          });
      }
    });
  }
  render() {
    const {
      form: { getFieldDecorator },
      purchasePorts: { list = [], pagination, codesList }
    } = this.props;
    return (
      <div className="page-inner">
        <PageHeader title="PB粉各港口现货与主力合约基差">
          <WrapAuth path={priceTrendThird.add.path}>
            <Link to="/purchase-ports/add" className="page-btn">
              +&nbsp;新增
            </Link>
          </WrapAuth>
        </PageHeader>
        <div className={commonStyle.filter}>
          <FormItemGroup>
            <FormItem label="价格日期：" style={{ marginRight: 60 }}>
              {getFieldDecorator('rangeTime')(
                <RangePicker
                  style={{ width: 260 }}
                  onChange={this.handleChangeDate}
                  placeholder={['开始日期', '结束日期']}
                  className="range-date-picker"
                />
              )}
            </FormItem>
            <FormItem label="创建日期：" style={{ marginRight: 60 }}>
              {getFieldDecorator('createTime')(
                <DatePicker style={{ width: 180 }} />
              )}
            </FormItem>
            <FormItem label="创建人：" labelWidth={58}>
              {getFieldDecorator('createUser', {
                initialValue: ''
              })(
                <Input
                  style={{ width: 180 }}
                  maxLength={10}
                  placeholder="请输入发布者名称"
                />
              )}
            </FormItem>
          </FormItemGroup>
          <FormItemGroup>
            <FormItem label="合约号：" labelWidth={70}>
              {getFieldDecorator('basePriceCode', {})(
                <Select
                  width="100%"
                  placeholder="请选择合约号"
                  className="select2"
                  style={{ width: 260 }}
                  showSearch
                  optionFilterProp="children"
                  options={codesList}
                  filterHidden={false}
                />
              )}
            </FormItem>
            <div className={commonStyle.right}>
              <Button type="primary" onClick={this.handleSearchData}>
                查询
              </Button>
              &nbsp;
              <Button type="primary" onClick={this.resetFormAndReload}>
                重置
              </Button>
            </div>
          </FormItemGroup>
        </div>
        <div className={commonStyle['table-operations']}>
          <WrapAuth path={priceTrendThird.import.path}>
            <ImportData
              action={purchasePorts.importData}
              onChange={this.handleSearchData}
            />
          </WrapAuth>
          <WrapAuth path={priceTrendThird.download.path}>
            <DownloadTemplate loadData={reaction.purchasePorts.fetchTemplate} />
          </WrapAuth>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={list}
            loading={false}
            rowKey="id"
            pagination={this.getPageConfig(pagination)}
            borderd
          >
            <Column
              title="序号"
              key="id"
              align="center"
              width={40}
              render={(text, record, index) => {
                return index + (this.state.pagination.current - 1) * 10 + 1;
              }}
            />
            <Column
              title="时间"
              key="dateTime"
              dataIndex="dateTime"
              width={80}
              align="center"
            />
            <Column
              title="合约号"
              key="basePriceCode"
              dataIndex="basePriceCode"
              width={60}
              align="center"
            />
            <Column
              title="合约价"
              key="basePrice"
              dataIndex="basePrice"
              width={60}
              align="center"
            />
            <Column
              title="日照港PB粉价格"
              key="priceTwo"
              dataIndex="priceTwo"
              width={100}
              align="center"
            />
            <Column
              title="差价"
              key="priceTwoSpread"
              dataIndex="priceTwoSpread"
              width={45}
              align="center"
            />
            <Column
              title="曹妃甸港PB粉价格"
              key="priceThree"
              dataIndex="priceThree"
              width={105}
              align="center"
            />
            <Column
              title="差价"
              key="priceThreeSpread"
              dataIndex="priceThreeSpread"
              width={45}
              align="center"
            />
            <Column
              title="创建人"
              key="createUser"
              dataIndex="createUser"
              width={80}
              align="center"
            />
            <Column
              title="创建日期"
              key="createTime"
              dataIndex="createTime"
              align="center"
              width={80}
              render={time => resolveTimeText(time)}
            />
            <Column
              title="修改人"
              key="updateUser"
              dataIndex="updateUser"
              align="center"
              width={80}
            />
            <Column
              title="修改日期"
              key="updateTime"
              dataIndex="updateTime"
              align="center"
              width={84}
              render={time => (time ? resolveTimeText(time) : '--')}
            />
            <Column
              title="操作"
              key="handler"
              align="center"
              fixed="right"
              width={80}
              className={style.operateTd}
              style={{ padding: 0 }}
              render={row => {
                return (
                  <React.Fragment>
                    <WrapAuth path={priceTrendThird.update.path}>
                      <Link
                        className="link-handle"
                        to={`/purchase-ports/edit/${row.id}`}
                      >
                        编辑
                      </Link>
                    </WrapAuth>
                    <WrapAuth path={priceTrendThird.delete.path}>
                      <a
                        className="link-handle"
                        onClick={() => this.handleDelete(row.id)}
                        href="javascript:;"
                      >
                        删除
                      </a>
                    </WrapAuth>
                  </React.Fragment>
                );
              }}
            />
          </Table>
        </Spin>
      </div>
    );
  }
}

export default PortsPriceInfo;
