import React from 'react';
import { Form, Input, Button, TreeSelect, InputNumber } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'components/Select2';
import CommonEdit from '../common/Edit';
// import Editor from 'components/Editor';
// import TextArea from 'components/TextArea';
// import BraftEditor from 'braft-editor'
import reaction from 'reaction';
// import Editor from 'components/Editor';
import { platformsForHelp, helpStatusFilterOptions } from 'config';
import { resolveTreeNodes } from 'utils';
import style from '../page.module.scss';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
}

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

@connect(({ help, user, category }) => ({ help, user, category }))
@Form.create()
class HelpEdit extends CommonEdit {
  mode = 'edit';
  loadData(id) {
    this.loadDetailData(id);
  }
  loadDetailData(id) {
    reaction.help.fetchDetail(id).then(({ platform }) => {
      this.setState({
        loading: false
      });
      this.loadCategories(platform || null, 'HELP')
    });
  }
  clear() {
    reaction.help.clear();
  }
  submitData(data) {
    reaction.help.update(data).then(res => {
      this.setState({
        submitting: false,
      });
      this.props.history.replace('/help');
    }).catch(err => {
      this.setState({
        submitting: false,
      });
    });
  }
  handlePlatformChange = (v) => {
    this.props.form.setFieldsValue({
      categoryIds: undefined,
    });
    this.loadCategories(v, 'HELP');
  }
  loadCategories(platform = null, categoryType = null) {
    reaction.category.fetchNodes({
      parentId: 0,
      showSub: true,
      platform,
      categoryType
    });
  }
  getTotalNodes (data) {
    return resolveTreeNodes(data);
  }
  getRenderContent() {
    const { category: { nodes = [] } } = this.props;
    const treeNodes = this.getTotalNodes(nodes); // eslint-disable-line
    console.log(treeNodes)
    let {
      form: {
        getFieldDecorator,
      },
      help: {
        detail,
      },
    } = this.props;
    const {
      submitting,
      loading,
    } = this.state;
    detail = detail || {};
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>编辑帮助信息</span>
        </div>
        <div className={`${style.form} page-form`}>
          {
            getFieldDecorator('id', {
              initialValue: detail.id,
            })(
              <Input hidden />
            )
          }
          <Form.Item label="标题" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('title', {
              initialValue: detail.title,
              rules: [
                {
                  required: true,
                  message: '请输入标题',
                },
              ],
            })(<Input placeholder="请输入标题" style={{ width: 220 }} maxLength={ 10 }/>)}
          </Form.Item>
          <Form.Item
            label="所属平台"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('platform', {
                initialValue: detail.platform || undefined,
                rules: [{
                  required: true,
                  message: '请选择所属平台',
                }],
              })(
                <Select
                  placeholder="请选择"
                  options={platformsForHelp}
                  style={{ width: 220 }}
                  onChange={this.handlePlatformChange}
                />
              )
            }
          </Form.Item>
          <Form.Item label="所属栏目" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('categoryId', {
              initialValue: detail.categoryId,
              rules: [
                {
                  required: true,
                  message: '请选择栏目',
                },
              ],
            })(
              <TreeSelect disabled={!nodes.length} style={{ width: 220 }} treeData={treeNodes} placeholder="请选择" />
            )}
          </Form.Item>
          <Form.Item label="状态" {...formItemLayout} required>
            {getFieldDecorator('status', {
              initialValue: detail.status,
              rules: [
                {
                  required: true,
                  message: '请选择状态',
                },
              ],
            })(<Select style={{ width: 220 }} placeholder="请选择" options={helpStatusFilterOptions} />)}
          </Form.Item>
          <Form.Item label="排序" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('sorted', {
              initialValue: detail.sorted,
              rules: [
                {
                  required: true,
                  message: '请输入排序号',
                },
              ],
            })(<InputNumber max={999} min={0} placeholder="请输入排序号" style={{ width: 220 }} maxLength={ 20 }/>)}
          </Form.Item>
          {!loading ? this.renderEditor(detail.content, '帮助内容') : null}
          <Form.Item
            label=""
            {...formTailLayout}
            required
          >
            <Button
              type="primary"
              className={style['button']}
              onClick={() => this.handlePublish(this.props.form)}
              loading={submitting}
            >保存</Button>
            <Link className={`${style.button} ${style['button-back']}`} to="/help">返回</Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default HelpEdit;
