import http from 'utils/request';
import { API_PREFIX } from 'config/env';

export default {
  /**
   * 列表，分页
   * @param {Object} data 请求参数，pagination: { current, pageSize }
   */
  fetchList(data) {
    return http.request('/otherPriceTrend/getOtherList', data);
  },
  /**
   * 新增其他各品类的价格信息
   * @param {Object} data 提交的数据
   */
  add(data) {
    return http.request('/otherPriceTrend/add', data);
  },
  /**
   * 修改或新增其他港口价格信息
   * @param {Object} data 提交数据
   */
  save(data) {
    return http.request('/otherPriceTrend/update', data);
  },
  /**
   * 获取其他港口价格信息详情
   * @param {Number} id 其他港口价格信息id
   */
  detail(id) {
    return http.request('/otherPriceTrend/getById', { id });
  },
  /**
   * 删除其他港口价格信息
   * @param {Number} id 其他港口价格信息id
   * @param {*} handlerName 操作人
   */
  delete(ids = [], handlerName) {
    return http.request('/otherPriceTrend/deleteList', {
      ids,
      handlerName
    });
  },
  /**
   * 获取模版下载地址
   */
  fetchTemplateUrl() {
    return http.request('/otherPriceTrend/download', {}, { method: 'get' });
  },
  importData: `${API_PREFIX}/otherPriceTrend/import`
};
