import React from 'react';
import {
  Form,
  Button,
  message,
  InputNumber,
  DatePicker,
  Input,
  Spin
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'components/Select2';
import CommonEdit from '../../common/Edit';
import reaction from 'reaction';
import { funcType } from 'reaction/tradeSteelPrice';
import tradeSteelPrice from 'api/tradeSteelPrice';
import style from '../../page.module.scss';
import moment from 'moment';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16
  }
};

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 }
  }
};

@connect(({ tradeSteelPrice, user }) => ({ tradeSteelPrice, user }))
@Form.create()
@withRouter
class PriceEdit extends CommonEdit {
  mode = 'edit';
  componentDidMount() {
    reaction.tradeSteelPrice
      .fetchData({ id: 0 }, funcType.fetchGoodsBigTypeNameList)
      .then(err => {
        this.setState({
          bigType: null
        });
      });
    this.setState({
      loading: true
    });
    this.loadData(`${this.props.match.params.id}`);
  }
  loadData(id) {
    //查询详情
    reaction.tradeSteelPrice
      .fetchData({ id }, funcType.fetchPriceDetail)
      .then(res => {
        // 查询商品小类
        reaction.tradeSteelPrice
          .fetchData(
            {
              id: res.goodsBigTypeId
            },
            funcType.fetchGoodsSmallTypeNameList
          )
          .then(() => {
            // 查询商品品名
            reaction.tradeSteelPrice
              .fetchData(
                {
                  id: res.goodsSmallTypeId
                },
                funcType.fetchGoodsNameList
              )
              .then(data => {
                let filterData = data.filter(
                  item => item.value === res.tradeId
                );
                if (filterData.length === 0) {
                  reaction.tradeSteelPrice.appendGoodsNameList({
                    value: res.tradeId,
                    label: res.name
                  });
                }
                this.setState({
                  loading: false
                });
              });
          });
      });
  }
  handleChangeBigType = (value, option) => {
    if (value) {
      reaction.tradeSteelPrice
        .fetchData({ id: value }, funcType.fetchGoodsSmallTypeNameList)
        .then(res => {
          reaction.tradeSteelPrice.resetGoodsNameList().then(() => {
            this.props.form.setFieldsValue({
              goodsSmallTypeId: null,
              tradeId: null
            });
          });
        });
    }
  };
  handleChangeSmallType = (value, option) => {
    if (value) {
      reaction.tradeSteelPrice
        .fetchData({ id: value }, funcType.fetchGoodsNameList)
        .then(res => {
          // this.props.form.resetFields(['tradeId']);
          this.props.form.setFieldsValue({
            tradeId: null
          });
        });
    }
  };
  submitData(data) {
    data.marketTime = data.marketTime.format('YYYY-MM-DD');
    tradeSteelPrice
      .updatePrice(data)
      .then(err => {
        this.setState({
          submitting: false
        });
        message.success('编辑成功');
        this.props.history.replace('/steelpricedata');
      })
      .catch(err => {
        this.setState({
          submitting: false
        });
      });
  }
  render() {
    const {
      form: { getFieldDecorator },
      tradeSteelPrice: {
        priceDetail = {},
        goodsBigTypeNameList = [],
        goodsSmallTypeNameList = [],
        goodsNameList = []
      }
    } = this.props;

    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>编辑钢价数据</span>
        </div>
        <Spin spinning={this.state.loading}>
          {' '}
          <div className={`${style.form} page-form`}>
            <Form>
              {getFieldDecorator('id', {
                initialValue: priceDetail.id
              })(<Input hidden />)}
              <Form.Item
                label="商品大类"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('goodsBigTypeId', {
                  initialValue: !this.state.loading
                    ? priceDetail.goodsBigTypeId
                    : '',
                  rules: [
                    {
                      required: true,
                      message: '请选择商品大类'
                    }
                  ]
                })(
                  <Select
                    placeholder="请选择"
                    options={goodsBigTypeNameList}
                    style={{ width: 200 }}
                    showSearch
                    optionFilterProp="children"
                    onChange={this.handleChangeBigType}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="商品小类"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('goodsSmallTypeId', {
                  initialValue: !this.state.loading
                    ? priceDetail.goodsSmallTypeId
                    : '',
                  rules: [
                    {
                      required: true,
                      message: '请选择商品小类'
                    }
                  ]
                })(
                  <Select
                    placeholder="请选择"
                    showSearch
                    optionFilterProp="children"
                    options={goodsSmallTypeNameList}
                    onChange={this.handleChangeSmallType}
                    style={{ width: 200 }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="品名"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('tradeId', {
                  initialValue: !this.state.loading ? priceDetail.tradeId : '',
                  rules: [
                    {
                      required: true,
                      message: '请选择品名'
                    }
                  ]
                })(
                  <Select
                    placeholder="请选择"
                    showSearch
                    optionFilterProp="children"
                    options={goodsNameList}
                    style={{ width: 200 }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="价格（元/吨）"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('guidePrice', {
                  initialValue: priceDetail.guidePrice,
                  rules: [
                    {
                      required: true,
                      message: '请输入价格'
                    }
                  ]
                })(
                  <InputNumber
                    placeholder="请输入价格"
                    style={{ width: 200 }}
                    min={0}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="日期"
                labelWidth={72}
                {...formItemLayout}
                required
              >
                {getFieldDecorator('marketTime', {
                  initialValue: moment(
                    priceDetail.marketTime
                      ? priceDetail.marketTime.substr(0, 10)
                      : 0
                  ),
                  rules: [
                    {
                      required: true,
                      message: '请选择日期'
                    }
                  ]
                })(<DatePicker style={{ width: 200 }} />)}
              </Form.Item>
              <Form.Item label="" {...formTailLayout} required>
                <Button
                  type="primary"
                  className={style.button}
                  onClick={this.handlePublish}
                  loading={this.state.submitting}
                >
                  保存
                </Button>
                <Link
                  className={`ant-btn ${style.button} ${style['button-back']}`}
                  to="/steelpricedata"
                >
                  返回
                </Link>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </div>
    );
  }
}

export default PriceEdit;
