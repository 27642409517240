import React, { useState } from 'react';
import { Modal } from 'antd';

import styles from './viewer.module.scss';

function ImageViewer({ name, src, children, ...props }) {

  const [ visible, setVisible ] = useState(false);

  return (
    <React.Fragment>
      <Modal centered visible={visible} footer={null} onCancel={() => setVisible(false)} style={{ maxHeight: '80%' }} width="80%" className={`${styles.wrapper ? styles.wrapper : ''} img-viewer`}>
        <img alt="" title={name} style={{ width: '100%' }} src={src} className={styles.innerImg} />
      </Modal>
      {
        children ? (
          <span onClick={() => setVisible(true)}>{children}</span>
        ) : <img {...props} alt="" src={src} className={styles.img} onClick={() => setVisible(true)} />
      }
    </React.Fragment>
  )
}

export default ImageViewer;
