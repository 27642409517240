import React from 'react';
import { Form, Button, message, Radio } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Select from 'components/Select2';
import CommonEdit from '../common/Edit';
// import Editor from 'components/Editor';
import reaction from 'reaction';
import { noticePages, newPageStatusOptions } from 'config';

import style from '../page.module.scss';

// const { Group: CheckboxGroup } = Checkbox;

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8,
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
};

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
};

@connect(({ user }) => ({ user }))
@Form.create()
@withRouter
class NoticeAdd extends CommonEdit {
  mode = 'add';
  submitData (data) {
    reaction.notice
      .add(data)
      .then((res) => {
        this.setState({
          submitting: false,
        });
        message.success('发布成功');
        this.props.history.replace('/notice');
      })
      .catch((err) => {
        this.setState({
          submitting: false,
        });
      });
  }
  render () {
    let { form: { getFieldDecorator } } = this.props;
    const { submitting, startTime } = this.state;
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>添加公告</span>
          {/* <span className={style.crm}>添加公告<span className={style.split}>/</span><Link to="/notice">公告列表</Link></span> */}
        </div>
        <div className={`${style.form} page-form`}>
          <Form.Item label="所属平台" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('positions', {
              rules: [
                {
                  required: true,
                  message: '请选择所属平台',
                },
              ],
            })(<Select options={noticePages} mode="multiple" style={{ width: 520 }} />)}
          </Form.Item>
          {this.renderTitle()}
          <Form.Item label="公告类型" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('noticeType', {
              initialValue: 'POPUPS'
            })(
              <Radio.Group>
                <Radio value="POPUPS">弹窗</Radio>
                <Radio value="NON_POPUPS">非弹窗</Radio>
              </Radio.Group>,
            )}
          </Form.Item>
          <Form.Item label="状态" {...formItemLayout} required>
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: '请选择状态',
                },
              ],
            })(<Select style={{ width: 195 }} placeholder="请选择" options={newPageStatusOptions} />)}
          </Form.Item>
          {this.renderStartTime()}
          {this.renderEndTime(startTime, '', { key: 'expireTime', title: '下架时间' })}
          {this.renderEditor()}
          <Form.Item label="" {...formTailLayout} required>
            <Button
              type="primary"
              className={style['button']}
              onClick={() => this.handlePublish(this.props.form)}
              loading={submitting}
            >
              发布
            </Button>
            <Link className={`${style.button} ${style['button-back']}`} to="/notice">
              返回
            </Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default NoticeAdd;
