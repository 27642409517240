import http from 'utils/request';

export default {
  fetchList(data) {
    return http.request('/theme/appThemePage', data);
  },
  update(data) {
    return http.request('/theme/appThemeUpdate', data);
  },
  add(data) {
    return http.request('/theme/appThemeInsert', data);
  },
  /**
   * 获取app主题详情
   * @param {Number} id app主题id
   */
  detail(id) {
    return http.request('/theme/appThemeDetail', { id });
  },
  /**
   * 删除主题
   * @param {Number} id 主题id
   * @param {String} handlerName 操作人
   */
  delete(id, handlerName) {
    return http.request('/theme/appDelete', {
      id,
      status: 'DEL',
      handlerName,
    });
  }
};
