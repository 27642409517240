import React from 'react';
import { datePickerConfig } from 'config';

export default class CommonList extends React.Component {
    otherState = {};
    state = {
        ...this.otherState,
        pagination: {
            current: 1,
            pageSize: 10
        },
        params: {},
        loading: false,
        loadingDetail: false,
        selectArr: []
    };
    componentDidMount() {
        this.handleSearchData();
    }
    componentWillUnmount() {
        this.clear();
    }
    clear() { }

    reloadData(isCheckLastItem) {
        let {
            pagination: { pageSize, current },
            params
        } = this.state;
        if (isCheckLastItem && pageSize !== 1) {
            // 在删除时重新加载数据后，如果当前页只有一条数据，那么删除了这一页就应该没有数据了，所以应该查询上一页的数据...
            current -= 1;
        }
        this.setState({
            loading: true
        });
        this.loadData({
            pagination: {
                pageSize,
                current
            },
            ...params
        });
    }
    resetFormAndReload = () => {
        this.props.form.resetFields();
        this.handleSearchData();
    };
    handleSearchData = () => {
        const { form } = this.props;
        const { rangeTime = [], ...data } = form.getFieldsValue();
        const [startTime, endTime] = rangeTime;
        this.setState(
            {
                params: {
                    ...data,
                    startTime: startTime
                        ? `${startTime.format(datePickerConfig.formateDate)} 00:00:00`
                        : null,
                    endTime: endTime
                        ? `${endTime.format(datePickerConfig.formateDate)} 23:59:59`
                        : null
                },
                pagination: {
                    pageSize: 10,
                    current: 1
                }
            },
            () => this.reloadData()
        );
    };
    handleChangePage = (current, pageSize) => {
        this.setState(
            {
                pagination: {
                    current,
                    pageSize
                }
            },
            () => this.reloadData()
        );
    };
    getPageConfig = pagination => {
        return {
            ...pagination,
            onChange: this.handleChangePage
        };
    };
}
