import React from 'react';
import { Form, Button, message, InputNumber, DatePicker } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'components/Select2';
import CommonEdit from '../../common/Edit';
import reaction from 'reaction';
import { funcType } from 'reaction/tradeSteelPrice';
import tradeSteelPrice from 'api/tradeSteelPrice';
import style from '../../page.module.scss';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16
  }
};

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 }
  }
};

@connect(({ tradeSteelPrice, user }) => ({ tradeSteelPrice, user }))
@Form.create()
@withRouter
class PriceAdd extends CommonEdit {
  mode = 'add';
  componentDidMount() {
    reaction.tradeSteelPrice
      .fetchData({ id: 0 }, funcType.fetchGoodsBigTypeNameList)
      .then(err => {
        this.setState({
          bigType: null
        });
      });
    reaction.tradeSteelPrice.resetGoodsSmallTypeNameList();
    reaction.tradeSteelPrice.resetGoodsNameList();
  }
  handleChangeBigType = (value, option) => {
    if (value) {
      reaction.tradeSteelPrice
        .fetchData({ id: value }, funcType.fetchGoodsSmallTypeNameList)
        .then(res => {
          reaction.tradeSteelPrice.resetGoodsNameList().then(() => {
            this.props.form.resetFields(['goodsSmallTypeId', 'tradeId']);
          });
        });
    }
  };
  handleChangeSmallType = (value, option) => {
    if (value) {
      reaction.tradeSteelPrice
        .fetchData({ id: value }, funcType.fetchGoodsNameList)
        .then(res => {
          this.props.form.resetFields(['tradeId']);
        });
    }
  };
  submitData(data) {
    data.marketTime = data.marketTime.format('YYYY-MM-DD');
    tradeSteelPrice
      .addPrice(data)
      .then(err => {
        this.setState({
          submitting: false
        });
        message.success('添加成功');
        this.props.history.replace('/steelpricedata');
      })
      .catch(err => {
        // this.props.form.setFields({
        //   name: {
        //     errors: [new Error('forbid ha')]
        //   }
        // });
        this.setState({
          submitting: false
        });
      });
  }
  render() {
    const {
      form: { getFieldDecorator },
      tradeSteelPrice: {
        goodsBigTypeNameList = [],
        goodsSmallTypeNameList = [],
        goodsNameList = []
      }
    } = this.props;

    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>新建钢价数据</span>
        </div>
        <div className={`${style.form} page-form`}>
          <Form>
            <Form.Item
              label="商品大类"
              labelWidth={72}
              {...formItemLayout}
              required
            >
              {getFieldDecorator('goodsBigTypeId', {
                rules: [
                  {
                    required: true,
                    message: '请选择商品大类'
                  }
                ]
              })(
                <Select
                  placeholder="请选择"
                  options={goodsBigTypeNameList}
                  style={{ width: 200 }}
                  showSearch
                  optionFilterProp="children"
                  onChange={this.handleChangeBigType}
                />
              )}
            </Form.Item>
            <Form.Item
              label="商品小类"
              labelWidth={72}
              {...formItemLayout}
              required
            >
              {getFieldDecorator('goodsSmallTypeId', {
                rules: [
                  {
                    required: true,
                    message: '请选择商品小类'
                  }
                ]
              })(
                <Select
                  placeholder="请选择"
                  showSearch
                  optionFilterProp="children"
                  options={goodsSmallTypeNameList}
                  onChange={this.handleChangeSmallType}
                  style={{ width: 200 }}
                />
              )}
            </Form.Item>
            <Form.Item
              label="品名"
              labelWidth={72}
              {...formItemLayout}
              required
            >
              {getFieldDecorator('tradeId', {
                rules: [
                  {
                    required: true,
                    message: '请选择品名'
                  }
                ]
              })(
                <Select
                  placeholder="请选择"
                  showSearch
                  optionFilterProp="children"
                  options={goodsNameList}
                  style={{ width: 200 }}
                />
              )}
            </Form.Item>
            <Form.Item
              label="价格（元）"
              labelWidth={72}
              {...formItemLayout}
              required
            >
              {getFieldDecorator('guidePrice', {
                initialValue: null,
                rules: [
                  {
                    required: true,
                    message: '请输入价格'
                  }
                ]
              })(
                <InputNumber
                  placeholder="请输入价格"
                  style={{ width: 200 }}
                  min={0}
                />
              )}
            </Form.Item>
            <Form.Item
              label="日期"
              labelWidth={72}
              {...formItemLayout}
              required
            >
              {getFieldDecorator('marketTime', {
                initialValue: 0,
                rules: [
                  {
                    required: true,
                    message: '请选择日期'
                  }
                ]
              })(<DatePicker style={{ width: 200 }} />)}
            </Form.Item>
            <Form.Item label="" {...formTailLayout} required>
              <Button
                type="primary"
                className={style.button}
                onClick={this.handlePublish}
                loading={this.state.submitting}
              >
                保存
              </Button>
              <Link
                className={`ant-btn ${style.button} ${style['button-back']}`}
                to="/steelpricedata"
              >
                返回
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default PriceAdd;
