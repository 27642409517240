import 'react-app-polyfill/ie9';
import 'core-js/es6/weak-map'; // mobx
import 'core-js/fn/array/values';
import 'core-js/fn/array/is-array';
import 'core-js/fn/array/includes';
import 'core-js/fn/string/includes';
import 'core-js/es6/map';
import 'core-js/es6/index';
import 'core-js/fn/array/index';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './serviceWorker';
import './index.scss';
// import $ from 'jquery';
// window.jQuery = window.$ = $;

// register({});
unregister();

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
document.body.setAttribute('version', 'v1.1');

// $('body').addClass('test-class');
