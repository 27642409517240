import http from 'utils/request';

export default {
  /**
   * 列表，分页
   * @param {Object} data 请求参数，包含parentId, showSub，pagination: { current, pageSize }
   */
  fetchListUsePage(data) {
    return http.request('/category/page', data);
  },
  /**
   * 列表，不分页
   * @param {Object} data 请求参数，包含parentId, showSub
   */
  fetchList(data) {
    return http.request('/category/list', data, { method: 'post' });
  },
  /**
   * 修改或新增栏目
   * @param {Object} data 提交数据
   */
  save(data) {
    return http.request('/category/save', data);
  },
  /**
   * 获取栏目详情
   * @param {Number} id 栏目id
   */
  detail(id) {
    return http.request('/category/detail', { id }, { method: 'get' });
  },
  /**
   *修改栏目状态...
   * @param {Object} data 包含id， handlerId，status
   */
  changeStatus(data) {
    return http.request('/category/update', data);
  },
  /**
   * 删除栏目
   * @param {Number} id 栏目id
   * @param {*} handlerName 操作人
   */
  delete(id, handlerName) {
    return http.request('/category/status', {
      id,
      status: 'DEL',
      handlerName,
    })
  }
};
