import React from 'react';
import { Form, Input, TreeSelect, Button, message, InputNumber, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import Editor from 'components/Editor';
import { resolveTreeNodes } from 'utils';
import TextArea from 'components/TextArea';
import CommonEdit from '../common/Edit';
import Select from 'components/Select2';
import Upload from 'components/Upload2';

import { editPageStatusOptions, rules, platforms } from 'config';
import reaction from 'reaction';

import style from '../page.module.scss';
import WebsiteInput from 'components/Website';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
}

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

@connect(({ news, category, user }) => ({ news, category, user }))
@Form.create()
class NewsEdit extends CommonEdit {
  mode = 'edit';
  loadCategories(platform = null) {
    reaction.category.fetchNodes({
      parentId: 0,
      showSub: true,
      platform,
    });
  }
  loadDetailData(id) {
    reaction.news.fetchDetail(id)
      .then(data => {
        this.setState({
          startTime: data.startTime,
          loading: false,
          isWechatArticle: !!data.linkAddress
        });
        this.loadCategories(data.platform);
      });
  }
  loadData(id) {
    this.loadDetailData(id);
  }
  handlePlatformChange = (platform) => {
    this.loadCategories(platform);
    this.resetCategoryValue(['categoryId']);
    reaction.category.clear();
  }
  clear() {
    reaction.category.clear();
  }
  submitData(data) {
    reaction.news.update(data)
      .then(res => {
        this.setState({
          submitting: false,
        });
        message.success('保存成功');
        this.props.history.replace('/news');
      })
      .catch(err => {
        this.setState({
          submitting: false,
        });
      })
  }
  getRenderContent() {
    const {
      form: {
        getFieldDecorator,
      },
      news: {
        detail,
      },
      category: {
        nodes = [],
      },
    } = this.props;
    const {
      submitting,
      loading,
      isWechatArticle
    } = this.state;
    const categories = resolveTreeNodes(nodes.filter(node => node.categoryType === 'NEWS'));
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>编辑资讯</span>
        </div>
        <div className={`${style.form} page-form`}>
          {
            getFieldDecorator('id', {
              initialValue: detail.id,
            })(
              <Input hidden />
            )
          }
          {this.renderTitle(detail.title, '资讯标题')}
          <Form.Item
            label="所属平台"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('platform', {
                initialValue: detail.platform || undefined
              })(
                <Select
                  placeholder="请选择"
                  options={platforms}
                  style={{ width: 195 }}
                  filterHidden={false}
                  onChange={this.handlePlatformChange}
                >
                </Select>
              )
            }
          </Form.Item>
          <Form.Item
            label="栏目"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('categoryId', {
                initialValue: detail.categoryId,
                rules: [{
                  message: '请选择栏目',
                  required: true,
                }],
              })(
                <TreeSelect disabled={!categories.length} treeData={categories} style={{ width: 195 }} placeholder="请选择"></TreeSelect>
              )
            }
          </Form.Item>
          <Form.Item
            label="状态"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('status', {
                initialValue: detail.status,
                rules: [{
                  message: '请选择状态',
                  required: true,
                }],
              })(
                <Select
                  placeholder="请选择"
                  options={editPageStatusOptions}
                  style={{ width: 195 }}
                  filterHidden={false}
                >
                </Select>
              )
            }
          </Form.Item>
          <Form.Item
            label="初始浏览量"
            {...formItemLayout}
            extra="只能数字，在0-100000"
            required
          >
            {
              getFieldDecorator('initialClick', {
                initialValue: detail.initialClick,
                rules: [
                  {
                    required: true,
                    message: '请输入初始浏览量',
                  }
                ],
              })(
                <InputNumber
                  placeholder="初始浏览量"
                  maxLength={10}
                  min={0}
                  max={100000}
                  style={{ width: 195 }}
                />
              )
            }
          </Form.Item>
          <Form.Item
            label="作者"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('author', {
                initialValue: detail.author,
                rules: [{
                  message: '请输入作者',
                  required: true,
                  whitespace: true,
                }],
              })(
                <Input
                  placeholder="请输入作者"
                  maxLength={10}
                  style={{ width: 195 }}
                />
              )
            }
          </Form.Item>
          {
            this.renderStartTime(detail.startTime, { title: '上架时间', key: 'startTime' })
          }
          {
            this.renderEndTime(this.state.startTime, detail.expireTime, { title: '下架时间', key: 'expireTime', required: false })
          }
          <Form.Item
            label="文章摘要"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('digest', {
                initialValue: detail.digest,
                rules: [{
                  message: '请输入文章摘要',
                  required: true,
                  whitespace: true,
                }],
              })(
                <TextArea style={{ width: 600 }} placeholder="请输入文章摘要" rows={3} />
              )
            }
          </Form.Item>
          <Form.Item
            label="缩略图"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('image', {
                initialValue: detail.image,
                rules: [{
                  message: '请上传缩略图',
                  required: true,
                }],
              })(
                <Upload />
              )
            }
            <p className={style.tip}>请上传JPG、PNG格式.</p>
          </Form.Item>
          <Form.Item
            label="资讯类型"
            {...formItemLayout}
          >
            <Checkbox onChange={this.handleChangeArticleType} checked={isWechatArticle}>是否微信公众号文章</Checkbox>
          </Form.Item>
          {
            isWechatArticle ? (
              <Form.Item
                label="跳转链接"
                {...formItemLayout}
                required
              >
                {
                  getFieldDecorator('linkAddress', {
                    initialValue: detail.linkAddress,
                    rules: [{
                      required: true,
                      message: '请输入跳转链接',
                    }, {
                      pattern: rules.website,
                      message: '链接地址格式不正确',
                    }],
                  })(
                    <WebsiteInput style={{ width: 520 }} placeholder="请输入跳转链接" />
                  )
                }
              </Form.Item>
            ) : (
              // 异步的时候，有时会出现初始值传递不成功，导致editor组件拿到的value值为空，故此为了确保每次都能够正确拿到值，这里在数据加载成功完成再初始化组件
              !loading ? this.renderEditor(detail.content, '文章正文') : null
            )
          }
          <Form.Item
            label=""
            {...formTailLayout}
            required
          >
            <Button type="primary" className={style['button']} onClick={this.handlePublish} loading={submitting}>保存</Button>
            <Link className={`${style.button} ${style['button-back']}`} to="/news">返回</Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default NewsEdit;
