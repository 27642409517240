import React from 'react';
import { Form, Input, Button, Modal, message, Spin, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import reaction from 'reaction';
import PageHeader from 'modules/PageHeader';
import Select from 'components/Select2';
import Table from 'components/Table2';
import CommonList from '../../common/List';
import tradeSteelPrice from 'api/tradeSteelPrice';
import { funcType } from 'reaction/tradeSteelPrice';
import WrapAuth from 'components/WrapAuth';
import { path } from 'config';
import { checkAuth } from 'utils';

import commonStyle from '../../page.module.scss';
import style from './varieties.module.scss';

const iconBrand = path.cms.iconBrand;
const { confirm } = Modal;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};
const statusOption = [
  { value: '', label: '全部' },
  { value: '1', label: '有效' },
  { value: '0', label: '无效' }
];

@withRouter
@connect(({ tradeSteelPrice, user }) => ({ tradeSteelPrice, user }))
@Form.create()
class Varieties extends CommonList {
  otherState = {
    selectedRowKeys: [],
    selectRows: []
  };
  componentDidMount() {
    reaction.tradeSteelPrice
      .fetchData({ id: 0 }, funcType.fetchGoodsBigTypeNameList)
      .then(err => {
        // console.log(err);
      });
    reaction.tradeSteelPrice.resetGoodsSmallTypeNameList();
    this.handleSearchData();
  }
  loadData(params) {
    reaction.tradeSteelPrice
      .fetchData(params, funcType.fetchVarietiesList)
      .then(err => {
        this.setState({
          loading: false,
          selectRow: [],
          selectedRowKeys: []
        });
      });
  }
  handleChangeBigType = (value, option) => {
    if (value === null) {
      this.props.form.resetFields(['goodsSmallTypeId']);
      this.setState({
        bigType: 'All'
      });
    } else {
      reaction.tradeSteelPrice
        .fetchData({ id: value }, funcType.fetchGoodsSmallTypeNameList)
        .then(res => {
          this.setState({
            bigType: 'other'
          });
          this.props.form.setFieldsValue({
            goodsSmallTypeId: null
          });
        });
    }
  };
  handleCheckboxChange = (selectedRowKeys, selectedRows) => {
    let selectRows = [];
    for (let item of selectedRows) {
      selectRows.push(item.id);
    }
    this.setState({
      selectRows,
      selectedRowKeys
    });
  };
  handleDeleteAll = () => {
    confirm({
      title: '确定批量删除所勾选数据吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        if (this.state.selectRows && this.state.selectRows.length > 0) {
          tradeSteelPrice
            .delVareties({
              ids: this.state.selectRows,
              handlerId: this.props.user.uid
            })
            .then(() => {
              message.success('批量删除成功');
              this.reloadData();
            });
        } else {
          message.error('请勾选需要删除的数据！');
        }
      }
    });
  };
  handleDelete(ids) {
    confirm({
      title: '确定删除该条数据吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        tradeSteelPrice
          .delVareties({ ids, handlerId: this.props.user.uid })
          .then(() => {
            message.success('删除成功');
            this.reloadData();
          });
      }
    });
  }

  render() {
    const {
      form: { getFieldDecorator },
      tradeSteelPrice: {
        varietiesList: list = [],
        varietiesPagination: pagination = {},
        goodsBigTypeNameList = [],
        goodsSmallTypeNameList = []
      }
    } = this.props;
    const columns = [
      {
        title: '商品大类',
        dataIndex: 'goodsBigTypeName',
        align: 'center',
        key: 'goodsBigTypeName'
      },
      {
        title: '商品小类',
        dataIndex: 'goodsSmallTypeName',
        align: 'center',
        key: 'goodsSmallTypeName'
      },
      {
        title: '品名',
        dataIndex: 'name',
        align: 'center',
        key: 'name'
      },
      {
        title: '助记码',
        dataIndex: 'mnemonicCode',
        align: 'center',
        key: 'mnemonicCode'
      },
      {
        title: '前台显示排序',
        dataIndex: 'sort',
        align: 'center',
        key: 'sort'
      },
      {
        title: '状态',
        dataIndex: 'showFlag',
        align: 'center',
        key: 'showFlag',
        render: text => {
          return text === '1' ? '有效' : '无效';
        }
      },
      {
        title: '操作',
        dataIndex: 'id',
        align: 'center',
        key: 'id',
        render: text => (
          <React.Fragment>
            <WrapAuth path={iconBrand.update.path}>
              <Link
                to={`/steelpricevarieties/edit/${text}`}
                className="link-handle"
              >
                编辑
              </Link>
            </WrapAuth>
            <WrapAuth path={iconBrand.delete.path}>
              <a
                href="javascript:;"
                className="link-handle"
                onClick={() => this.handleDelete([text])}
              >
                删除
              </a>
            </WrapAuth>
          </React.Fragment>
        )
      }
    ];
    const newGoodsBigTypeNameList = [
      { value: null, label: '全部' },
      ...goodsBigTypeNameList
    ];
    const newGoodsSmallTypeNameList =
      this.state.bigType === 'All'
        ? [{ value: null, label: '全部' }]
        : [{ value: null, label: '全部' }, ...goodsSmallTypeNameList];

    return (
      <div className="page-inner">
        <PageHeader title="钢价品种管理">
          <WrapAuth path={iconBrand.add.path}>
            <Link to="/steelpricevarieties/add" className="page-btn">
              +&nbsp;添加品种
            </Link>
          </WrapAuth>
        </PageHeader>
        <div className={commonStyle.filter}>
          <Form>
            <Row>
              <Col span={8}>
                <Form.Item
                  label="商品大类："
                  {...formItemLayout}
                  className={style.styleFormItem}
                >
                  {getFieldDecorator('goodsBigTypeId', {
                    initialValue: null
                  })(
                    <Select
                      width="100%"
                      placeholder="请选择"
                      className="select2"
                      options={newGoodsBigTypeNameList}
                      showSearch
                      optionFilterProp="children"
                      onChange={this.handleChangeBigType}
                      filterHidden={false}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="品名："
                  {...formItemLayout}
                  className={style.styleFormItem}
                >
                  {getFieldDecorator('name', {
                    initialValue: ''
                  })(
                    <Input style={{ width: '100%' }} placeholder="请输入品名" />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="助记码："
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  className={`${style.styleFormItem}`}
                >
                  {getFieldDecorator('mnemonicCode', {
                    initialValue: ''
                  })(
                    <Input
                      style={{ width: '100%' }}
                      placeholder="请输入助记码"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item
                  label="商品小类："
                  {...formItemLayout}
                  className={style.styleFormItem}
                >
                  {getFieldDecorator('goodsSmallTypeId', {
                    initialValue: null
                  })(
                    <Select
                      width="100%"
                      placeholder="请选择"
                      className="select2"
                      showSearch
                      optionFilterProp="children"
                      options={newGoodsSmallTypeNameList}
                      filterHidden={false}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="状态："
                  {...formItemLayout}
                  className={style.styleFormItem}
                >
                  {getFieldDecorator('showFlag', {
                    initialValue: ''
                  })(
                    <Select
                      width="100%"
                      placeholder="请选择"
                      className="select2"
                      options={statusOption}
                      filterHidden={false}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className={style.operateFormItem}>
                <Button type="primary" onClick={this.handleSearchData}>
                  查询
                </Button>
                <WrapAuth path={iconBrand.delete.path}>
                  <Button
                    type="primary"
                    onClick={this.handleDeleteAll}
                    style={{ marginLeft: 16 }}
                  >
                    批量删除
                  </Button>
                </WrapAuth>
              </Col>
            </Row>
          </Form>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            rowKey="id"
            dataSource={list}
            columns={columns}
            pagination={this.getPageConfig(pagination)}
            rowSelection={
              checkAuth(iconBrand.delete.path)
                ? {
                    onChange: this.handleCheckboxChange,
                    selectedRowKeys: this.state.selectedRowKeys
                  }
                : null
            }
          />
        </Spin>
      </div>
    );
  }
}

export default Varieties;
