import React from 'react';
import { Form, Input, Button, InputNumber, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'components/Select2';
import Upload from 'components/Upload2';
import ColorPicker from 'components/ColorPicker';
import WebsiteInput from 'components/Website';
import CommonEdit from '../common/Edit';
import { bannerPages, editPageStatusOptions, rules } from 'config';
import reaction from 'reaction';

import style from '../page.module.scss';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
}

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

@connect(({ user, banner }) => ({ user, banner }))
@Form.create()
@withRouter
class BannerEdit extends CommonEdit {
  mode = 'edit';
  submitData(data) {
    reaction.banner.update(data)
      .then(err => {
        this.setState({
          submitting: false,
        });
        message.success('修改成功');
        this.props.history.replace('/banner');
      }).catch(err => {
        this.setState({
          submitting: false,
        });
      });
  }
  loadData(id) {
    reaction.banner.fetchDetail(id).then(({ startTime }) => {
      this.setState({
        startTime,
        loading: false,
      });
    });
  }
  clear() {
    reaction.banner.clear();
  }
  handleColorPick = (e) => {
    // console.log(e);
    return '#ff0000';
  }
  getRenderContent() {
    let {
      form: {
        getFieldDecorator,
      },
      banner: {
        detail,
      },
    } = this.props;
    const {
      startTime
    } = this.state;
    detail = detail || {};
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>编辑轮播图</span>
          {/* <span className={style.crm}>添加轮播图<span className={style.split}>/</span><Link to="/adv">轮播图列表</Link></span> */}
        </div>
        <div className={`${style.form} page-form`}>
          {
            getFieldDecorator('id', {
              initialValue: detail.id,
            })(
              <Input hidden />
            )
          }
          <Form.Item
            label="页面"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('position', {
                initialValue: detail.position || undefined,
                rules: [{
                  required: true,
                  message: '请选择页面',
                }],
              })(
                <Select
                  options={bannerPages}
                  style={{ width: 195 }}
                />
              )
            }
          </Form.Item>
          <Form.Item
            label="状态"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('status', {
                initialValue: detail.status,
                rules: [{
                  required: true,
                  message: '请选择状态',
                }],
              })(
                <Select
                  placeholder="请选择"
                  options={editPageStatusOptions}
                  style={{ width: 195 }}
                />
              )
            }
          </Form.Item>
          {
            this.renderTitle(detail.title, '轮播图标题')
          }
          <Form.Item
            label="跳转协议"
            {...formItemLayout}
          >
            {
              getFieldDecorator('linkAddress', {
                initialValue: detail.linkAddress,
                rules: [{
                  pattern: rules.website,
                  message: '链接地址格式不正确',
                }],
              })(
                <WebsiteInput style={{ width: 520 }} placeholder="请输入跳转链接" />
              )
            }
          </Form.Item>
          <Form.Item
            label="排序"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('sorted', {
                initialValue: detail.sorted || '0',
                // normalize: value => (value !== '' && value !== undefined) ? +value : undefined,
                rules: [{
                  required: true,
                  message: '请输入排序',
                }],
              })(
                <InputNumber min={0} style={{ width: 195 }} placeholder="排序" />
              )
            }
          </Form.Item>
          {
            detail.startTime && this.renderStartTime(detail.startTime)
          }
          {
            // 这里使用state.startTime是因为结束时间不能选择的时间受限于开始时间的选择
            detail.expireTime && this.renderEndTime(startTime, detail.expireTime)
          }
          <Form.Item
            label="背景颜色"
            {...formItemLayout}
            // required
          >
            {
              getFieldDecorator('backColor', {
                initialValue: detail.backColor || undefined,
                // rules: [{
                //   required: true,
                //   message: '请选择背景颜色',
                // }],
              })(
                <ColorPicker />
              )
            }
          </Form.Item>
          <Form.Item
            label="图片"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('image', {
                initialValue: detail.image,
                rules: [{
                  required: true,
                  message: '请上传轮播图图片',
                }],
              })(
                <Upload
                  showUploadList={true}
                  max={1}
                  accept=".jpg,.png"
                />
              )
            }
            <p className={style.tip}>请上传JPG、PNG格式.</p>
          </Form.Item>
          <Form.Item
            label=""
            {...formTailLayout}
            required
          >
            <Button type="primary" className={style.button} onClick={this.handlePublish} loading={this.state.submitting}>保存</Button>
            <Link className={`ant-btn ${style.button} ${style['button-back']}`} to="/banner">返回</Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default BannerEdit;
