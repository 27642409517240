import React from 'react';
import { Input } from 'antd';
import { ChromePicker } from 'react-color';

import styles from './color-picker.module.scss';

export default class ColorPicker extends React.Component {
  state = {
    color: this.props.defaultValue || '#ffffff',
    result: this.props.defaultValue || '#ffffff',
    visible: false,
  }
  isInited = false;
  UNSAFE_componentWillReceiveProps(nextProps, props ) {
    if (nextProps.value !== props.value && !this.isInited) {
      this.updateValue(nextProps.value)
      this.isInited = true;
    }
  }
  componentDidMount() {
    this.updateValue(this.props.value);
  }
  updateValue(value) {
    if (value) {
      this.setState({
        color: value,
        result: value,
      });
    }
  }
  handleOnChangeComplete = (color, event) => {
    this.setState({
      color: color.hex,
    });
  }
  handleShowPicker = () => {
    this.setState(({ result }) => {
      return {
        visible: true,
        color: result,
      };
    })
  }
  handleClosePicker = (event) => {
    this.setState(({ color }) => {
      return {
        visible: false,
        result: color,
      };
    }, () => {
      this.triggerColorValue(this.state.result);
    })
  }
  triggerColorValue(value) {
    const { onChange = () => {} } = this.props;
    onChange(value);
  }
  render() {
    const {
      style = {}
    } = this.props;
    const {
      visible,
      color,
      result,
    } = this.state;
    const backgroundColor = result;
    return (
      <div className={styles.wrapper} onClick={this.handleWrapperClick} style={style}>
        <Input style={{ backgroundColor }} readOnly className={styles.result} onClick={this.handleShowPicker} />
        {
          visible ? [
            <div onClick={this.handleClosePicker} key="popover" className={styles.popover}></div>,
            <div key="picker" className={styles.picker}>
              <ChromePicker
                onChangeComplete={this.handleOnChangeComplete}
                disableAlpha
                color={color}
                hex
              />
            </div>
          ] : null
        }
      </div>
    )
  }
}
