import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, message, Input, Spin, DatePicker } from 'antd';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import reaction from 'reaction';
import PageHeader from 'modules/PageHeader';
import Select from 'components/Select2';
import FormItem from 'components/FormItem';
import Table from 'components/Table2';
import WrapAuth from 'components/WrapAuth';
import CommonList from '../common/List';
import common from 'api/common';
import { resolvePageStatusText, resolvePlatformNames, downloadFileByForm } from 'utils';
import { categoryFilterOptions, platformsForHelp, categoryTypes, datePickerConfig, path } from 'config';

import style from '../page.module.scss';

const { Column } = Table;
const { FormItemGroup } = FormItem;
const { confirm } = Modal;

const formDate = datePickerConfig.format;
const categoryPath = path.cms.category;

@withRouter
@connect(({ category, user }) => ({ category, user }))
@Form.create()
class Category extends CommonList {
  loadData (params) {
    params.parentId = -1;
    reaction.category.fetchList(params)
      .then(err => this.setState({ loading: false }));
      this.fetchCategories();

  }
  handlePlatformChange = (platform) => {
    platform !== null && this.props.form.resetFields(['parentId']);
    this.fetchCategories(platform);
  }
  fetchCategories(platform = null) {
    reaction.category.fetchNodes({
      parentId: 0,
      showSub: true,
      platform
    });
  }
  clear () {
    reaction.category.clear();
  }
  handleDelete (id) {
    confirm({
      title: '确定删除此栏目吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        reaction.category.delete(id, this.props.user.username).then(() => {
          message.success('删除成功');
          this.reloadData(!this.props.category.list.length);
        });
      },
    });
  }
  exportData(data) {
    downloadFileByForm({
      action: common.exportCategoryData,
      data,
    });
  }
  handleConfirmExport = () => {
    const {
      startTime,
      endTime
    } = this.state;
    const { parentId, ...data } = this.props.form.getFieldsValue();
    if ((!endTime && !startTime) || (!endTime || endTime > startTime)) {
      this.closeExportModal();
      this.exportData({
        ...data,
        startTime: +startTime,
        endTime: +endTime,
        timestamp: +new Date(),
      });
    } else {
      message.error('结束时间不能小于开始时间');
    }
  }
  showExportModal = () => {
    this.clearDateValue();
    this.setState({
      showExportModal: true,
    });
  }
  closeExportModal = () => {
    this.setState({
      showExportModal: false,
    });
  }
  saveDateValue(field, v) {
    this.setState({
      [field]: v,
    });
  }
  clearDateValue() {
    this.setState({
      startTime: null,
      endTime: null,
    });
  }
  render () {
    const { form: { getFieldDecorator }, category: { list = [], pagination = {} } } = this.props;
    const {
      showExportModal,
      startTime,
      endTime
    } = this.state;
    return (
      <div className="page-inner">
        <WrapAuth path={categoryPath.export.path}>
          <Modal
            visible={showExportModal}
            title="导出栏目资讯数据"
            width={630}
            onOk={this.handleConfirmExport}
            onCancel={this.closeExportModal}
            keyboard={false}
            maskClosable={false}
          >
            <FormItemGroup>
              <FormItem
                label="开始时间："
                style={{ marginRight: 50 }}
              >
                <DatePicker showTime={{
                  defaultValue: moment('00:00:00', 'HH:mm:ss'),
                }} value={startTime || null } format={formDate} onChange={(m, v) => this.saveDateValue('startTime', m)}></DatePicker>
              </FormItem>
              <FormItem
                label="结束时间："
              >
                <DatePicker showTime={{
                  defaultValue: moment('23:59:59', 'HH:mm:ss'),
                }} value={endTime || null} format={formDate} onChange={(m, v) => this.saveDateValue('endTime', m)}></DatePicker>
              </FormItem>
            </FormItemGroup>
          </Modal>
        </WrapAuth>
        <PageHeader title="栏目列表">
          <WrapAuth path={categoryPath.add.path}>
            <Link to="/category/add" className="page-btn">
              +&nbsp;添加栏目
            </Link>
          </WrapAuth>
        </PageHeader>
        <div className={style.filter}>
          <FormItemGroup>
            <FormItem label="所属平台：" style={{ marginRight: 20 }}>
              {getFieldDecorator('platform', {
                initialValue: null,
              })(
                <Select
                  width={120}
                  options={platformsForHelp}
                  onChange={this.handlePlatformChange}
                  filterHidden={false}
                />
              )}
            </FormItem>
            <FormItem label="应用模块：" style={{ marginRight: 20 }}>
              {getFieldDecorator('categoryType', {
                initialValue: null,
              })(
                <Select
                  width={120}
                  options={categoryTypes}
                  filterHidden={false}
                />
              )}
            </FormItem>
            <FormItem label="栏目名称：" className="form-item-filter" style={{ marginRight: 20 }}>
              {getFieldDecorator('categoryName', {
                initialValue: null,
              })(<Input style={{ width: 180 }} placeholder="请输入栏目名称" className="select2" />)}
            </FormItem>
            
            <FormItem label="状态：" style={{ marginRight: 20 }}>
              {getFieldDecorator('status', {
                initialValue: null,
              })(
                <Select
                  width={120}
                  options={categoryFilterOptions}
                />
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearchData}>
              查询
            </Button>
            <WrapAuth path={categoryPath.export.path}>
              <div className={style.right}>
                <Button type="primary" icon="export" onClick={this.showExportModal}>导出数据</Button>
              </div>
            </WrapAuth>
          </FormItemGroup>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={list}
            loading={false}
            rowKey={(row, index) => index}
            pagination={this.getPageConfig(pagination)}
            borderd
          >
            <Column title="序号" key="index" align="center" render={(row, arr, index) => index + 1} width={60} />
            <Column
              title="所属平台"
              key="platform"
              dataIndex="platform"
              align="center"
              width={100}
              render={(value) => resolvePlatformNames({ value })}
            />
            <Column title="应用模块" key="name" dataIndex="categoryType" align="center" width={110} render={(row, arr, index) => row === 'HELP' ? '帮助中心' : '资讯'}/>
            <Column title="栏目名称" key="name" dataIndex="name" align="center" width={110} />
            
            <Column title="篇章数量" key="articleCount" dataIndex="articleCount" align="center" width={110} />
            <Column title="浏览量" key="clickCount" dataIndex="clickCount" align="center" width={100} />
            <Column title="排序" key="sorted" dataIndex="sorted" align="center" width={50} />
            <Column
              title="状态"
              key="status"
              dataIndex="status"
              align="center"
              render={(status) => resolvePageStatusText(status)}
              width={80}
            />
            <Column
              title="操作"
              key="handler"
              align="center"
              // width={100}
              render={(row) => {
                return (
                  <div>
                    <WrapAuth path={categoryPath.update.path}>
                      <Link to={`/category/edit/${row.id}`} className="link-handle">
                        编辑
                      </Link>
                    </WrapAuth>
                    <WrapAuth path={categoryPath.delete.path}>
                      {
                        row.articleCount > 0 ? (
                          <a href="javascript:;" className="link-handle link-handle-disabled">删除</a>
                        ) : (
                          <a href="javascript:;" className="link-handle" onClick={() => this.handleDelete(row.id)}>删除</a>
                        )
                      }
                    </WrapAuth>
                  </div>
                );
              }}
            />
          </Table>
        </Spin>
      </div>
    );
  }
}

export default Category;
