import other from 'api/purchase-other';

const initState = {
  list: [],
  pagination: {},
  detail: {},
  template: ''
};

export const types = {
  saveList: 'PURCHASE_OTHER_LIST',
  saveDetail: 'PURCHASE_OTHER_DETAIL',
  saveTemplate: 'PURCHASE_OTHER_TEMPLATE',
  clear: 'PURCHASE_OTHER_CLEAR'
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.saveList:
      return Object.assign({}, state, {
        list: action.list,
        pagination: action.pagination
      });
    case types.saveDetail:
      return Object.assign({}, state, { detail: action.detail });
    case types.saveTemplate:
      return Object.assign({}, state, { template: action.template });
    case types.clear:
      return initState;
    default:
      return state;
  }
};

reducer.fetchList = data => {
  return dispatch => {
    return other.fetchList(data).then(data => {
      dispatch({
        type: types.saveList,
        list: data.list,
        pagination: data.pagination
      });
      return data;
    });
  };
};

reducer.add = data => {
  return () => {
    return other.add(data);
  };
};

reducer.update = data => {
  return () => {
    return other.save(data);
  };
};

reducer.delete = (ids, handlerName) => {
  return () => {
    return other.delete(ids, handlerName);
  };
};

reducer.fetchDetail = id => {
  return dispatch => {
    return other.detail(id).then(data => {
      // if (res.code === 0)
      dispatch({
        type: types.saveDetail,
        detail: data || {}
      });
      return data;
    });
  };
};

reducer.fetchTemplate = () => {
  return dispatch => {
    return other.fetchTemplateUrl().then(data => {
      dispatch({
        type: types.saveTemplate,
        template: data
      });
      return data;
    });
  };
};

reducer.clear = () => {
  return dispatch => {
    dispatch({
      type: types.clear
    });
  };
};

export default reducer;
