import http from 'utils/request';

export default {
  fetchList(data) {
    return http.request('/notice/page', data);
  },
  update(data) {
    return http.request('/notice/update', data);
  },
  add(data) {
    return http.request('/notice/save', data);
  },
  /**
   * 获取公告详情
   * @param {Number} id 公告id
   */
  detail(id) {
    return http.request('/notice/detail', { id }, { method: 'get' });
  },
  /**
   * 下架公告
   * @param {*} id 公告id
   * @param {*} handlerName 操作人
   */
  cancel(id, handlerName) {
    return http.request('/notice/expired', {
      id,
      status: 'CANCEL',
      handlerName,
    })
  },
  /**
   * 删除公告
   * @param {Number} id 公告id
   */
  delete(id, handlerName) {
    return http.request('/notice/delete', {
      id,
      status: 'DEL',
      handlerName,
    })
  },
};
