import { getToken } from 'utils';
import createUID from 'utils/createUID';
import user from 'api/user';

const INITIAL_DATA = createUID();

const CLEAR_DATA = createUID();

const USER_AUTH = createUID();

// const USER_PERMISSION = createUID();

// const COMMON_DATA = createUID();

let initialState = {
  userInfo: {
    userId: -1,
    lastLoginTime: -1
  },
  auth: {},
  // permision: {},
  // commonData: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case INITIAL_DATA:
      return Object.assign({}, state, action.data);
    case USER_AUTH:
      return Object.assign({}, state, action.data);
    // case USER_PERMISSION:
    //   return Object.assign({}, state, action.data);
    // case COMMON_DATA:
    //   return Object.assign({}, state, action.data);
    default:
      return state;
  }
}

// reducer.getUserCommonData = () => {
//   return dispatch => {
//     return user.initData({ token: getToken() })
//       .then(data => {
//         dispatch({
//           type: COMMON_DATA,
//           data: data || {},
//         })
//         return data;
//       })
//   }
// }

reducer.getUserAuth = () => {
  return (dispatch) => {
    return user.getUserAuth(getToken())
      .then(data => {
        dispatch({
          type: USER_AUTH,
          data: data || {},
        });
        return data;
      });
  }
}

// reducer.getUserPermission = () => {
//   return (dispatch) => {
//     return user.getAllPermission(getToken())
//       .then(data => {
//         dispatch({
//           type: USER_PERMISSION,
//           data: data || {},
//         });
//         return data;
//       })
//   }
// }

reducer.initial = () => {
  return (dispatch) => {
    return user.getUserInfo(getToken()).then(data => {
      dispatch({
        type: INITIAL_DATA,
        data: data || {},
      });
      return data;
    });
  };
}

reducer.clear = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DATA,
    });
  }
}

export default reducer;
