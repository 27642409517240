import React from 'react';
import style from './page-header.module.scss';

function PageHeader({ title, children }) {
  return (
    <div className={style.wrapper}>
      <span className={style.title}>{title}</span>
      <div>
        {
          children
        }
      </div>
    </div>
  );
}

export default PageHeader;
