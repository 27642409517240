import uamsdk from 'zlgx-uamsdk';
import { SSO_URL, PROD } from 'config/env';
import storage from 'utils/localStorage';
import { setToken } from './index';
import reaction from 'reaction';

export const initConfig = () => {
  const protocol = PROD ? 'https': 'http';

  uamsdk.init({
    apiUrl: `${protocol}:${SSO_URL}/api/`,
    loginUrl: `${protocol}:${SSO_URL}#/login`,
    logoutUrl: `${protocol}:${SSO_URL}#/logout`,
    redirect_uri: `${protocol}://${location.hostname}:${location.port}/callback.html`,
    display: uamsdk.POPUP,
  });
}

export const getUserInfo = () => {
  reaction.user.initial();
}

export const loginInit = () => {
  initConfig();
}

export const logout = (cb, isLogout = true) => {
  if (!isLogout) {
    setToken('');
    uamsdk.removeToken();
    reaction.user.clear();
    storage.removeItem('token');
    cb && cb();
  } else {
    try {
      uamsdk.logout(() => {
        setToken('');
        uamsdk.removeToken();
        reaction.user.clear();
        storage.removeItem('token');
        cb && cb();
      });
    } catch (error) {
      console.error(error);
    }
  }
}
export const toLogin = (isLogout = true) => {
  logout(() => {
    window.location.replace('/login');
  }, isLogout);
}
