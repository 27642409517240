import React from 'react';
import Logo from '../Logo';
import LoginInfo from '../LoginInfo';
import style from './header.module.scss';

function Header() {
  return (
    <div className={style.wrapper}>
      <Logo className={style.logo}></Logo>
      <LoginInfo className={style.info}></LoginInfo>
    </div>
  )
}

export default Header;
