import { path } from './index';

const menus = [
  {
    path: '/notice',
    name: '公告管理',
    flag: path.cms.notice.list.path
  },
  {
    path: '/banner',
    name: '轮播图',
    flag: path.cms.banner.list.path
  },
  {
    name: '主题管理',
    children: [
      {
        path: '/web-theme',
        name: 'web主题管理',
        flag: path.cms.theme.app.list.path
      },
      {
        path: '/app-theme',
        name: 'app主题管理',
        flag: path.cms.theme.web.list.path
      }
    ]
  },
  {
    path: '/category',
    name: '栏目管理',
    flag: path.cms.category.list.path
  },
  {
    path: '/help',
    name: '帮助中心',
    flag: path.cms.help.list.path
  },
  {
    path: '/news',
    name: '资讯管理',
    flag: path.cms.news.list.path
  },
  {
    path: '/wx_news',
    name: '微官网资讯管理',
    flag: path.cms.wx_news.list.path
  },
  {
    name: '钢价动态管理',
    children: [
      {
        path: '/steelpricevarieties',
        name: '钢价品种管理',
        flag: path.cms.iconBrand.list.path
      },
      {
        path: '/steelpricedata',
        name: '钢价数据管理',
        flag: path.cms.steelData.list.path
      }
    ]
  },
  {
    name: '大宗原料价格管理',
    children: [
      {
        path: '/purchase-diff',
        name: '日照PB、曹妃甸PB价差',
        flag: path.cms.priceTrendFirst.list.path
      },
      {
        path: '/purchase-diff-port',
        name: '日照港PB粉、PB块价差',
        flag: path.cms.priceTrendSecond.list.path
      },
      {
        path: '/purchase-ports',
        name: '港口现货与主力合约基差',
        flag: path.cms.priceTrendThird.list.path
      },
      {
        path: '/purchase-other',
        name: '其他大类商品价格走势',
        flag: path.cms.otherPriceTrend.list.path
      }
    ]
  },
  {
    name: '成本预控报表数据管理',
    children: [
      {
        path: '/preControl',
        name: '日报',
        flag: path.cms.preControl.daily.list.path,
      },
      {
        path: '/preControlWeek',
        name: '周报',
        flag: path.cms.preControl.week.list.path
      }
    ]
  }
];

export default menus;
