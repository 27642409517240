import React from 'react';
import { Form, Input, Button, InputNumber, message, DatePicker } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import CommonEdit from 'pages/common/Edit';
import reaction from 'reaction';

import style from 'pages/page.module.scss';
import { datePickerConfig } from 'config';

// const { TreeNode } = TreeSelect;

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16
  }
};

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 }
  }
};

@connect(({ purchasePorts, user }) => ({ purchasePorts, user }))
@Form.create()
@withRouter
class PortsPriceInfoEdit extends CommonEdit {
  handlePublish = () => {
    this.submitData();
  };
  loadData(id) {
    reaction.purchasePorts
      .fetchDetail(id)
      .then(() => {
        this.setState({
          loading: false
        });
      })
      .catch(() => {
        this.setState({
          loading: false
        });
      });
  }
  submitData() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          submitting: true
        });
        values.dateTime = values.dateTime.format('YYYY-MM-DD');
        values.handlerName = this.props.user.username;
        reaction.purchasePorts
          .update(values)
          .then(res => {
            this.setState({
              submitting: false
            });
            message.success('保存成功');
            this.goListPage();
          })
          .catch(err => {
            this.setState({
              submitting: false
            });
          });
      }
    });
  }
  goListPage = () => {
    reaction.purchasePorts.clear();
    this.props.history.push('/purchase-ports');
  };
  getRenderContent() {
    const {
      form: { getFieldDecorator },
      purchasePorts: { detail }
    } = this.props;
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>各港口合约基差信息修改</span>
        </div>
        <div className={`${style.form} page-form`}>
          {getFieldDecorator('id', {
            initialValue: detail.id
          })(<Input type="hidden" />)}
          <Form.Item {...formItemLayout} label="时间" required>
            {getFieldDecorator('dateTime', {
              initialValue: detail.dateTime
                ? moment(detail.dateTime)
                : undefined,
                rules: [{
                  required: true,
                  message: '请选择时间',
                }],
            })(
              <DatePicker
                style={{ width: 195 }}
                format={datePickerConfig.formateDate}
              />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="合约号" required>
            {getFieldDecorator('basePriceCode', {
              initialValue: detail.basePriceCode,
              rules: [{
                required: true,
                message: '请输入合约号',
              }],
            })(<Input style={{ width: 195 }} />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="合约价格" required>
            {getFieldDecorator('basePrice', {
              initialValue: detail.basePrice,
              rules: [{
                required: true,
                message: '请输入合约价格',
              }],
            })(<InputNumber min={1} style={{ width: 195 }} />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="日照港PB粉价格" required>
            {getFieldDecorator('priceTwo', {
              initialValue: detail.priceTwo,
              rules: [{
                required: true,
                message: '请输入日照港PB粉价格',
              }],
            })(<InputNumber min={1} style={{ width: 195 }} />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="曹妃甸港PB粉价格" required>
            {getFieldDecorator('priceThree', {
              initialValue: detail.priceThree,
              rules: [{
                required: true,
                message: '请输入曹妃甸港PB粉价格',
              }],
            })(<InputNumber min={1} style={{ width: 195 }} />)}
          </Form.Item>
          <Form.Item label="" {...formTailLayout} required>
            <Button
              type="primary"
              className={style['button']}
              onClick={this.handlePublish}
              loading={this.state.submitting}
            >
              保存
            </Button>
            <Button
              className={`${style.button} ${style['button-back']}`}
              onClick={this.goListPage}
            >
              返回
            </Button>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default PortsPriceInfoEdit;
