import React, { Component } from 'react';
import { Upload, Icon, Modal, Button, message } from 'antd';
// import localStorage from 'utils/localStorage';
import { getToken } from 'utils'
import api from 'api';
import styles from './upload.module.scss';

function validIsTimeout(response) {
  if (!response) {
    return 'uploading';
  }
  if (typeof response === 'string' && response.indexOf('error') !== -1) {
    return 'error';
  }
  if (typeof response === 'string' && response.indexOf('timing') !== -1) {
    return 'timeout';
  }
  return '';
}

export default class Upload2 extends Component {
  state = {
    fileList: [],
    previewVisible: false,
    previewImage: '',
    previewName: '',
  }
  hasInited = false;
  UNSAFE_componentWillReceiveProps(nextProps = {}, props = {}) {
    const { value } = nextProps;
    if (value && value !== props.value && !this.hasInited) {
      this.updateFileList(Array.isArray(value) ? value.length ? value : [] : [ value ]);
      this.hasInited = true;
    }
  }
  generateErrorInfo(file, response) {
    return {
      name: file.name,
      status: 'error',
      uid: file.uid,
      response,
      url: '',
    }
  }
  updateFileList(fileList = [], file) {
    this.setState({
      fileList: fileList.map(f => {

        if (typeof f === 'string') {
          return {
            name: f,
            status: 'done',
            uid: f,
            url: f,
          }
        }
        if (!this.validateFileInfo(f)) {
          return this.generateErrorInfo(f, `文件过大，不能超过${this.props.maxSize || 10}M`);
        }
        if (validIsTimeout(f.response) === 'timeout') {
          return this.generateErrorInfo(f, '上传文件超时');
        }
        if (validIsTimeout(f.response) === 'error') {
          return this.generateErrorInfo(f, '服务器错误');
        }
        if (f.response && f.response.code !== 0) {
          return this.generateErrorInfo(f, f.response.msg);
        }
        const response = (f.response && f.response.code === 0) ? f.response : { data: f.url || '' };
        return {
          name: f.name,
          status: f.status,
          uid: f.uid || response.data,
          url: response.data,
        }
      }),
    }, () => {
      if (!file) {
        return false;
      }
      const errorInfo = validIsTimeout(file.response);
      if (errorInfo === 'timeout' && !this.props.showUploadList) {
        return this.triggerChangeEvent({
          name: file.name,
          status: 'done',
          uid: file.uid,
          response: {
            msg: '上传文件超时',
          },
          url: '',
        });
      }
      if (errorInfo === 'error' && !this.props.showUploadList) {
        return this.triggerChangeEvent({
          name: file.name,
          status: 'done',
          uid: file.uid,
          response: {
            msg: '服务器错误',
          },
          url: '',
        });
      }
      if (file.status !== 'error') {
        const response = (file.response && file.response.code === 0) ? file.response : { data: file.url };
        this.triggerChangeEvent({
          ...file,
          url: response.data,
        });
      }
    });
  }
  handleChange = ({ file, fileList = [] }) => {
    this.updateFileList(fileList, file);
  }
  triggerChangeEvent(file) {
    const { onChange = () => {}, max = 1 } = this.props;
    const isMutilpe = max > 1;
    const { fileList } = this.state;
    if (fileList.length) {
      const hasUploadedFiles = fileList.filter(el => el.status === 'done' || el.status === 'uploading');
      if (!hasUploadedFiles.length) {
        onChange(isMutilpe ? [] : '', file);
      } else {
        onChange(isMutilpe ? hasUploadedFiles.map(f => f.url) : hasUploadedFiles[0].url, file);
      }
    } else {
      onChange(isMutilpe ? [] : '', file);
    }
  }
  removeFile = (file) => {
    this.setState(({ fileList }) => {
      return {
        fileList: fileList.filter(f => f.uid !== file.uid)
      };
    }, () => {
      this.triggerChangeEvent();
    });
  }
  resetFileList() {
    this.setState({
      fileList: [],
    }, () => this.triggerChangeEvent());
  }
  validateFileInfo(file) {
    const { maxSize = 10 } = this.props;
    if (file.size > maxSize * 1024 * 1024) {
      return false;
    }
    return true;
  }
  handlePreview = ({ name, url }) => {
    this.setState({
      previewImage: url,
      previewName: name,
    }, () => this.changeModalVisible(true));
  }
  handleCancel = () => {
    this.changeModalVisible(false);
  }
  changeModalVisible(previewVisible) {
    this.setState({
      previewVisible,
    });
  }
  handleBeforeUpload = (file) => {
    const isValid = this.validateFileInfo(file);
    const { maxSize = 10, showUploadList = true } = this.props;
    if (!isValid) {
      const errorTips = `文件过大，不能超过${maxSize}M`;
      const errorFileInfo = {
        name: file.name,
        status: 'done',
        uid: file.uid,
        response: {
          msg: errorTips,
        },
        url: '',
      };
      if (showUploadList) {
        message.error(errorTips, 3);
      }
      this.triggerChangeEvent(errorFileInfo);
      return false;
    }
    return true;
  }
  handleOnRemoveFile = (file) => {
    return true;
  }
  render() {
    const {
      fileList,
      previewVisible,
      previewImage,
      previewName,
    } = this.state;
    const {
      action = api.common.uploadFile,
      max = 1,
      onChange, // 默认的onChange事件函数去掉...
      listType = 'picture-card',
      buttonText = '点击上传',
      showUploadList = true,
      accept = '.jpg,.png',
      value,
      children,
      fileListRender,
      ...otherProps
    } = this.props;
    const uploadUrl = `${action}?token=${getToken()}&timestamp=${new Date().getTime()}`;

    const uploadButton = (
      <div className={styles.btn}>
        <Icon type="plus" className={styles.plus} />
      </div>
    );

    const uploadFileSelectButton = (
      <Button>
        <Icon type="upload" /> {buttonText}
      </Button>
    );
    const fileListItem = fileListRender ? fileListRender(fileList) : '';
    const uploadSelectWrapper = listType === 'text' ? children ? children : uploadFileSelectButton : uploadButton;
    return (
      <div className={styles.wrapper}>
        {
          listType !== 'text' ? (
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel} width="80%">
              <img alt={previewName} style={{ width: '100%' }} src={previewImage} />
            </Modal>
          ) : null
        }
        <Upload
          className={styles.upload}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          showUploadList={fileListRender ? false : showUploadList}
          listType={listType}
          action={uploadUrl}
          accept={accept}
          fileList={fileList}
          max={max}
          beforeUpload={this.handleBeforeUpload}
          onRemove={this.handleOnRemoveFile}
          withCredentials
          {...otherProps}
        >
          {
            (max > fileList.length) ? uploadSelectWrapper : fileListItem
          }
        </Upload>
      </div>
    );
  }
}
