import React from 'react';
import { Form, Button, InputNumber, message, DatePicker, Input } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CommonEdit from 'pages/common/Edit';
import { datePickerConfig } from 'config';
import reaction from 'reaction';

import style from 'pages/page.module.scss';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
}

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

@connect(({ purchaseDiffPort, user }) => ({ purchaseDiffPort, user }))
@Form.create()
@withRouter
class DiffPortPriceCreate extends CommonEdit {
  loadData(id) {
    this.clear();
    reaction.purchaseDiffPort.fetchDetail(id).then(() => {
      this.setState({
        loading: false
      });
    }).catch(() => {
      this.setState({
        loading: false
      });
    })
  }
  handlePublish = () => {
    this.submitData();
  }
  submitData() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          submitting: true
        })
        values.handlerName = this.props.user.username;
        values.dateTime = values.dateTime.format(datePickerConfig.formateDate);
        reaction.purchaseDiffPort.add(values)
          .then(res => {
            this.setState({
              submitting: false,
            });
            message.success('保存成功');
            this.goListPage();
          })
          .catch(err => {
            this.setState({
              submitting: false,
            });
          })
      }
    })
  }
  clear() {
    reaction.purchaseDiff.clear();
  }
  goListPage = () => {
    this.props.history.push('/purchase-diff-port');
  }
  getRenderContent() {
    const {
      form: {
        getFieldDecorator,
      },
      purchaseDiffPort: {
        detail
      }
    } = this.props;
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>日照港价差数据添加</span>
        </div>
        <div className={`${style.form} page-form`}>
          {
            getFieldDecorator('id', {
              initialValue: detail.id,
            })(<Input type="hidden" />)
          }
          <Form.Item
            {
              ...formItemLayout
            }
            label="日照港PB块价格"
            required
          >
            {
              getFieldDecorator('pbBlockPrice', {
                initialValue: detail.pbBlockPrice,
                rules: [{
                  required: true,
                  message: '请填写日照港PB块价格'
                }]
              })(
                <InputNumber style={{ width: 195 }} min={1} />
              )
            }
          </Form.Item>
          <Form.Item
            {
              ...formItemLayout
            }
            label="日照港PB粉价格"
            required
          >
            {
              getFieldDecorator('pbPowderPrice', {
                initialValue: detail.pbPowderPrice,
                rules: [{
                  required: true,
                  message: '请填写日照港PB粉价格'
                }]
              })(
                <InputNumber style={{ width: 195 }} min={1} />
              )
            }
          </Form.Item>
          <Form.Item
            {
              ...formItemLayout
            }
            label="时间"
            required
          >
            {
              getFieldDecorator('dateTime', {
                initialValue: detail.dateTime ? moment(detail.dateTime) : undefined,
                rules: [{
                  required: true,
                  message: '请选择时间'
                }]
              })(
                <DatePicker style={{ width: 195 }} format={datePickerConfig.formateDate}></DatePicker>
              )
            }
          </Form.Item>
          <Form.Item
            label=""
            {...formTailLayout}
            required
          >
            <Button type="primary" className={style['button']} onClick={this.handlePublish} loading={this.state.submitting}>保存</Button>
            <Button className={`${style.button} ${style['button-back']}`} onClick={this.goListPage}>返回</Button>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default DiffPortPriceCreate;
