import React, { Component } from 'react';
import { DatePicker, Form, Input, Spin } from 'antd';
import Select from 'components/Select2';
import Editor from 'components/Editor';
// import Editor from 'braft-editor';
// import 'braft-editor/dist/index.css'
import { datePickerConfig, editPageStatusOptions } from 'config';
// import zh_CN from 'antd/lib/locale-provider/zh_CN';
import {
  disabledRangeDate,
  disabledEndTime,
  resolveTimestampToValue
} from 'utils';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16
  }
};
export default class CommonEdit extends Component {
  mode = 'edit';
  otherState = {};
  state = {
    ...this.otherState,
    submitting: false,
    startTime: '',
    endTime: '',
    loading: true,
    isWechatArticle: false
  };
  componentDidMount() {
    if (this.mode === 'edit') {
      this.setState({
        loading: true
      });
      this.loadData(`${this.props.match.params.id}`);
    }
  }
  componentWillUnmount() {
    this.clear();
  }
  changeStartTime = (d, s) => {
    this.setState({
      startTime: s
    });
  };

  changeEndTime = (d, s) => {
    this.setState({
      endTime: s
    });
  };
  handlePublish = () => {
    this.props.form.validateFieldsAndScroll(
      (err, { startTime, expireTime, content, ...values }) => {
        if (!err) {
          this.setState({
            submitting: true
          });
          const postData = {
            ...values,
            // 因为有些页面没有这两个字段所以需要判断一下...
            startTime: startTime ? +startTime : undefined,
            expireTime: expireTime ? +expireTime : undefined,
            handlerName: this.props.user.username,
            handlerId: this.props.user.uid
          };
          if (content) {
            postData.content = content.toHTML();
          }
          this.submitData(postData);
        }
      }
    );
  };
  handleChangeArticleType = (e, v) => {
    this.setState({
      isWechatArticle: e.target.checked
    });
  };
  renderTitle = (initialValue, label = '公告标题') => {
    const { getFieldDecorator } = this.props.form;
    const rules = [
      {
        required: true,
        message: `请输入${label}`
      }
    ];
    const initialConfig = {
      initialValue,
      rules
    };

    return (
      <Form.Item label={label} labelWidth={72} {...formItemLayout} required>
        {getFieldDecorator('title', initialConfig)(
          <Input
            maxLength={50}
            size="large"
            placeholder={`请输入${label}`}
            style={{ width: 520 }}
          />
        )}
      </Form.Item>
    );
  };
  renderEditor = (initialValue, label = '公告内容') => {
    const { getFieldDecorator } = this.props.form;
    const rules = [
      {
        required: true,
        message: `请输入${label}`,
        whitespace: true,
        validator: (_, value, callback) => {
          if (value.isEmpty()) {
            callback('请输入正文内容');
          } else {
            callback();
          }
        }
      }
    ];
    const initialConfig = {
      validateTrigger: 'onBlur',
      rules,
      initialValue
    };
    return (
      <Form.Item label={label} labelWidth={72} {...formItemLayout} required>
        {getFieldDecorator('content', initialConfig)(
          <Editor style={{ width: 600 }} placeholder={`请输入${label}`} />
        )}
      </Form.Item>
    );
  };

  renderStatus = initialValue => {
    const { getFieldDecorator } = this.props.form;
    const rules = [
      {
        required: true,
        message: `请选择状态`
      }
    ];
    const initialConfig = {
      initialValue,
      rules
    };
    return (
      <Form.Item label="状态" {...formItemLayout} required>
        {getFieldDecorator('status', initialConfig)(
          <Select placeholder="请选择" options={editPageStatusOptions} />
        )}
      </Form.Item>
    );
  };

  renderStartTime = (startTime,config = { title: '上架时间', key: 'startTime' }) => {
    const { getFieldDecorator } = this.props.form;
    const rules = [
      {
        required: true,
        message: `请选择${config.title}`
      }
    ];

    if (typeof startTime === 'object') {
      config = startTime;
      startTime = null;
    }

    const initialConfig = {
      initialValue: startTime ? resolveTimestampToValue(startTime) : undefined,
      rules
    };

    return (
      <Form.Item label={config.title} {...formItemLayout} required>
        {getFieldDecorator(config.key, initialConfig)(
          <DatePicker
            showTime
            disabledDate={disabledRangeDate}
            format={datePickerConfig.format}
            onChange={this.changeStartTime}
            // locale={zh_CN}
            placeholder={`请选择${config.title}`}
          />
        )}
      </Form.Item>
    );
  };
  renderEndTime = (startTime, endTime,config = { title: '下架时间', key: 'expireTime', required: true }) => {

    // const mode = this.mode;
    const { getFieldDecorator } = this.props.form;

    const { title = '下架时间', key = 'expireTime', required = true } = config;

    const rules = [
      {
        required,
        message: `请选择${title}`
      }
    ];
    const initialConfig = {
      initialValue: endTime ? resolveTimestampToValue(endTime) : undefined,
      rules
    }

    return (
      <Form.Item label={title} {...formItemLayout} required={required}>
        {getFieldDecorator(key, initialConfig)(
          <DatePicker
            showTime
            format={datePickerConfig.format}
            placeholder={`请选择${title}`}
            onChange={this.changeEndTime}
            disabledDate={e => disabledEndTime(e, startTime || new Date())}
          />
        )}
      </Form.Item>
    );
  };
  getRenderContent(props) {
    return null;
  }
  clear() {}
  resetCategoryValue(fields) {
    this.props.form.resetFields(fields);
  }
  render() {
    return (
      <Spin spinning={this.state.loading}>
        {this.getRenderContent(this.props)}
      </Spin>
    );
  }
}
