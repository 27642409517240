import http from 'utils/request';

const baseConfig = {
  baseURL: '/api/v1/cost-control'
};

export default {
  /**
   * 列表，分页
   * @param {Object} data 请求参数，pagination: { current, pageSize }
   */
  fetchList(data) {
    return http.request('/entry/list', data, { ...baseConfig, method: 'get' });
  },
  /**
   * 修改或新增PB价差信息
   * @param {Object} data 提交数据
   */
  save(data) {
    return http.request('/priceTrendFirst/update', data, baseConfig);
  },
  /**
   *
   * @param {Object} data 价格数据
   */
  add(data) {
    return http.request('/priceTrendFirst/add', data, baseConfig);
  },
  /**
   * 删除excel
   */
  deleteExcel(id) {
    return http.request(`/entry/remove/${id}`, { id }, baseConfig);
  },
  /**
   * 删除PB价差信息
   * @param {Number} id PB价差信息id
   * @param {*} handlerName 操作人
   */
  delete(ids = [], handlerName) {
    return http.request('/priceTrendFirst/deleteList', {
      ids,
      handlerName,
    }, baseConfig)
  },
  /**
   * 获取模版下载地址
   */
  fetchTemplateUrl() {
    return http.request('/entry/download', {}, { method: 'get' }, baseConfig);
    },
  
  importData: `/api/v1/cost-control/entry/import-excel`,
};
