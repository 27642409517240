import http from 'utils/request';
import { API_PREFIX } from 'config/env';

export default {
  /**
   * 列表，分页
   * @param {Object} data 请求参数，pagination: { current, pageSize }
   * @enum {}
   */
  fetchList(data) {
    return http.request('/priceTrendSecond/getSecondList', data);
  },
  /**
   * 新增日照港价格对比数据
   */
  add(data) {
    return http.request('/priceTrendSecond/add', data);
  },
  /**
   * 修改或新增日照港PB粉PB块价差信息
   * @param {Object} data 提交数据
   */
  save(data) {
    return http.request('/priceTrendSecond/update', data);
  },
  /**
   * 获取日照港PB粉PB块价差信息详情
   * @param {Number} id 日照港PB粉PB块价差信息id
   */
  detail(id) {
    return http.request('/priceTrendSecond/getById', { id });
  },
  /**
   * 删除日照港PB粉PB块价差信息
   * @param {Number} id 日照港PB粉PB块价差信息id
   * @param {*} handlerName 操作人
   */
  delete(ids = [], handlerName) {
    return http.request('/priceTrendSecond/deleteList', {
      ids,
      handlerName,
    })
  },
  /**
   * 获取模版下载地址
   */
  fetchTemplateUrl() {
    return http.request('/priceTrendSecond/download', {}, { method: 'get' });
  },
  improtData: `${API_PREFIX}/priceTrendSecond/import`
};
