import React from 'react';
import { Link } from 'react-router-dom';

// import style from './logo.module.scss';
// import logo from 'assets/logo.png';

function Logo({ className }) {
  let headerLogo = localStorage.getItem('headerLogo')
  return (
    <Link className={`${className}`} to="/">
      <img src={headerLogo} alt="" />
    </Link>
  )
}

export default Logo;
