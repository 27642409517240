import React, { Component } from 'react';
import { Input, Select } from 'antd';

// import styles from './website.module.scss';

const { Option } = Select;

const defaultProtocol = 'http://';

function transformValue(value = '') {
  const [, protocol, domain] = (value || '').match(/(https?:\/\/)?([^\1].*)/) || [];
  return [
    protocol,
    domain
  ]
}

class WebsiteInput extends Component {
  state = {
    protocol: defaultProtocol,
    domain: '',
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value = '' } = nextProps;
    this.updateValue(value);
  }
  componentDidMount() {
    this.updateValue(this.props.value);
  }
  updateValue(value) {
    const [ protocol = this.state.protocol, domain = '' ] = transformValue((value || '').trim());
    this.setState({
      protocol,
      domain,
    });
  }
  handleProtocolChange = (protocol) => {
    this.setState({
      protocol,
    }, () => {
      this.triggerChangeEvent();
    });
  }
  handleSiteChange = (event) => {
    const value = event.target.value || '';
    const [protocol = this.state.protocol, domain = ''] = transformValue(value.trim());
    this.setState({
      domain,
      protocol
    }, () => {
      this.triggerChangeEvent();
    });
  }
  triggerChangeEvent() {
    const { onChange } = this.props;
    let { protocol, domain } = this.state;
    const value = domain ? `${protocol}${domain}` : '';
    if (onChange) {
      onChange(value);
    }
  }
  render() {
    const {
      protocol,
      domain,
    } = this.state;
    const selectBefore = (
      <Select style={{ width: 92 }} onChange={this.handleProtocolChange} value={protocol}>
        <Option value="http://">http://</Option>
        <Option value="https://">https://</Option>
      </Select>
    )
    return (
      <Input
        {...this.props}
        addonBefore={selectBefore}
        defaultValue=""
        value={domain}
        onChange={this.handleSiteChange}
      />
    );
  }
}

export default WebsiteInput;
