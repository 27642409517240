import appTheme from 'api/appTheme';

const initState = {
  list: [],
  pagination: {},
  detail: {},
};

export const types = {
  saveList: 'WEB_THEME_LIST',
  saveDetail: 'WEB_THEME_DETAIL',
  clear: 'CLEAR',
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.saveList:
      return Object.assign({}, state, { list: action.list, pagination: action.pagination });

    case types.saveDetail:
      return Object.assign({}, state, { detail: action.detail });

    case types.clear:
      return initState;

    default:
      return state;
  }
}

reducer.fetchList = (data) => {
  return (dispatch, getState) => {
    return appTheme.fetchList(data).then(data => {
      dispatch({
        type: types.saveList,
        list: data.list,
        pagination: data.pagination,
      });
    });
  }
}
reducer.update = (data) => {
  return (dispatch, getState) => {
    return appTheme.update(data);
  }
}

reducer.add = (data) => {
  return (dispatch) => {
    return appTheme.add(data);
  }
}

reducer.delete = (id, handlerName) => {
  return (dispatch) => {
    return appTheme.delete(id, handlerName);
  }
}

reducer.fetchDetail = (id) => {
  return (dispatch) => {
    return appTheme.detail(id).then(data => {
      // if (res.code === 0)
      dispatch({
        type: types.saveDetail,
        detail: data,
      });
      return data;
    });
  }
}

reducer.clear = () => {
  return (dispatch) => {
    dispatch({
      type: types.clear,
    });
  }
}

export default reducer;
