import diff from 'api/preControl';

const initState = {
    list: [],
    pagination: {},
    detail: {},
    template: '',
};

export const types = {
    saveList: 'DIFF_LIST',
    saveDetail: 'DIFF_DETAIL',
    saveTemplate: 'DIFF_TEMPLATE',
    clear: 'CLEAR',
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case types.saveList:
            return Object.assign({}, state, { list: action.list, pagination: action.pagination });
        case types.saveDetail:
            return Object.assign({}, state, { detail: action.detail });
        case types.saveTemplate:
            return Object.assign({}, state, { template: action.template });
        case types.clear:
            return initState;
        default:
            return state;
    }
}

reducer.fetchList = (data) => {
    return (dispatch) => {
        return diff.fetchList(data).then(data => {
            dispatch({
                type: types.saveList,
                list: data.data,
                pagination: {
                    current: data.pageNum,
                    pageSize: data.pageSize,
                    total: data.totalNum
                },
            });
            return data;
        });
    }
}

reducer.deleteExcel = (ids, handlerName) => {
    return () => {
        return diff.deleteExcel(ids, handlerName);
    }
}

reducer.add = (data) => {
    return () => {
        return diff.add(data);
    }
}

reducer.update = (data) => {
    return () => {
        return diff.save(data);
    }
}

reducer.delete = (ids, handlerName) => {
    return () => {
        return diff.delete(ids, handlerName);
    }
}

reducer.fetchDetail = (id) => {
    return (dispatch) => {
        return diff.detail(id).then(data => {
            dispatch({
                type: types.saveDetail,
                detail: data || {},
            });
            return data;
        });
    }
}

reducer.fetchTemplate = () => {
    return (dispatch) => {
        return diff.fetchTemplateUrl().then(data => {
            dispatch({
                type: types.saveTemplate,
                template: data,
            })
            return data;
        });
    }
}

reducer.clear = () => {
    return (dispatch) => {
        dispatch({
            type: types.clear,
        });
    }
}

export default reducer;
