import React from 'react';
import {
  Form,
  Input,
  Button,
  DatePicker,
  Modal,
  message,
  TreeSelect,
  Spin
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeader from 'modules/PageHeader';
import Select2 from 'components/Select2';
import FormItem from 'components/FormItem';
import Table from 'components/Table2';
import WrapAuth from 'components/WrapAuth';
import CommonList from '../common/List';
import { filterStatusOptions, datePickerConfig, platforms, path } from 'config';
import {
  resolveTimeText,
  resolvePageStatusText,
  resolveTreeNodes,
  resolvePlatformNames,
  downloadFileByForm
} from 'utils';
import reaction from 'reaction';
import commonApi from 'api/common';

import style from '../page.module.scss';

/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */
const { Column } = Table;
const { FormItemGroup } = FormItem;
const { confirm } = Modal;

const { RangePicker } = DatePicker;
const newsPath = path.cms.news;

@connect(({ news, user, category }) => ({ news, user, category }))
@Form.create()
class NewsList extends CommonList {
  UNSAFE_componentWillMount() {
    this.loadCategories();
  }
  handlePlatformChange = platform => {
    platform !== null && this.props.form.resetFields(['categoryId']);
    this.loadCategories(platform);
  };
  clear() {
    reaction.category.clear();
  }
  loadCategories(platform = null) {
    reaction.category.fetchNodes({
      parentId: 0,
      showSub: true,
      platform
    });
  }
  loadData(params) {
    reaction.news.fetchList(params).then(err => {
      this.setState({
        loading: false
      });
    });
  }
  getExportData() {
    const {
      rangeTime = [],
      author = null,
      status = null,
      title = null,
      categoryId = null
    } = this.props.form.getFieldsValue();
    const [startTime, endTime] = rangeTime;

    return {
      startTime: startTime ? startTime.format(datePickerConfig.format) : null,
      endTime: endTime ? endTime.format(datePickerConfig.format) : null,
      author,
      status,
      title,
      categoryId,
      timestamp: +new Date(),
    };
  }
  handleExportData = () => {
    downloadFileByForm({
      action: commonApi.exportNewsData,
      data: this.getExportData()
    });
  };
  handleDelete(id) {
    confirm({
      title: '确定删除此资讯吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        reaction.news.delete(id, this.props.user.username).then(() => {
          message.success('删除成功');
          this.reloadData(!this.props.news.list.length);
        });
      }
    });
  }
  render() {
    const {
      form: { getFieldDecorator },
      news: { list = [], pagination = {} },
      category: { nodes = [] }
    } = this.props;
    const categories = resolveTreeNodes(nodes);
    return (
      <div className="page-inner">
        <PageHeader title="资讯列表">
          <WrapAuth path={newsPath.add.path}>
            <Link to="/news/add" className="page-btn">
              <b>+</b>&nbsp;添加资讯
            </Link>
          </WrapAuth>
        </PageHeader>
        <div className={style.filter}>
          <FormItemGroup>
            <FormItem
              label="平台："
              className="form-item-normal"
              labelWidth={42}
            >
              {getFieldDecorator('platform', {
                initialValue: null
              })(
                <Select2
                  placeholder="请选择"
                  options={platforms}
                  style={{ width: 170 }}
                  onChange={this.handlePlatformChange}
                  filterHidden={false}
                />
              )}
            </FormItem>
            <FormItem label="栏目名称：" labelWidth={70}>
              {getFieldDecorator('categoryId')(
                <TreeSelect
                  style={{ width: 180 }}
                  placeholder="请选择"
                  className="select2"
                  disabled={!categories.length}
                  treeData={categories}
                />
              )}
            </FormItem>
            <FormItem label="发布者：" labelWidth={70}>
              {getFieldDecorator('author', {
                initialValue: ''
              })(
                <Input
                  style={{ width: 155 }}
                  maxLength={10}
                  placeholder="请输入发布者名称"
                />
              )}
            </FormItem>
            <FormItem label="状态：" labelWidth={58}>
              {getFieldDecorator('status', {
                initialValue: null
              })(
                <Select2
                  width={90}
                  placeholder="请选择"
                  filterHidden={false}
                  className="select2"
                  options={filterStatusOptions}
                />
              )}
            </FormItem>
            <Button
              style={{margin: '0 0 0 10px'}}
              type="primary"
              onClick={this.handleSearchData}
            >
              查询
            </Button>
          </FormItemGroup>
          <FormItemGroup>
            <FormItem
              label="标题："
              className="form-item-normal"
              labelWidth={42}
            >
              {getFieldDecorator('title', {
                initialValue: ''
              })(
                <Input
                  style={{ width: 305 }}
                  maxLength={50}
                  placeholder="请输入资讯标题"
                />
              )}
            </FormItem>
            <FormItem label="上架时间：">
              {getFieldDecorator('rangeTime')(
                <RangePicker
                  style={{ width: 350 }}
                  placeholder={['开始时间', '结束时间']}
                  className="range-date-picker"
                />
              )}
            </FormItem>
            <WrapAuth path={newsPath.export.path}>
              <Button
                style={{margin: '0 0 0 10px'}}
                type="primary"
                icon="export"
                onClick={this.handleExportData}
              >
                导出数据
              </Button>
            </WrapAuth>
          </FormItemGroup>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={list}
            loading={false}
            rowKey={(row, index) => row.id || index}
            pagination={this.getPageConfig(pagination)}
            borderd
          >
            <Column
              title="栏目名称"
              key="categoryName"
              dataIndex="categoryName"
              align="center"
              width={100}
            />
            <Column
              title="标题"
              key="title"
              dataIndex="title"
              align="center"
              width={200}
            />
            <Column
              title="来源"
              key="platform"
              dataIndex="platform"
              align="center"
              width={70}
              render={value => resolvePlatformNames({ value })}
            />
            <Column
              title="初始浏览量"
              key="initialClick"
              dataIndex="initialClick"
              align="center"
              width={80}
            />
            <Column
              title="实际浏览量"
              key="clickCount"
              dataIndex="clickCount"
              align="center"
              width={80}
            />
            <Column
              title="上架时间"
              key="startTime"
              dataIndex="startTime"
              align="center"
              width={124}
              render={time => resolveTimeText(time)}
            />
            <Column
              title="下架时间"
              key="expireTime"
              dataIndex="expireTime"
              align="center"
              width={122}
              render={time => resolveTimeText(time)}
            />
            {/* <Column title="排序" key="sorted" dataIndex="sorted" align="center" width={68} /> */}
            <Column
              title="状态"
              key="status"
              dataIndex="status"
              align="center"
              width={68}
              render={status => resolvePageStatusText(status)}
            />
            <Column
              title="操作"
              key="handler"
              align="center"
              render={row => {
                return (
                  <React.Fragment>
                    <WrapAuth path={newsPath.update.path}>
                      <Link className="link-handle" to={`/news/edit/${row.id}`}>
                        编辑
                      </Link>
                    </WrapAuth>
                    <WrapAuth path={newsPath.delete.path}>
                      <a
                        className="link-handle"
                        onClick={() => this.handleDelete(row.id)}
                        href="javascript:;"
                      >
                        删除
                      </a>
                    </WrapAuth>
                  </React.Fragment>
                );
              }}
            />
          </Table>
        </Spin>
      </div>
    );
  }
}

export default NewsList;
