function setCookie(key, { value = '', days = 0.1, path = '/', domain}) {
  let expires;
  let d = new Date();
  if (days) {
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = 'expires=' + d.toUTCString();
    domain && (document.cookie = key + '=' + value + '; ' + expires + ';path=' + path + ';domain=' + domain);
    domain || (document.cookie = key + '=' + value + '; ' + expires + ';path=' + path);
  } else {
    domain && (document.cookie = key + '=' + value + ';path=' + path + ';domain=' + domain);
    domain || (document.cookie = key + '=' + value + ';path=' + path);
  }
  // days && (document.cookie = key + '=' + value + '; ' + expires + ';path=' + path);
  // !days && (document.cookie = key + '=' + value + ';path=' + path);
}

function getCookie(key, path = '/') {
  key = path === '/' ? key : key + path.replace('/', '_');
  var value = '';
  if (document.cookie.length > 0) {
    var arr = document.cookie.split(';');
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].split('=')[0].trim() === key) {
        value = arr[i].split('=')[1];
      }
    }
  }
  return value;
}

export default {
  setCookie, getCookie
};
