import React from 'react';

import style from './item.module.scss';

export function FormItem ({ label,className, labelWidth = false,align = 'right',size = 'normal', style: otherStyle, children}) {
  const labelStyle = {
    minWidth: labelWidth,
  };
  return (
    <div className={`${style.wrapper} ${className || ''}`} style={otherStyle}>
      <div className={`${style.label} ${style['align-' + align]} ${style['label-size-'+size]}`} style={labelWidth ? labelStyle : {}}>
        {label}
      </div>
      <div className={style.inner}>
        {children}
      </div>
    </div>
  );
 }

 export function FormItemGroup({ children, className }) {
  return (
    <div className={`${style.group} ${className ? className : ''}`}>
      {children}
    </div>
  );
 }


FormItem.FormItemGroup = FormItemGroup;

export default FormItem;
