import React, { Component, memo } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import menus from 'config/menu';
import reaction from 'reaction';
import memoOne from 'memoize-one';
import { checkAuth, saveAuth } from 'utils';

import style from './side-menu.module.scss';

const SubMenu = Menu.SubMenu;

const findHasSelectedItemSubmenu = memoOne(function(menus = [], path) {
  let index = null;
  menus.some((menu, i) => {
    if (menu.children && menu.children.length) {
      const hasMatch = menu.children.some(item => item.path.indexOf(path) !== -1);
      if (hasMatch) {
        index = i;
        return true;
      }
      return false;
    }
    return false;
  });
  return index;
});

const filterHasPermissionMenus = memoOne((auth) => {
  return menus.map(item => {
    if (item.children && item.children.length) {
      return {
        ...item,
        children: item.children.filter(child => checkAuth(child.flag, auth)),
      };
    }
    return item;
  }).filter(item => {
    return item.children ? item.children.length > 0 : checkAuth(item.flag);
  });
});

const resolvePermissionByPath = memoOne(function(hasPermissionMenus = [], path = '') {
  return hasPermissionMenus.find(menu => {    
    return path.includes(menu.path) || (menu.children && menu.children.find(child => path.includes(child.path)))
  });
});

@connect(({ user }) => ({
  user
}))
@withRouter
class SideMenu extends Component {
  state = {
    auth: undefined,
  }
  fetchAuthInfo() {
    reaction.user.getUserAuth().then(data => {
      let cache = data || {
        roleInfo: {
          roleAuthList: []
        }
      };
      this.setState({
        auth: saveAuth(cache.roleInfo.roleAuthList),
      });
    });
  }
  componentDidMount() {
    this.fetchAuthInfo();
  }
  renderHasPermissionMenus = memoOne((hasPermissionMenus) => {
    return hasPermissionMenus.map((item, index) => {
      const { path, exact = false, name, hidden = false, children = [] } = item;
      if (!hidden) {
        if (children && children.length) {
          const hasPermissionChildren = children.filter(el => checkAuth(el.flag));
          if (!hasPermissionChildren.length) {
            return null;
          }
          return (
            <SubMenu key={'root-' + index} title={name}>
              {
                hasPermissionChildren.map(({ path, name, exact = false }) => {
                  return (
                    <Menu.Item
                      key={path}
                    >
                      <NavLink
                        to={path}
                        exact={exact}
                        activeClassName={style.active}
                      >
                        {name}
                      </NavLink>
                  </Menu.Item>
                  )
                })
              }
            </SubMenu>
          )
        }
        return (
          <Menu.Item
            key={path}
          >
            <NavLink
              to={path}
              exact={exact}
              activeClassName={style.active}
            >
              {name}
            </NavLink>
          </Menu.Item>
        );
      }
      return null;
    });
  });

  goFirstHasPermissonPage(menus = []) {
    const { history } = this.props;
    if (menus.length) {
      history.replace(menus[0].path);
    }
  }

  render() {
    const {
      match: {
        path: currentPath,
      }
    } = this.props;
    const auth = this.state.auth;
    const hasPermissionMenus = filterHasPermissionMenus(auth);
    const defaultOpenIndex = findHasSelectedItemSubmenu(hasPermissionMenus, currentPath);
    const pathToPermisson = resolvePermissionByPath(hasPermissionMenus, currentPath);

    if (!pathToPermisson) {
      // 如果当前页面已经没有权限了，那么就重定向到第一个有权限的页面去
      this.goFirstHasPermissonPage(hasPermissionMenus);
      // return null;
    }
    
    return (
      <div className={style.wrapper}>
        <Menu
          mode="inline"
          forceSubMenuRender
          inlineIndent={15}
          defaultSelectedKeys={[currentPath]}
          defaultOpenKeys={defaultOpenIndex !== null ? ['root-' + defaultOpenIndex] : []}
        >
          {this.renderHasPermissionMenus(hasPermissionMenus)}
        </Menu>
      </div>
    );
  }
}

export default memo(SideMenu);
