import React, { useState } from 'react';
import { Button } from 'antd';
import { downloadFileByForm } from 'utils';

function DownloadTemplate({ children, loadData, ...props }) {

  const [ loading, setLoading ] = useState(false);
  const handleClick = () => {
    setLoading(true);
    loadData().then(action => {
      setLoading(false);
      downloadFileByForm({
        action,
      });
    });
  }

  return (
    <Button
      {
        ...props
      }
      icon="download"
      onClick={handleClick}
      disabled={loading}
      loading={loading}
    >
      {children || '导入模板下载'}
    </Button>
  )
}


export default DownloadTemplate;
