import React, { Component } from 'react'; // eslint-disable-line
import { Upload, Icon, Button, message } from 'antd';
import api from 'api';
import { multipartUpload } from 'utils/oss';

const retryCountMax = 3;
const doneCache = {};

let uploadFileClient;
let failReTryCount = 0;
let retryCount = 0;
let uploadStatus = 0;
let isDestory = false;

export default class OssUpload extends Component {
  state = {
    fileList: [],
  }
  componentDidMount() {
    isDestory = false;
    uploadStatus = 0;
    retryCount = 0;
    failReTryCount = 0;
  }
  componentWillUnmount() {
    isDestory = true;
    this.cancelUpload();
  }
  cancelUpload() {
    if (uploadFileClient && uploadStatus !== 1) {
      uploadFileClient.cancel();
    }
  }

  uploadFile = (client, uploadInfo = {}) => {
    let { file, onSuccess, onProgress, onError, fileKey } = uploadInfo;
    const date = new Date();
    const month = date.getMonth() + 1;


    if (!uploadFileClient || Object.keys(uploadFileClient).length === 0) {
      uploadFileClient = client;
    }
    const fileSize = file.size;
    const fileUid = file.uid;
    const fileType = file.type;

    uploadStatus = 0;
    // fileKey 不能以/开头。
    fileKey = `cms/${date.getFullYear()}${month < 10 ? '0' + month : month}/${fileKey}`;

    if (!doneCache[fileKey]) {
      doneCache[fileKey] = {
        checkpoint: null,
      };
    }
    console.time('times');
    const cache = doneCache[fileKey];
    console.log('cache', cache);
    const options = {
      progress: (percent, checkpoint) => {
        cache.checkpoint = checkpoint;
        if (isDestory) {
          return ;
        }
        console.info('progress：', (percent * 100).toFixed(2)+ '%');
        onProgress({
          percent: percent * 100
        }, file);
      },
      ...cache,
      timeout: 60000
    };
    return uploadFileClient.multipartUpload(fileKey, file, options)
      .then(({ res }) => {
        uploadStatus = 1;
        cache.checkpoint = null;
        const saveAs = (res.requestUrls[0] || '').split('?')[0];
        function successCallback() {

          console.timeEnd('times');
          console.log('upload success: %j', res);

          if (isDestory) {
            return ;
          }

          onSuccess({
            url: saveAs,
            code: 200,
          }, {
            status: 'done',
            name: file.name,
            uid: fileUid,
            size: fileSize,
            percent: 100,
            type: fileType,
            // response: {
            //   data: url,
            //   code: 200,
            // },
            url: saveAs,
          });
          uploadFileClient = null;
        }
          // debugger ; // eslint-disable-line
          api.common.setFilePermission(fileKey)
          .then(successCallback)
          .catch(error => {
            console.log('设置权限失败...', error);
            !isDestory && this.triggerErrorFileInfo('服务器错误');
          });
      })
      .catch((err) => {
        if (isDestory) {
          return ;
        }
        if (doneCache[fileKey].checkpoint.doneParts.length < 100 && failReTryCount < retryCountMax) {
          ++ failReTryCount;
          console.info(`上传失败，正在进行第【${failReTryCount}】次重试，最多尝试【${retryCountMax}】次。`);
          this.uploadFile(client, uploadInfo);
          return ;
        }
        if (uploadFileClient && uploadFileClient.isCancel()) {
          onError(new Error('取消上传'), err, file);
          this.triggerErrorFileInfo('取消上传');
        } else {
          console.log(`err.name : ${err.name}`);
          console.log(`err.message : ${err.message}`);
          if (err.name.toLowerCase().indexOf('connectiontimeout') !== -1) {
            // timeout retry
            if (retryCount < retryCountMax) {
              retryCount++;
              console.error(`retryCount : ${retryCount}`);
              this.uploadFile(client, uploadInfo);
            } else {
              onError(err, err, file)
              this.triggerErrorFileInfo('上传失败');
            }
          } else {
            this.triggerErrorFileInfo('上传失败');
            onError(err, err, file)
          }
        }
      });
  }
  handleUpload = ({ file, onProgress, onSuccess, onError }) => {
    multipartUpload({
      validPeriodSeconds: 3600,
      read: false,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type.split('/')[1]
    }).then(({client, fileKey}) => {
      this.uploadFile(client, {
        file,
        onProgress,
        onSuccess,
        onError,
        fileKey
      });
    })
    .catch(err => {
      console.log(err);
      this.triggerErrorFileInfo('服务器错误');
      message.error('服务器错误');
    });
  }
  handleChange = ({ fileList, file }) => {
    const { onChange } = this.props;
    const fileInfo = {
      ...file,
      url: file.response && file.response.url ? file.response.url : '',
      fileInfo: file
    }
    this.setState({
      fileList,
    });
    onChange(fileList, fileInfo);
  }
  triggerErrorFileInfo(tips) {
    const {
      fileList,
    } = this.state;
    const [ file ] = fileList;
    const errorFileInfo = {
      name: file.name,
      status: 'done',
      uid: file.uid,
      response: {
        msg: tips,
      },
      url: '',
    };
    uploadStatus = 0;
    this.props.onChange([errorFileInfo], errorFileInfo);
  }
  render() {
    const {
      onChange,
      fileListRender,
      buttonText = '点击上传',
      children,
      ...props
    } = this.props;
    const {
      fileList
    } = this.state;
    const fileListItem = fileListRender ? fileListRender(fileList) : '';
    const uploadFileSelectButton = (
      <Button>
        <Icon type="upload" /> {buttonText}
      </Button>
    );
    return (
      <div>
        <Upload
          {...props}
          onChange={this.handleChange}
          customRequest={this.handleUpload}
          fileList={fileList}
          disabled={1 <= fileList.length}
        >
          {
            (1 > fileList.length) ? children || uploadFileSelectButton: fileListItem
          }
        </Upload>
      </div>
    )
  }
}
