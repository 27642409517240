import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, message, Input, Spin, DatePicker } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import reaction from 'reaction';
import PageHeader from 'modules/PageHeader';
import Select from 'components/Select2';
import FormItem from 'components/FormItem';
import Table from 'components/Table2';
import WrapAuth from 'components/WrapAuth';
import CommonList from '../common/List';
import { resolvePageStatusText, resolvePlatformNames } from 'utils';
import { helpFilterOptions, platformsForHelp, path } from 'config';

import style from '../page.module.scss';
import moment from 'moment';

const { Column } = Table;
const { FormItemGroup } = FormItem;
const { confirm } = Modal;
const RangePicker = DatePicker.RangePicker;
const helpPath = path.cms.help;

@withRouter
@connect(({ help, user }) => ({ help, user }))
@Form.create()
class Help extends CommonList {
  loadData (params) {
    params.updateTime = params.updateTime ? moment(new Date(params.updateTime).getTime()).startOf('day').valueOf() : null;
    reaction.help.fetchList(params)
      .then(err => this.setState({ loading: false }));
      // this.fetchCategories();

  }
  handlePlatformChange = (platform) => {
    platform !== null && this.props.form.resetFields(['parentId']);
    // this.fetchCategories(platform);
  }
  // fetchCategories(platform = null) {
  //   reaction.help.fetchNodes({
  //     parentId: 0,
  //     showSub: true,
  //     platform
  //   });
  // }
  clear () {
    reaction.help.clear();
  }
  handleDelete (id) {
    confirm({
      title: '确定删除吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        reaction.help.delete(id, this.props.user.username).then(() => {
          message.success('删除成功');
          this.reloadData(!this.props.help.list.length);
        });
      },
    });
  }
 
  saveDateValue(field, v) {
    this.setState({
      [field]: v,
    });
  }
  clearDateValue() {
    this.setState({
      startTime: null,
      endTime: null,
    });
  }
  render () {
    const { form: { getFieldDecorator }, help: { list = [], pagination = {} } } = this.props;
    return (
      <div className="page-inner">
        <PageHeader title="帮助中心">
          <WrapAuth path={helpPath.add.path}>
            <Link to="/help/add" className="page-btn">
              +&nbsp;添加帮助
            </Link>
          </WrapAuth>
        </PageHeader>
        <div className={style.filter}>
          <FormItemGroup>
            <FormItem label="所属平台：" style={{ marginRight: 20 }}>
              {getFieldDecorator('platform', {
                initialValue: null,
              })(
                <Select
                  width={120}
                  options={platformsForHelp}
                  onChange={this.handlePlatformChange}
                  filterHidden={false}
                />
              )}
            </FormItem>
            <FormItem label="标题：" className="form-item-filter" style={{ marginRight: 20 }}>
              {getFieldDecorator('title', {
                initialValue: '',
              })(<Input style={{ width: 180 }} placeholder="请输入标题"/>)}
            </FormItem>
            <FormItem label="状态：" style={{ marginRight: 20 }}>
              {getFieldDecorator('status', {
                initialValue: null,
              })(
                <Select
                  width={120}
                  options={helpFilterOptions}
                />
              )}
            </FormItem>
            <FormItem label="发布者：" className="form-item-filter" style={{ marginRight: 20 }}>
              {getFieldDecorator('handlerName', {
                initialValue: '',
              })(<Input style={{ width: 180 }} placeholder="请输入发布者" maxLength={ 10 }/>)}
            </FormItem>
            <FormItem label="发布时间：" style={{ marginRight: 20, marginTop: 5 }}>
              {getFieldDecorator('rangeTime', {
                initialValue: '',
              })(
                <RangePicker
                  style={{ width: 350 }}
                  placeholder={['开始时间', '结束时间']}
                  className="range-date-picker"
                />
              )}
            </FormItem>
            
            <Button type="primary" onClick={this.handleSearchData} style={{ marginTop: 5 }}>
              查询
            </Button>
          </FormItemGroup>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={list}
            loading={false}
            rowKey={(row, index) => index}
            pagination={this.getPageConfig(pagination)}
            borderd
          >
            <Column title="序号" key="index" align="center" render={(row, arr, index) => index + 1} width={60} />
            <Column
              title="所属平台"
              key="platform"
              dataIndex="platform"
              align="center"
              width={100}
              render={(value) => resolvePlatformNames({ value })}
            />
            <Column title="栏目名称" key="categoryName" dataIndex="categoryName" align="center" width={110} />
            <Column title="标题" key="title" dataIndex="title" align="center" width={110} />
            <Column title="发布者" key="handlerName" dataIndex="handlerName" align="center" width={110} />
            <Column title="发布时间" key="handleTime" dataIndex="handleTime" align="center" width={90} render={(row, arr, index) => moment(row).format('YYYY-MM-DD HH:mm:ss')}/>
            <Column
              title="状态"
              key="status"
              dataIndex="status"
              align="center"
              render={(status) => resolvePageStatusText(status)}
              width={80}
            />
            <Column
              title="排序"
              key="sorted"
              dataIndex="sorted"
              align="center"
              width={50}
            />
            <Column
              title="操作"
              key="handler"
              align="center"
              // width={100}
              render={(row) => {
                return (
                  <div>
                    <WrapAuth path={helpPath.update.path}>
                      <Link to={`/help/edit/${row.id}`} className="link-handle">
                        编辑
                      </Link>
                    </WrapAuth>
                    <WrapAuth path={helpPath.delete.path}>
                      {
                        row.articleCount > 0 ? (
                          <a href="javascript:;" className="link-handle link-handle-disabled">删除</a>
                        ) : (
                          <a href="javascript:;" className="link-handle" onClick={() => this.handleDelete(row.id)}>删除</a>
                        )
                      }
                    </WrapAuth>
                  </div>
                );
              }}
            />
          </Table>
        </Spin>
      </div>
    );
  }
}

export default Help;
