/**
 * Created by Administrator on 2017/1/20.
 * edit 2017/08/31 @r91
 * 兼容sessionStorage
 */
import env from '../config/env';
let keyGetter = function(key) {
  return `${window.location.hostname}-${window.location.port}-${env.PROJECT_ID}[${key}]`;
};
export function storage(Storage = localStorage) {
  return {
    getItem(key) {
      return Storage.getItem(keyGetter(key));
    },
    setItem(key, data) {
      Storage.setItem(keyGetter(key), data);
    },
    getJSONItem(key) {
      let valueString = Storage.getItem(keyGetter(key));
      if (!valueString) return;
      try {
        return JSON.parse(valueString);
      } catch (e) {
        return {};
      }
    },
    setJSONItem(key, data) {
      Storage.setItem(keyGetter(key), JSON.stringify(data));
    },
    removeItem(key) {
      return Storage.removeItem(keyGetter(key));
    },
    clear() {
      Storage.clear();
    }
  };
}
export const sessionStorage = storage(window.sessionStorage);

export default storage(window.localStorage);

