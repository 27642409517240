import React from 'react';
import { Form, Input, Button, Modal, message, Spin, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import reaction from 'reaction';
import PageHeader from 'modules/PageHeader';
import Select from 'components/Select2';
import FormItem from 'components/FormItem';
import Table from 'components/Table2';
import Alert from 'components/Alert';
import WrapAuth from 'components/WrapAuth';
import CommonDetail from '../common/Detail';
import CommonList from '../common/List'
import { noticePages, filterStatusOptions, noticeLabels, path, noticeTypeOptions } from 'config';
import { resolveTimeText, resolveOnineStatus } from 'utils';

import style from '../page.module.scss';

const { Column } = Table;
const { FormItemGroup } = FormItem;
const { confirm } = Modal;
const { RangePicker } = DatePicker;
const noticePath = path.cms.notice;

@withRouter
@connect(({ notice, user }) => ({ notice, user }))
@Form.create()
class NoticeList extends CommonList {
  loadData(params) {
    reaction.notice.fetchList(params)
      .then(err => {
        this.setState({
          loading: false,
        });
      });
  }
  handleDelete(id) {
    confirm({
      title: '确定删除此公告吗？',
      cancelText: '取消',
      okText: '确定',
      onOk:() => {
        reaction.notice.delete(id, this.props.user.username)
          .then(() => {
            message.success('删除成功');
            this.reloadData();
          });
      }
    })
  }
  handleCancel(id) {
    confirm({
      title: '确定下架此公告吗？',
      cancelText: '取消',
      okText: '确定',
      onOk:() => {
        reaction.notice.cancel(id, this.props.user.username)
          .then(() => {
            message.success('下架成功');
            this.reloadData(!this.props.notice.list.length);
          });
      }
    });
  }
  handlePreviewNotice(id) {
    this.setState({
      loadingDetail: true,
    });
    reaction.notice.clear('detail');
    reaction.notice.fetchDetail(id)
      .then(() => {
        this.setState({
          loadingDetail: false,
        });
      })
      this.$alert.show();

  }
  render() {
    const {
      form: {
        getFieldDecorator,
      },
      notice: {
        list = [],
        pagination = {},
        detail,
      },
    } = this.props;
    return (
      <div className="page-inner">
        <Alert ref={dom => this.$alert = dom} width="80%">
          <CommonDetail data={detail} labelConfig={noticeLabels} loading={this.state.loadingDetail}></CommonDetail>
        </Alert>
        <PageHeader
          title="公告列表"
        >
          <Link to="/notice/add" className="page-btn">+&nbsp;添加公告</Link>
        </PageHeader>
        <div className={style.filter}>
          <FormItemGroup>
            <FormItem
              label="所属平台："
              className="form-item-filter"
            >
              {
                getFieldDecorator('position', {
                  initialValue: null,
                })(
                  <Select
                    width={180}
                    placeholder="请选择"
                    className="select2"
                    options={noticePages}
                    filterHidden={false}
                  />
                )
              }
            </FormItem>
            <FormItem
              label="公告标题："
              style={{ marginRight: 40 }}
            >
              {
                getFieldDecorator('title', {
                  initialValue: '',
                })(
                  <Input
                    style={{ width: 305 }}
                    placeholder="请输入公告标题"
                  />
                )
              }
            </FormItem>
            <FormItem
              label="状态："
              style={{ marginRight: 47 }}
            >
              {
                getFieldDecorator('status', {
                  initialValue: null,
                })(
                  <Select
                    width={90}
                    placeholder="请选择"
                    className="select2"
                    options={filterStatusOptions}
                    filterHidden={false}
                  />
                )
              }
            </FormItem>
            <FormItem
              label="公告类型："
              style={{ marginRight: 47 }}
            >
              {
                getFieldDecorator('noticeType', {
                  initialValue: null,
                })(
                  <Select
                    width={90}
                    placeholder="请选择"
                    className="select2"
                    options={noticeTypeOptions}
                    filterHidden={false}
                  />
                )
              }
            </FormItem>
            <FormItem label="上架时间：" style={{ marginRight: 30 }}>
              {getFieldDecorator('rangeTime')(
                <RangePicker
                  style={{ width: 230 }}
                  placeholder={['开始日期', '结束日期']}
                  className="range-date-picker"
                />
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearchData}>查询</Button>
          </FormItemGroup>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={list}
            loading={false}
            rowKey={(row, index) => index}
            pagination={this.getPageConfig(pagination)}
            borderd
          >
            <Column
              title="所属平台"
              key="positionName"
              dataIndex="positionName"
              align="center"
              width={100}
            />
            <Column
              title="标题"
              key="title"
              dataIndex="title"
              align="center"
              width={250}
            />
            <Column
              title="公告类型"
              key="noticeType"
              dataIndex="noticeType"
              render={(text) => text === 'POPUPS' ? '弹窗' : '非弹窗'}
              align="center"
              width={100}
            />
            <Column
              title="上架时间"
              key="startTime"
              dataIndex="startTime"
              align="center"
              width={110}
              render={(time) => resolveTimeText(time)}
            />
            <Column
              title="下架时间"
              key="expireTime"
              dataIndex="expireTime"
              align="center"
              width={110}
              render={(time) => resolveTimeText(time)}
            />
            {/* <Column
              title="排序"
              key="sorted"
              dataIndex="sorted"
              align="center"
              width={68}
            /> */}
            <Column
              title="状态"
              key="status"
              align="center"
              width={68}
              render={({ status, startTime, expireTime }) => resolveOnineStatus({ startTime, expireTime, status, options: filterStatusOptions })}
            />
            <Column
              title="操作"
              key="handler"
              align="center"
              render={(row) => {
                return (
                  <div>
                    <WrapAuth path={noticePath.update.path}>
                      <Link to={`/notice/edit/${row.id}`} className="link-handle">编辑</Link>
                    </WrapAuth>
                    <WrapAuth path={noticePath.delete.path}>
                      <a href="javascript:;" className="link-handle" onClick={() => this.handleDelete(row.id)}>删除</a>
                    </WrapAuth>
                    <WrapAuth path={noticePath.expired.path}>
                      {
                        row.status !== 'CANCEL' && <a href="javascript:;" className="link-handle" onClick={() => this.handleCancel(row.id)}>下架</a>
                      }
                    </WrapAuth>
                    <a href="javascript:;" onClick={() => this.handlePreviewNotice(row.id)} className="link-handle">查看</a>
                  </div>
                );
              }}
            />
          </Table>
        </Spin>
      </div>
    );
  }
}

export default NoticeList;
