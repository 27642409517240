import React from 'react';
import { Form, Input, Button, Radio } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'components/Select2';
import CommonEdit from '../common/Edit';
// import Editor from 'components/Editor';
// import TextArea from 'components/TextArea';
// import BraftEditor from 'braft-editor'
import reaction from 'reaction';
// import Editor from 'components/Editor';
import { noticePages, editPageStatusOptions } from 'config';

import style from '../page.module.scss';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
}

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

@connect(({ notice, user }) => ({ notice, user }))
@Form.create()
class NoticeEdit extends CommonEdit {
  loadData(id) {
    this.loadDetailData(id);
  }
  loadDetailData(id) {
    reaction.notice.fetchDetail(id).then(({ startTime }) => {
      this.setState({
        startTime,
        loading: false,
      });
    });
  }
  clear() {
    reaction.notice.clear();
  }
  submitData(data) {
    reaction.notice.update(data).then(res => {
      this.setState({
        submitting: false,
      });
      this.props.history.replace('/notice');
    }).catch(err => {
      this.setState({
        submitting: false,
      });
    });
  }
  getRenderContent() {
    let {
      form: {
        getFieldDecorator,
      },
      notice: {
        detail,
      },
    } = this.props;
    const {
      submitting,
      startTime,
      loading,
    } = this.state;
    detail = detail || {};
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>编辑公告</span>
          {/* <span className={style.crm}>添加公告<span className={style.split}>/</span><Link to="/notice">公告列表</Link></span> */}
        </div>
        <div className={`${style.form} page-form`}>
          {
            getFieldDecorator('id', {
              initialValue: detail.id,
            })(
              <Input hidden />
            )
          }
          <Form.Item
            label="所属平台"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('position', {
                initialValue: detail.position || undefined,
                rules: [{
                  required: true,
                  message: '请选择所属平台',
                }],
              })(
                <Select
                  placeholder="请选择"
                  options={noticePages}
                  style={{ width: 195 }}
                />
              )
            }
          </Form.Item>
          {this.renderTitle(detail.title)}
          <Form.Item label="公告类型" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('noticeType', {
              initialValue: detail.noticeType || 'POPUPS'
            })(
              <Radio.Group>
                <Radio value="POPUPS">弹窗</Radio>
                <Radio value="NON_POPUPS">非弹窗</Radio>
              </Radio.Group>,
            )}
          </Form.Item>
          <Form.Item
            label="状态"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('status', {
                initialValue: detail.status,
                rules: [{
                  required: true,
                  message: '请选择状态',
                }],
              })(
                <Select
                  placeholder="请选择"
                  filterHidden={false}
                  options={editPageStatusOptions}
                  style={{ width: 195 }}
                />
              )
            }
          </Form.Item>
          {
            detail.startTime && this.renderStartTime(detail.startTime)
          }
          {
            detail.expireTime && this.renderEndTime(startTime, detail.expireTime, { key: 'expireTime', title: '下架时间' })
          }
          {
            !loading ? this.renderEditor(detail.content) : null
          }
          <Form.Item
            label=""
            {...formTailLayout}
            required
          >
            <Button
              type="primary"
              className={style['button']}
              onClick={() => this.handlePublish(this.props.form)}
              loading={submitting}
            >保存</Button>
            <Link className={`${style.button} ${style['button-back']}`} to="/notice">返回</Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default NoticeEdit;
