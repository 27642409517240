import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { message, LocaleProvider } from 'antd';
import store from './store';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import Login from 'pages/login';
import { validationLoginStatus, getToken } from 'utils';
import { basicRoutes, getRoutes } from 'config/routes';
import http from 'utils/request';
import { toLogin, loginInit } from 'utils/user';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

const isLoginPage = window.location.pathname.indexOf('/login') !== -1;
// 初始化的登录配置
loginInit();

if (!isLoginPage && !validationLoginStatus()) {
  toLogin(false);
}

const successCode = [0, 200, null];

http
  .token(function () {
    return getToken();
  })
  .url(url => {
    return `${url}?${+new Date()}`;
  })
  .codeHook(res => {
    if (successCode.includes(res.code)) {
      return res;
    }
    if (res.code === 3303 || res.code === 1102) {
      toLogin(false);
      return;
    }
    res.msg && message.error(res.msg);
    return Promise.reject(res);
  });

(function () {
  //加载网站信息
  const href = window.location.href
  let tld = "zlgx.com"
  let url = 'https://news.zlgx.com/api/v1/cms/site/info'

  if (/(localhost|test)/.test(href)) {
    tld = "test.zlgx.com"
    url = 'http://news.test.zlgx.com/api/v1/cms/site/info'
  }

  let params = {
    platformCode: 'common',
    siteUrl: tld,
    platformType: 'cms'
  };

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.text())
    .then(res => {
      let data = JSON.parse(res);
      if (data.code === 0) {
        localStorage.setItem('headerLogo', data.data.init.headerLogo);
        const changeFavicon = link => {
          let el = document.createElement('link');
          el.rel = 'icon';
          el.href = link;
          document.head.appendChild(el);
        };
        changeFavicon(data.data.logo); // 动态修改标签栏图标
        document.title = data.data.title;
      }
    })
    .catch(err => console.log(err));
})();

const browserHistory = createHistory();
class App extends Component {
  componentDidMount() {
    this.validationLogin();
  }
  validationLogin() {
    if (!validationLoginStatus() && !isLoginPage) {
      setTimeout(() => {
        toLogin(false);
      }, 0);
    }
  }

  render() {
    return (
      <Provider store={store}>
        <LocaleProvider locale={zh_CN}>
          <Router history={browserHistory}>
            <Switch>
              {getRoutes(basicRoutes, '')}
              <Route path="/login" component={Login} />
              <Route component={() => <Redirect from="/" to="/notice" />} />
            </Switch>
          </Router>
        </LocaleProvider>
      </Provider>
    );
  }
}

export default App;
