import React from 'react';
import { Form, Button, message, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'components/Select2';
import Upload from 'components/Upload2';
import CommonEdit from 'pages/common/Edit';
import RangeInput from 'components/RangeInput';
import reaction from 'reaction';

import style from 'pages/page.module.scss';
import { appPlatforms, rules } from 'config';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
}

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

@connect(({ user }) => ({ user }))
@Form.create()
@withRouter
class AppThemeAdd extends CommonEdit {
  mode = 'add';
  submitData(data) {
    reaction.appTheme.add(data)
      .then(err => {
        this.setState({
          submitting: false,
        });
        message.success('添加成功');
        this.props.history.replace('/app-theme');
      }).catch(err => {
        this.setState({
          submitting: false,
        });
      });
  }
  render() {
    const {
      form: {
        getFieldDecorator,
      },
    } = this.props;
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>app主题添加</span>
        </div>
        <div className={`${style.form} page-form`}>
          <Form.Item
            label="主题名称"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('themeName', {
                rules: [{
                  required: true,
                  message: '请输入主题名称',
                }],
              })(
                <Input placeholder="请输入主题名称" style={{ width: 195 }} />
              )
            }
          </Form.Item>
          <Form.Item
            label="平台"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('platform', {
                rules: [{
                  required: true,
                  message: '请选择平台',
                }],
              })(
                <Select
                  placeholder="请选择"
                  options={appPlatforms}
                  style={{ width: 195 }}
                />
              )
            }
          </Form.Item>
          <Form.Item
            label="版本范围"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('version', {
                rules: [{
                  validator: rules.versionsValidator(),
                }],
              })(
                <RangeInput
                  placeholders={[
                    '最低版本名',
                    '最高版本名'
                  ]}
                  options={appPlatforms}
                  style={{ width: 195 }}
                />
              )
            }
            <p className={style.tip}>版本名格式a.b.c，如：1.0.0</p>
          </Form.Item>
          {
            this.renderStartTime()
          }
          {
            this.renderEndTime(this.state.startTime)
          }
          <Form.Item
            label="启动图"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('themeImage', {
                rules: [{
                  required: true,
                  message: '请上传启动图',
                }],
              })(
                <Upload />
              )
            }
            <p className={style.tip}>请上传JPG、PNG格式的文件，不能超过10M.</p>
          </Form.Item>
          <Form.Item
            label="资源包"
            {...formItemLayout}
            // required
          >
            {
              getFieldDecorator('themeData', {
                // rules: [{
                //   required: true,
                //   message: '请上传资源包',
                // }],
              })(
                <Upload
                  listType="text"
                  accept=".zip,.rar"
                />
              )
            }
            <p className={style.tip}>请上传zip、rar格式的文件，不能超过10M.</p>
          </Form.Item>
          <Form.Item
            label=""
            {...formTailLayout}
            required
          >
            <Button type="primary" className={style.button} onClick={this.handlePublish} loading={this.state.submitting}>发布</Button>
            <Link className={`ant-btn ${style.button} ${style['button-back']}`} to="/app-theme">返回</Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default AppThemeAdd;
