import React from 'react';
import { Form, Input, Button, Modal, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PageHeader from 'modules/PageHeader';
import Select from 'components/Select2';
import FormItem from 'components/FormItem';
import Table from 'components/Table2';
import ImageViewer from 'components/ImageViewer';
import WrapAuth from 'components/WrapAuth';
import CommonList from '../common/List';
import { resolvePageStatusText, resolveTimeText } from 'utils';
import { bannerPages, filterStatusOptions, path } from 'config';
import reaction from 'reaction';

import style from '../page.module.scss';

const { Column } = Table;
const { FormItemGroup } = FormItem;
const { confirm } = Modal;
const bannerPath = path.cms.banner;

@Form.create()
@connect(({ banner, user }) => ({ banner, user }))
class Banner extends CommonList {
  loadData (params) {
    reaction.banner.fetchList(params, true)
      .then(err => {
        this.setState({
          loading: false,
        });
      });
  }
  clear () {
    reaction.banner.clear();
  }
  handleDelete (id) {
    confirm({
      title: '确定删除此轮播图吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        reaction.banner.delete(id, this.props.user.username).then(() => {
          message.success('删除成功');
          this.reloadData(!this.props.banner.list.length);
        });
      },
    });
  }
  render () {
    const { form: { getFieldDecorator }, banner: { list = [], pagination = {} } } = this.props;
    return (
      <div className="page-inner">
        <PageHeader title="轮播图列表">
          <WrapAuth path={bannerPath.add.path}>
            <Link to="/banner/add" className="page-btn">
              +&nbsp;添加轮播图
            </Link>
          </WrapAuth>
        </PageHeader>
        <div className={style.filter}>
          <FormItemGroup>
            <FormItem label="页面：" className="form-item-filter">
              {getFieldDecorator('position', {
                initialValue: null,
              })(
                <Select width={180} placeholder="请选择" className="select2" options={bannerPages} filterHidden={false} />,
              )}
            </FormItem>
            <FormItem label="轮播图标题：" style={{ marginRight: 40 }}>
              {getFieldDecorator('title', {
                initialValue: '',
              })(<Input style={{ width: 305 }} placeholder="请输入轮播图标题" />)}
            </FormItem>
            <FormItem label="状态：" style={{ marginRight: 47 }}>
              {getFieldDecorator('status', {
                initialValue: null,
              })(
                <Select
                  width={90}
                  placeholder="请选择"
                  className="select2"
                  // filterHidden={false}
                  options={filterStatusOptions}
                />,
              )}
            </FormItem>
            <Button type="primary" onClick={this.handleSearchData}>
              查询
            </Button>
          </FormItemGroup>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={list}
            loading={false}
            rowKey={(row, index) => index}
            pagination={this.getPageConfig(pagination)}
            borderd
          >
            <Column title="页面" key="positionName" dataIndex="positionName" align="center" width={99} />
            <Column title="标题" key="title" dataIndex="title" align="center" width={192} />
            <Column
              title="跳转协议"
              key="linkAddress"
              dataIndex="linkAddress"
              align="center"
              render={(val) => (
                val ? (
                  <a href={val} target="__blank">
                    {val}
                  </a>
                ) : '--'

              )}
              width={134}
            />
            <Column
              title="上架时间"
              key="startTime"
              dataIndex="startTime"
              align="center"
              width={108}
              render={(time) => time ? resolveTimeText(time) : '--'}
            />
            <Column
              title="下架时间"
              key="expireTime"
              dataIndex="expireTime"
              align="center"
              width={100}
              render={(time) => time ? resolveTimeText(time) : '--'}
            />
            <Column title="排序" key="sorted" dataIndex="sorted" align="center" width={60} />
            <Column
              title="预览"
              key="image"
              dataIndex="image"
              align="center"
              width={82}
              render={(src) => <ImageViewer src={src} name="" width={60} height={40} />}
            />
            <Column
              title="状态"
              key="status"
              dataIndex="status"
              align="center"
              width={60}
              render={(status) => resolvePageStatusText(status)}
            />
            <Column
              title="操作"
              key="handler"
              align="center"
              width={105}
              render={(row) => {
                return (
                  <div>
                    <WrapAuth path={bannerPath.update.path}>
                      <Link to={`/banner/edit/${row.id}`} className="link-handle">
                        编辑
                      </Link>
                    </WrapAuth>
                    <WrapAuth path={bannerPath.delete.path}>
                      <a href="javascript:;" className="link-handle" onClick={() => this.handleDelete(row.id)}>
                        删除
                      </a>
                    </WrapAuth>
                  </div>
                );
              }}
            />
          </Table>
        </Spin>
      </div>
    );
  }
}

export default Banner;
