import React from 'react';
import { Form, Button, message, Input, TreeSelect, InputNumber } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Select from 'components/Select2';
import CommonEdit from '../common/Edit';
// import Editor from 'components/Editor';
import reaction from 'reaction';
import { platformsForHelp, newPageStatusOptions } from 'config';
import { resolveTreeNodes } from 'utils';
import style from '../page.module.scss';

// const { Group: CheckboxGroup } = Checkbox;

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8,
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
};

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
};

@connect(({ user, category }) => ({ user, category }))
@Form.create()
@withRouter
class HelpAdd extends CommonEdit {
  mode = 'add';
  submitData (data) {
    reaction.help
      .add(data)
      .then((res) => {
        this.setState({
          submitting: false,
        });
        message.success('添加成功');
        this.props.history.replace('/help');
      })
      .catch((err) => {
        this.setState({
          submitting: false,
        });
      });
  }
  handlePlatformChange = (v) => {
    this.props.form.setFieldsValue({
      categoryIds: undefined,
    });
    this.loadCategories(v);
  }
  loadCategories(platform = null, categoryType = 'HELP') {
    reaction.category.fetchNodes({
      parentId: 0,
      showSub: true,
      platform,
      categoryType
    });
  }
  getTotalNodes (data) {
    return resolveTreeNodes(data);
  }
  render () {
    let { form: { getFieldDecorator } } = this.props;
    const { category: { nodes = [] } } = this.props;
    const { submitting } = this.state;
    const treeNodes = this.getTotalNodes(nodes); // eslint-disable-line
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>添加帮助</span>
        </div>
        <div className={`${style.form} page-form`}>
        <Form.Item label="标题" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: '请输入标题',
                },
              ],
            })(<Input placeholder="请输入标题" style={{ width: 220 }} maxLength={ 10 }/>)}
          </Form.Item>
          <Form.Item label="所属平台" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('platform', {
              rules: [
                {
                  required: true,
                  message: '请选择所属平台',
                },
              ],
            })(<Select options={platformsForHelp} style={{ width: 220 }}  onChange={this.handlePlatformChange}/>)}
          </Form.Item>
          <Form.Item label="所属栏目" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('categoryIds', {
              inititalValue: null,
              rules: [
                {
                  required: true,
                  message: '请选择栏目',
                },
              ],
            })(
              <TreeSelect disabled={!nodes.length} multiple style={{ width: 220 }} treeData={treeNodes} placeholder="请选择" />
            )}
          </Form.Item>
          <Form.Item label="状态" {...formItemLayout} required>
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: '请选择状态',
                },
              ],
            })(<Select style={{ width: 220 }} placeholder="请选择" options={newPageStatusOptions} />)}
          </Form.Item>
          <Form.Item label="排序" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('sorted', {
              rules: [
                {
                  required: true,
                  message: '请输入排序号',
                },
              ],
            })(<InputNumber type="number" max={999} min={0} placeholder="请输入排序号" style={{ width: 220 }} maxLength={ 20 }/>)}
          </Form.Item>
          {this.renderEditor('', '帮助内容')}
          <Form.Item label="" {...formTailLayout} required>
            <Button
              type="primary"
              className={style['button']}
              onClick={() => this.handlePublish(this.props.form)}
              loading={submitting}
            >
              提交
            </Button>
            <Link className={`${style.button} ${style['button-back']}`} to="/notice">
              返回
            </Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default HelpAdd;
