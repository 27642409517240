import React, { Component } from 'react';
import { Table } from 'antd';
import style from './table2.module.scss';

export default class Table2 extends Component {
  render() {
    const MyCell = ({ children, ...rest }) => {
      const content = children[2];
      return <td {...rest}>{(content === '' || content === null) ? '--' : children }</td>;
    }
    return (
      <div
        className={style.table2}
      >
        <Table
          {...this.props}
          components={{
            body: {
              cell: MyCell
            }
          }}
        >
          {
            this.props.children
          }
        </Table>
      </div>
    );
  }
}

Table2.Column = Table.Column;
Table2.ColumnGroup = Table.ColumnGroup;
