import env from 'config/env';
import reaction from 'reaction';
import createStore from 'utils/reduxCreateStore';
// import localStorage from 'utils/localStorage';
// import { getCookieValue } from 'utils/cookie';

// let root = document.getElementById('root');
let store;
// 初始化配置

if (env.DEBUG && window.__REDUX_DEVTOOLS_EXTENSION__) {
  store = createStore(
    reaction,
    // window.preloadedState || localStorage.getJSONItem('store'),
    // window.__REDUX_DEVTOOLS_EXTENSION__(),
  );
} else {
  store = createStore(reaction);
}
export default store;
