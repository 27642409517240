import React from 'react';
import { Form, Modal, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PageHeader from 'modules/PageHeader';
// import Select from 'components/Select2';
// import FormItem from 'components/FormItem';
import Table from 'components/Table2';
import ImageViewer from 'components/ImageViewer';
import CommonList from 'pages/common/List';
import WrapAuth from 'components/WrapAuth';
import { resolveTimeText, resolvePlatformNames } from 'utils';
import { appPlatforms, path } from 'config';
import reaction from 'reaction';

const { Column } = Table;
// const { FormItemGroup } = FormItem;
const { confirm } = Modal;

const appTheme = path.cms.theme.app;

@Form.create()
@connect(({ appTheme, user }) => ({ appTheme, user }))
class AppTheme extends CommonList {
  loadData (params) {
    reaction.appTheme.fetchList(params, true)
      .then(err => {
        this.setState({
          loading: false,
        });
      });
  }
  clear () {
    reaction.appTheme.clear();
  }
  handleDelete (id) {
    confirm({
      title: '确定删除此主题吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        reaction.appTheme.delete(id, this.props.user.username).then(() => {
          message.success('删除成功');
          this.reloadData(!this.props.appTheme.list.length);
        });
      },
    });
  }
  render () {
    const { appTheme: { list = [], pagination = {} } } = this.props;
    return (
      <div className="page-inner">
        <PageHeader title="app主题列表">
          <WrapAuth path={appTheme.add.path}>
            <Link to="/app-theme/add" className="page-btn">
              +&nbsp;添加主题
            </Link>
          </WrapAuth>
        </PageHeader>
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={list}
            loading={false}
            rowKey={(row, index) => index}
            pagination={this.getPageConfig(pagination)}
            borderd
            style={{ marginTop: 20 }}
          >
            <Column title="序号" key="index" align="center" width={40} render={(val, array, index) => index + 1} />
            <Column title="主题名称" key="themeName" dataIndex="themeName" align="center" width={100} />
            <Column title="平台" key="platform" dataIndex="platform" align="center" width={60} render={value => resolvePlatformNames({ value, options: appPlatforms })} />
            <Column
              title="版本范围"
              key="version"
              dataIndex="version"
              align="center"
              // render={(record) => `${record.minVersion} - ${record.maxVersion}`}
              render={([ min = '--', max = '--' ]) => `${min} - ${max}`}
              width={60}
            />
            <Column
              title="上架时间"
              key="startTime"
              dataIndex="startTime"
              align="center"
              width={108}
              render={(time) => resolveTimeText(time)}
            />
            <Column
              title="下架时间"
              key="expireTime"
              dataIndex="expireTime"
              align="center"
              width={100}
              render={(time) => resolveTimeText(time)}
            />
            <Column
              title="操作"
              key="handler"
              align="center"
              width={160}
              render={(row) => {
                const hasThemeData = !!row.themeData;
                return (
                  <div>
                    <WrapAuth path={appTheme.update.path}>
                      <Link to={`/app-theme/edit/${row.id}`} className="link-handle">
                        编辑
                      </Link>
                    </WrapAuth>
                    <WrapAuth path={appTheme.delete.path}>
                      <a href="javascript:;" className="link-handle" onClick={() => this.handleDelete(row.id)}>
                        删除
                      </a>
                    </WrapAuth>
                    <ImageViewer src={row.themeImage}>
                      <a href="javascript:;" className="link-handle">查看启动图</a>
                    </ImageViewer>
                    <a href={row.themeData} className={`link-handle ${!hasThemeData ? 'link-handle-disabled' : ''}`} download={hasThemeData}>
                      下载资源包
                    </a>
                  </div>
                );
              }}
            />
          </Table>
        </Spin>
      </div>
    );
  }
}

export default AppTheme;
