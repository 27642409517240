import React, { Component } from 'react';
import { Input } from 'antd';

const { Group: InputGroup } = Input;

export default class RangeInput extends Component {
  state = {
    minValue: '',
    maxValue: '',
  }
  hasInited = false;
  UNSAFE_componentWillReceiveProps(nextProps = {}) {
    const { value = [] } = nextProps;
    if (value.length && value[0] && value[1] && !this.hasInited) {
      this.setValue(value);
      this.hasInited = true;
    }
  }
  triggerValue([minValue, maxValue]) {
    const { onChange = () => {} } = this.props;
    onChange([minValue, maxValue]);
  }
  setValue([minValue = '', maxValue = '']) {
    this.setState({
      minValue,
      maxValue,
    }, () => {
      this.triggerValue([minValue, maxValue]);
    });
  }
  saveValues(values = []) {
    this.setValue(values);
  }
  render() {
    const {
      placeholders = [],
    } = this.props;
    const {
      maxValue,
      minValue,
    } = this.state;
    const [ minPlacholder = '最小值', maxPlaceholder = '最大值' ] = placeholders;
    return (
      <InputGroup compact>
        <Input value={minValue} placeholder={minPlacholder} style={{ width: 100, textAlign: 'center' }} onChange={(e) => this.saveValues([e.target.value, maxValue])} />
        <Input
          style={{
            width: 30, borderLeft: 0, pointerEvents: 'none', backgroundColor: '#fff',
          }}
          placeholder="~"
          disabled
        />
        <Input value={maxValue} placeholder={maxPlaceholder} style={{ width: 100, textAlign: 'center', borderLeft: 0 }} onChange={(e) => this.saveValues([minValue, e.target.value])} />
      </InputGroup>
    );
  }
}
