import React from 'react';
import { Form } from 'antd';
import moment from 'moment';
import uamsdk from 'zlgx-uamsdk';
import memoOne from 'memoize-one';
import { filterStatusOptions, categoryTopMenus, commonStatus, datePickerConfig, platformsForHelp } from 'config';
import { uam_server_cookie_name } from 'config/env';
import { sessionStorage } from './localStorage';
import cookies from './cookie';

export const getToken = () => {
  return cookies.getCookie(uam_server_cookie_name) || uamsdk.getToken();
}

const removeCookie = (key, domain) => {
  cookies.setCookie(key, {
    value: '',
    days: -1,
    domain: domain ? `.${domain}` : '',
  });
}

export const setToken = (token = '') => {
  const host = window.location.host;
  const [, domain] = host.match(/([\w-]+\.[\w-]+)$/) || [];
  if (token === '') {
    removeCookie('JSESSIONID', domain);
    removeCookie('uam_biz_token', host);
    removeCookie('SESSION_STATE', host);
    removeCookie('uamsdk_state', host);
  }
  cookies.setCookie(uam_server_cookie_name, {
    days: !token ? -1 : 1,
    // secure: false,
    domain: domain ? `.${domain}` : '',
    value: token,
  });
}

export const validationLoginStatus = () => {
  return !!getToken();
}

export const resolveNameByOptions = (value, options) => {
  const current = options.find(el => el.value === value);
  return (current && current.value) ? current.label : value;
}

export const resolvePageStatusText = (status, options = filterStatusOptions) => {
  return resolveNameByOptions(status, options);
}

export const resolveIsOnlineStatus = ({ startTime, expireTime }) => {
  const currentDate = new Date(); // 当前时间
  const startDate = new Date(startTime); // 开始时间
  const expireDate = new Date(expireTime); // 结束时间
  if (currentDate < startDate) {
    return '预告';
  } else if (currentDate > expireDate) {
    return '下架';
  }
  return '上架';
}

export const resolveOnineStatus = ({ startTime, expireTime, status, options }) => {

  if (status === commonStatus.show.value) {
    return resolveIsOnlineStatus({ startTime, expireTime });
  }
  return resolvePageStatusText(status, options);
}

export const resolveTimeText = (time, isBreak = true) => {
  if (!time) {
    return '';
  }
  const timeText = moment(time).format(datePickerConfig.format);
  const splits = timeText.split(' ');
  if (isBreak) {
    return (
      <React.Fragment>
        {splits[0]}<br />{splits[1]}
      </React.Fragment>
    )
  }
  return timeText;
}

export const resolveTimestampToValue = (time) => {
  return time ? moment(time) : undefined;
}

export const resolvePositionsName = (positions = '') => {
  if (!positions) {
    return undefined;
  }
  if (Array.isArray(positions)) {
    return positions;
  } else {
    return positions.split(',');
  }
}

export const resolvePlatformNames = ({ options = platformsForHelp, value }) => {
  return (options.find(el => el.value === value) || {}).label || '';
}

// 禁用当前日期之前的选择
export const disabledRangeDate = (endDate) => {
  // 这里-1天，是因为可选日期需要包含今天在内
  return endDate && endDate < moment().subtract(1, 'day').endOf('day');
}

export function disabledEndTime (e, s){
  const nowDate = new Date();
  const sTime = new Date(s);
  if (s && e) {
  // 如果结束时间小于当前时间的话，就小于当前时间都是不可选择的...
    return sTime < nowDate ? e.valueOf() <= nowDate : e.valueOf() <= sTime;
  }
  // return s && e && e.valueOf() <= new Date(s).getTime();
}

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export const disabledRangeTime = () => {
  const current = new Date();
  return {
    disabledHours: () => range(0, 24).slice(0, current.getHours()),
    disabledMinutes: () => range(0, 60).slice(0, current.getMinutes()),
    disabledSeconds: () => range(0, 60).slice(0, current.getSeconds()),
  };
}

export const resolveTreeNodes = (data = [], isFilter = false) => {
  return data.filter(el => isFilter ? el.status === commonStatus.show.value : true).map(node => {
    if (node.subs && node.subs.length) {
      return {
        title: node.name,
        value: node.id,
        key: node.key,
        children: resolveTreeNodes(node.subs, isFilter)
      }
    } else {
      return {
        title: node.name,
        value: node.id,
        key: node.key,
      }
    }
  });
}

export const getTotalNodes = (data = [], isAll = false) => {
  if (isAll) {
    return [
      {
        value: '',
        key: 'all',
        title: '全部',
      },
      ...resolveTreeNodes(data),
    ];
  }
  return [
    {
      ...categoryTopMenus,
      children: resolveTreeNodes(data)
    }
  ];
}

export const FormItem = ({ label, children = null, ...props }) => {
  return (
    <Form.Item
      label={label}
      {
        ...props
      }
    >
      {
        children
      }
    </Form.Item>
  )
}

export const setDomAttrs = (dom, attr = {}) => {
  Object.keys(attr).forEach((prop) => {
    dom.setAttribute(prop, attr[prop]);
  });
  return dom;
};

export const downloadFileByForm = ({
  action,
  data = {},
  method = 'get'
}) => {
  const body = document.body;
  const form = setDomAttrs(document.createElement('form'), {
    target: '_blank',
    enctype: 'multipart/form-data',
    method,
    action,
  });

  if (getToken()) {
    data.token = getToken();
  }

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (!value) return ;
    let child = setDomAttrs(document.createElement('input'), {
      type: 'hidden',
      name: key,
      value,
    });
    form.appendChild(child);
    child = null;
  });

  body.appendChild(form);
  form.submit();
  return body.removeChild(form);
};

export const transformDateValueToZero = (dateValue = '', isMax = false) => {
  if (!dateValue) {
    return '';
  }
  if (isMax) {
    return +(dateValue.hours(23).minutes(59).seconds(59).milliseconds(999))
  }
  return +(dateValue.hours(0).minutes(0).seconds(0).milliseconds(0));
}

// export const transformPermissionData = (data = {}) => {
//   let cache = {};
//   Object.keys(data).forEach(key => {
//     const keys = key.split('.');
//     const topLevel = keys.shift();
//     const initData = cache[topLevel] = cache[topLevel] || {
//       name: data[key],
//       state: true,
//       children: {},
//     };

//     let path = topLevel;

//     keys.reduce((initData, key) => {
//       const children = initData.children;
//       if (!children[key]) {
//         children[key] = {
//           children: {},
//         };
//       }
//       path += '.' + key;
//       children[key].path = path;
//       children[key].state = true;
//       return children[key];
//     }, initData);
//   });
//   return cache;
// }

export const transformRoleDataForArray = (roleInfo = []) => {  
  const cache = {};
  if (!roleInfo.length) {
    return cache;
  }
  for (const item of roleInfo) {
    if (item.authCode.indexOf('cms') !== -1) {
      cache[item.authCode] = item.fullName;
    }
  }
  return cache;
}

const authKey = 'auth';

export const saveAuth = (data = {}) => {
  // localStorage.setJSONItem(authKey, transformPermissionData(data));
  const authData = transformRoleDataForArray(data);
  sessionStorage.setJSONItem(authKey, authData);
  return authData;
}

// Object.keys(versionInfo).forEach(key => {
//   versionInfo[key] = versionInfo[key].filter(el => param.nonTrading && nonTradingDays.indexOf(el['v_date']) !== -1)
// });

export const getAuth = () => {
  return sessionStorage.getJSONItem(authKey);
}

export const checkAuth = memoOne((path = 'cms.common', auth = getAuth()) => {
  const auths = auth || {};
  return !!auths[path];
});
