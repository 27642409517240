import React from 'react';
import { Form, Button, InputNumber, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'components/Select2';
import Upload from 'components/Upload2';
import WebsiteInput from 'components/Website';
import CommonEdit from '../common/Edit';
import ColorPicker from 'components/ColorPicker';
import { bannerPages, newPageStatusOptions, rules } from 'config';
import reaction from 'reaction';

import style from '../page.module.scss';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
}

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

@connect(({ user }) => ({ user }))
@Form.create()
@withRouter
class BannerAdd extends CommonEdit {
  mode = 'add';
  submitData(data) {
    // data.image = data.image.join(',');
    reaction.banner.add(data)
      .then(err => {
        this.setState({
          submitting: false,
        });
        message.success('添加成功');
        this.props.history.replace('/banner');
      }).catch(err => {
        this.setState({
          submitting: false,
        });
      });
  }
  // handleColorChange = (e) => {
  //   // console.log(e.target.value);
  //   return '#ff0000'
  // }
  render() {
    const {
      form: {
        getFieldDecorator,
      },
    } = this.props;
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>轮播图添加</span>
          {/* <span className={style.crm}>添加轮播图<span className={style.split}>/</span><Link to="/adv">轮播图列表</Link></span> */}
        </div>
        <div className={`${style.form} page-form`}>
          <Form.Item
            label="页面"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('positions', {
                rules: [{
                  required: true,
                  message: '请选择页面',
                }],
              })(
                <Select
                  options={bannerPages}
                  mode="multiple"
                  style={{ width: 520 }}
                />
              )
            }
          </Form.Item>
          <Form.Item
            label="状态"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('status', {
                rules: [{
                  required: true,
                  message: '请选择状态',
                }],
              })(
                <Select
                  placeholder="请选择"
                  options={newPageStatusOptions}
                  style={{ width: 195 }}
                />
              )
            }
          </Form.Item>
          {
            this.renderTitle(undefined, '轮播图标题')
          }
          <Form.Item
            label="跳转协议"
            {...formItemLayout}
          >
            {
              getFieldDecorator('linkAddress', {
                rules: [{
                  pattern: rules.website,
                  message: '链接地址格式不正确',
                }],
              })(
                <WebsiteInput style={{ width: 520 }} placeholder="请输入跳转链接" />
              )
            }
          </Form.Item>
          <Form.Item
            label="排序"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('sorted', {
                initialValue: '0',
                rules: [{
                  required: true,
                  message: '请输入排序',
                }],
              })(
                <InputNumber min={0} style={{ width: 195 }} placeholder="排序" />
              )
            }
          </Form.Item>
          {
            this.renderStartTime()
          }
          {
            this.renderEndTime(this.state.startTime)
          }
          <Form.Item
            label="背景颜色"
            {...formItemLayout}
            // required
          >
            {
              getFieldDecorator('backColor', {
                // rules: [{
                //   required: true,
                //   message: '请选择背景颜色',
                // }],
              })(
                <ColorPicker />
              )
            }
          </Form.Item>
          <Form.Item
            label="图片"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('image', {
                rules: [{
                  required: true,
                  message: '请上传轮播图图片',
                }],
              })(
                <Upload
                  max={1}
                />
              )
            }
            <p className={style.tip}>请上传JPG、PNG格式.</p>
          </Form.Item>
          <Form.Item
            label=""
            {...formTailLayout}
            required
          >
            <Button type="primary" className={style.button} onClick={this.handlePublish} loading={this.state.submitting}>发布</Button>
            <Link className={`ant-btn ${style.button} ${style['button-back']}`} to="/banner">返回</Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default BannerAdd;
