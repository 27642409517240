import OSS from 'ali-oss';
import api from 'api';

// export function multipartUpload (fileInfo = {}) {
//   return function(...args) {
//     return new Promise((resolve, reject) => {
//       api.common.getOSSConfig(fileInfo)
//         .then(res => {
//           const data = res || {};
//           new OSS({
//             region: 'oss-cn-beijing',
//             //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
//             accessKeyId: data.temporaryAccessIeyId,
//             accessKeySecret: data.temporaryAccessKeySecret,
//             stsToken: data.temporarySecurityToken,
//             bucket: data.bucketName
//           })
//           .multipartUpload(...args)
//           .then(resolve)
//           .catch(reject);
//         }).catch(reject);
//     });
//   }
// }

export function multipartUpload (fileInfo = {}) {
  return new Promise((resolve, reject) => {
    api.common.getOSSConfig(fileInfo)
      .then(res => {
        const data = res || {};
        resolve({
          client: new OSS({
            region: 'oss-cn-beijing',
            //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
            accessKeyId: data.temporaryAccessIeyId,
            accessKeySecret: data.temporaryAccessKeySecret,
            stsToken: data.temporarySecurityToken,
            bucket: data.bucketName
          }),
          fileKey: data.fileKey,
        });
      }).catch(reject);
  });
}
