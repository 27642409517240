import React from 'react';
import Header from 'modules/Header';
import SideMenu from 'modules/SideMenu';

import style from './basic.module.scss';

function BasicLayout({ children }) {
  return (
    <React.Fragment>
      <div className={style.header}>
        <Header></Header>
      </div>
      <div className={style.main}>
        <div className={style.content}>
          <div className={style.side}>
            <SideMenu></SideMenu>
          </div>
          <div className={style.inner}>
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default BasicLayout;
