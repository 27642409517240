import React from 'react';
import moment from 'moment';
import { resolveOnineStatus, resolveNameByOptions } from 'utils';

const HOME_PAGES = {
  cenpur: {
    label: '集采首页',
    value: 'CENPUR_HOME'
  },
  news: {
    label: '资讯首页',
    value: 'NEWS_HOME'
  },
  trade: {
    label: '交易首页',
    value: 'TRADE_HOME',
  },
  logistics: {
    label: '物流首页',
    value: 'LOGISTICS_HOME'
  },
  wms: {
    label: '仓储首页',
    value: 'WMS_HOME'
  },
  tradeApp: {
    label: '交易APP首页',
    value: 'TRADE_APP_HOME'
  },
  logApp:{
    label: '物流APP首页',
    value: 'LOGISTICS_APP_HOME'
  },
  oaHome: {
    label: '刚信办公首页',
    value: 'OA_HOME',
  },
  oaAppHome: {
    label: '钢信App首页',
    value: 'GANGXIN_APP_HOME',
  },
  home: {
    label: '官网首页',
    value: 'OFFICIAL_HOME',
  },
  finace: {
    label: '金融首页',
    value: 'FIN_HOME',
  },
  bidHome: {
    label: '招采首页',
    value: 'BID_HOME',
  },
  bidAppHome: {
    label: '招采APP首页',
    value: 'BID_APP_HOME',
  },
  all: {
    label: '全平台首页',
    value: 'ALL_HOME',
  },
};

const NEWS_PAGES = {
  news: {
    label: '资讯站点',
    value: 'STATION_NEWS'
  },
  trade: {
    label: '交易资讯站点',
    value: 'TRADE_NEWS'
  },
  logistics: {
    label: '物流资讯站点',
    value: 'LOGISTICS_NEWS'
  },
  cenpur: {
    label: '集采资讯站点',
    value: 'CENPUR_NEWS'
  },
  wms: {
    label: '仓储资讯站点',
    value: 'WMS_NEWS'
  }
};

const COMMON_OPTIONS = {
  all: {
    label: '全部',
    value: null,
    isHidden: true
  }
};

const PLATFORMS = {
  allPlatform: {
    label: '全平台',
    value: 'ALL'
  },
  cenpur: {
    label: '集采平台',
    value: 'CENPUR'
  },
  logistics: {
    label: '物流平台',
    value: 'LOGISTICS'
  },
  trade: {
    label: '交易平台',
    value: 'TRADE'
  },
  logisticsForDriver: {
    label: '物流司机端',
    value: 'LOGISTICS_DRIVER_APP'
  },
  wms: {
    label: '仓储平台',
    value: 'WMS',
  },
  fin: {
    label: '金融平台',
    value: 'FIN',
  },
  oaApp: {
    label: '钢信app',
    value: 'OA_APP',
  },
  oaPc: {
    label: '钢信pc端',
    value: 'OA_PC',
  },
  oaWeb: {
    label: '钢信管理后台',
    value: 'OA_ADMIN',
  },
  bid: {
    label: '招采平台',
    value: 'BID',
  },
};

export const commonStatus = {
  cancel: {
    label: '已下架',
    value: 'CANCEL'
    // isHidden: true,
  },
  show: {
    label: '显示',
    value: 'SHOW'
  },
  hidden: {
    label: '隐藏',
    value: 'HIDDEN'
  },
  all: {
    label: '全部',
    value: null
  }
};

export const bannerPages = [
  COMMON_OPTIONS.all,
  HOME_PAGES.cenpur,
  HOME_PAGES.trade,
  HOME_PAGES.logistics,
  NEWS_PAGES.news,
  HOME_PAGES.tradeApp,
  HOME_PAGES.logApp,
  HOME_PAGES.oaAppHome,
  HOME_PAGES.bidHome,
  HOME_PAGES.bidAppHome,
];

export const noticePages = [
  HOME_PAGES.all,
  HOME_PAGES.trade,
  HOME_PAGES.logistics,
  HOME_PAGES.cenpur
];

export const pageOptions = [
  ...noticePages,
  HOME_PAGES.wms,
  NEWS_PAGES.trade,
  NEWS_PAGES.logistics,
  NEWS_PAGES.cenpur,
  NEWS_PAGES.wms
];

export const platforms = [
  COMMON_OPTIONS.all,
  PLATFORMS.allPlatform,
  PLATFORMS.logistics,
  PLATFORMS.cenpur,
  PLATFORMS.trade,
  PLATFORMS.fin,
  PLATFORMS.bid,
];

// 特别提供给帮助中心使用的平台，多包含了三个平台(OA_APP, OA_PC, OA_ADMIN)
export const platformsForHelp = [
  COMMON_OPTIONS.all,
  PLATFORMS.allPlatform,
  PLATFORMS.logistics,
  PLATFORMS.cenpur,
  PLATFORMS.trade,
  PLATFORMS.fin,
  PLATFORMS.oaApp,
  PLATFORMS.oaPc,
  PLATFORMS.bid,
  // PLATFORMS.oaWeb,
];



export const filtionPlatforms = [
  COMMON_OPTIONS.all,
  PLATFORMS.allPlatform,
  PLATFORMS.logistics,
  PLATFORMS.cenpur,
  PLATFORMS.trade,
  PLATFORMS.fin
];

export const categoryTypes = [
  {label: '全部', value: null},
  {label: '资讯', value: 'NEWS'},
  {label: '帮助中心', value: 'HELP'}
]

export const categoryFilterOptions = [
  commonStatus.all,
  commonStatus.show,
  commonStatus.hidden
];

export const helpStatusFilterOptions = [
  commonStatus.show,
  commonStatus.hidden
];

export const helpFilterOptions = [
  commonStatus.all,
  commonStatus.show,
  commonStatus.hidden
];

export const appPlatforms = [PLATFORMS.logisticsForDriver, PLATFORMS.trade];

export const webThemePages = [
  HOME_PAGES.cenpur,
  // LOGIN_PAGES.cenpur,
  // HOME_PAGES.news,
  HOME_PAGES.trade,
  HOME_PAGES.logistics,
  HOME_PAGES.finace,
  HOME_PAGES.oaHome,
  HOME_PAGES.home,
  HOME_PAGES.wms,
];

export const filterStatusOptions = [
  commonStatus.all,
  commonStatus.show,
  commonStatus.hidden,
  commonStatus.cancel
];

export const noticeTypeOptions = [
  {label: '全部', value: null},
  {label: '弹窗', value: 'POPUPS'},
  {label: '非弹窗', value: 'NON_POPUPS'}
];

export const editPageStatusOptions = [
  commonStatus.show,
  commonStatus.hidden,
  commonStatus.cancel
];

export const newPageStatusOptions = [commonStatus.show, commonStatus.hidden];

export const categoryTopMenus = {
  title: '顶级栏目',
  value: '0',
  key: '0',
  children: []
};

export const datePickerConfig = {
  format: 'YYYY-MM-DD HH:mm:ss',
  formateDate: 'YYYY-MM-DD'
};

export const commonLabelConfig = {
  title: '标题',
  status: {
    label: '状态',
    format(_v, data) {
      return resolveOnineStatus(data);
    }
  },
  startTime: {
    label: '上架时间',
    format(v) {
      return moment(v).format(datePickerConfig.format);
    }
  },
  expireTime: {
    label: '下架时间',
    format(v) {
      return moment(v).format(datePickerConfig.format);
    }
  },
  position: {
    label: '页面',
    format(v) {
      return resolveNameByOptions(v, pageOptions);
    }
  },
  sorted: '排序',
  content: {
    label: '内容',
    format(v) {
      return (
        <div
          className="detail-content"
          dangerouslySetInnerHTML={{
            __html: v
          }}
        />
      );
    }
  }
};

export const noticeLabels = { ...commonLabelConfig };

export const rules = {
  website: /https?:\/\/([\w-]+\.){1,}\w+.?/,
  versionsValidator: (name = '版本名') => {
    return (_rule, value = [], callback) => {
      const [min = '', max = ''] = value;
      if (min === '' && max === '') {
        return callback(new Error('请输入版本范围'));
      }
      if (min === '') {
        return callback(new Error(`请输入最低${name}`));
      }
      if (max === '') {
        return callback(new Error(`请输入最高${name}`));
      }
      callback();
    };
  }
};

export const purchaseProductType = [
  {
    label: '钢坯',
    value: '钢坯'
  },
  {
    label: '焦炭',
    value: '焦炭'
  },
  {
    label: '生铁',
    value: '生铁'
  },
  {
    label: '煤炭',
    value: '煤炭'
  }
];
export const path = {
  cms: {
    path: 'cms',
    banner: {
      path: 'cms.banner',
      add: {
        path: 'cms.banner.add'
      },
      delete: {
        path: 'cms.banner.delete'
      },
      list: {
        path: 'cms.banner.list'
      },
      update: {
        path: 'cms.banner.update'
      }
    },
    category: {
      path: 'cms.category',
      add: {
        path: 'cms.category.add'
      },
      delete: {
        path: 'cms.category.delete'
      },
      list: {
        path: 'cms.category.list'
      },
      update: {
        path: 'cms.category.update'
      },
      export: {
        path: 'cms.category.export'
      }
    },
    help: {
      path: 'cms.help',
      add: {
        path: 'cms.help.add'
      },
      delete: {
        path: 'cms.help.delete'
      },
      list: {
        path: 'cms.help.list'
      },
      update: {
        path: 'cms.help.update'
      },
      export: {
        path: 'cms.help.export'
      }
    },
    news: {
      path: 'cms.news',
      add: {
        path: 'cms.news.add'
      },
      delete: {
        path: 'cms.news.delete'
      },
      list: {
        path: 'cms.news.list'
      },
      update: {
        path: 'cms.news.update'
      },
      export: {
        path: 'cms.news.export'
      }
    },
    wx_news: {
      path: 'cms.wx_news',
      add: {
        path: 'cms.wx_news.add'
      },
      delete: {
        path: 'cms.wx_news.delete'
      },
      list: {
        path: 'cms.wx_news.list'
      },
      update: {
        path: 'cms.wx_news.update'
      },
      export: {
        path: 'cms.wx_news.export'
      }
    },
    notice: {
      path: 'cms.notice',
      add: {
        path: 'cms.notice.add'
      },
      delete: {
        path: 'cms.notice.delete'
      },
      list: {
        path: 'cms.notice.list'
      },
      update: {
        path: 'cms.notice.update'
      },
      expired: {
        path: 'cms.notice.expired'
      }
    },
    theme: {
      path: 'cms.theme',
      app: {
        add: {
          path: 'cms.theme.appAdd'
        },
        delete: {
          path: 'cms.theme.appDelete'
        },
        list: {
          path: 'cms.theme.appList'
        },
        update: {
          path: 'cms.theme.appUpdate'
        }
      },
      web: {
        add: {
          path: 'cms.theme.webAdd'
        },
        delete: {
          path: 'cms.theme.webDelete'
        },
        list: {
          path: 'cms.theme.webList'
        },
        update: {
          path: 'cms.theme.webUpdate'
        }
      }
    },
    steelData: {
      path: 'cms.steelData',
      add: {
        path: 'cms.steelData.add'
      },
      list: {
        path: 'cms.steelData.list'
      },
      delete: {
        path: 'cms.steelData.delete'
      },
      update: {
        path: 'cms.steelData.update'
      }
    },
    iconBrand: {
      path: 'cms.iconBrand',
      add: {
        path: 'cms.iconBrand.add'
      },
      delete: {
        path: 'cms.iconBrand.delete'
      },
      update: {
        path: 'cms.iconBrand.update'
      },
      list: {
        path: 'cms.iconBrand.list'
      }
    },
    priceTrendFirst: {
      path: 'cms.priceTrendFirst',
      add: {
        path: 'cms.priceTrendFirst.add'
      },
      delete: {
        path: 'cms.priceTrendFirst.delete'
      },
      list: {
        path: 'cms.priceTrendFirst.list'
      },
      update: {
        path: 'cms.priceTrendFirst.update'
      },
      download: {
        path: 'cms.priceTrendFirst.download'
      },
      import: {
        path: 'cms.priceTrendFirst.import'
      }
    },
    priceTrendSecond: {
      path: 'cms.priceTrendSecond',
      add: {
        path: 'cms.priceTrendSecond.add'
      },
      delete: {
        path: 'cms.priceTrendSecond.delete'
      },
      list: {
        path: 'cms.priceTrendSecond.list'
      },
      update: {
        path: 'cms.priceTrendSecond.update'
      },
      download: {
        path: 'cms.priceTrendSecond.download'
      },
      import: {
        path: 'cms.priceTrendSecond.import'
      }
    },
    priceTrendThird: {
      path: 'cms.priceTrendThird',
      add: {
        path: 'cms.priceTrendThird.add'
      },
      delete: {
        path: 'cms.priceTrendThird.delete'
      },
      list: {
        path: 'cms.priceTrendThird.list'
      },
      update: {
        path: 'cms.priceTrendThird.update'
      },
      download: {
        path: 'cms.priceTrendThird.download'
      },
      import: {
        path: 'cms.priceTrendThird.import'
      }
    },
    otherPriceTrend: {
      path: 'cms.otherPriceTrend',
      add: {
        path: 'cms.otherPriceTrend.add'
      },
      delete: {
        path: 'cms.otherPriceTrend.delete'
      },
      list: {
        path: 'cms.otherPriceTrend.list'
      },
      update: {
        path: 'cms.otherPriceTrend.update'
      },
      download: {
        path: 'cms.otherPriceTrend.download'
      },
      import: {
        path: 'cms.otherPriceTrend.import'
      }
    },
    preControl: {
      path: 'cms.preControl',
      daily: {
        path: 'cms.preControl.daily',
        list: {
          path: 'cms.preControl.daily.list',
        },
        add: {
          path: 'cms.preControl.daily.add',
        },
        delete: {
          path: 'cms.preControl.daily.delete',
        },
        update: {
          path: 'cms.preControl.daily.update',
        },
        download: {
          path: 'cms.preControl.daily.download',
        },
        import: {
          path: 'cms.preControl.daily.import'
        }
      },
      week: {
        path: 'cms.preControl.week',
        list: {
          path: 'cms.preControl.week.list',
        },
        add: {
          path: 'cms.preControl.week.add',
        },
        delete: {
          path: 'cms.preControl.week.delete',
        },
        update: {
          path: 'cms.preControl.week.update',
        },
        download: {
          path: 'cms.preControl.week.download',
        },
        import: {
          path: 'cms.preControl.week.import'
        }
      },
    },
  }
};
