import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import styles from './alert.module.scss';


class Alert extends Component {
  state = {
    visible: false,
  }
  handleOnOk = () => {
    this.close();
  }
  show = () => {
    this.setState({
      visible: true,
    });
  }
  close = () => {
    this.setState({
      visible: false,
    })
  }
  render() {
    return (
      <Modal
        {
          ...this.props
        }
        visible={this.state.visible}
        centered
        onCancel={this.handleOnOk}
        footer={(
          <div className={styles.btns}>
            <Button onClick={this.handleOnOk} size="large">确定</Button>
          </div>
        )}
      >
      </Modal>
    );
  }
}

export default Alert;
