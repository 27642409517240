import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toLogin, getUserInfo } from 'utils/user';
import { validationLoginStatus,  } from 'utils';

import style from './loginInfo.module.scss';

@withRouter
@connect(({ user }) => ({ user }))
class LoginInfo extends Component {
  state = {
    user: null,
    isLogin: validationLoginStatus(),
  }
  componentDidMount() {
    if (this.state.isLogin) {
      getUserInfo();
    }
  }
  handleLogout = () => {
    toLogin(true);
  }
  render() {
    const { user, className } = this.props;
    return (
      <div className={`${style['login-info']} ${className}`}>
        <div className={style.inner}>
          {
            this.state.isLogin ? (
              <span>你好，{user.realName}&nbsp;&nbsp;|&nbsp;&nbsp;<a href="javascript:;" onClick={this.handleLogout}>退出</a></span>
            ) : (
                <Link to="/login">立即登录</Link>
              )
          }
        </div>
      </div>
    );
  }
}

export default LoginInfo;
