import React from 'react';
import { Form, Input, Button, DatePicker, Modal, message, Spin } from 'antd';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeader from 'modules/PageHeader';
import FormItem from 'components/FormItem';
import Table from 'components/Table2';
import WrapAuth from 'components/WrapAuth';
import CommonList from 'pages/common/List';
import ImportData from 'modules/ImportData';
import { datePickerConfig, path } from 'config';
import preControl from 'api/preControl';
import reaction from 'reaction';
import moment from 'moment';
import style from 'pages/page.module.scss';

/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */
const { Column } = Table;
const { FormItemGroup } = FormItem;
const { confirm } = Modal;

const { RangePicker } = DatePicker;
const dailyControl = path.cms.preControl.daily;

const DOWNLOAD_URL = `https://gangxin.oss-cn-beijing.aliyuncs.com/img/2020.0x.0x预控日报数据.xlsx`;

@connect(({ preControl, user }) => ({ preControl, user }))
@Form.create()
class PreControl extends CommonList {
    loadData(params) {
        const p = params.pagination;
        params.page = p.current;
        params.size = p.pageSize;
        params.type = 'day';
        if (params.createTime && params.createTime !== '') {
            params.createTime = moment(params.createTime).format('YYYY-MM-DD');
        }
        if (params.startTime && params.startTime !== '') {
            params.startTime = moment(params.startTime).format('YYYY-MM-DD');
        }
        if (params.endTime && params.endTime !== '') {
            params.endTime = moment(params.endTime).format('YYYY-MM-DD');
        }
        reaction.preControl.fetchList(params).then(err => {
            this.setState({
                loading: false
            });
        });
    }
    handleDelete(ids) {
        confirm({
            title: '确定删除此数据吗？',
            cancelText: '取消',
            okText: '确定',
            onOk: () => {
                reaction.preControl
                    .deleteExcel([ids], this.props.user.username)
                    .then(() => {
                        message.success('删除成功');
                        this.reloadData(!this.props.preControl.list.length);
                    });
            }
        });
    }
    render() {
        const {
            form: { getFieldDecorator },
            preControl: { list, pagination }
        } = this.props;
        return (
            <div className="page-inner">
                <PageHeader title="预控日报" />
                <div className={style.costFilter}>
                    <FormItemGroup>
                        <FormItem label="日期：" style={{ marginRight: 30 }}>
                            {getFieldDecorator('rangeTime')(
                                <RangePicker
                                    style={{ width: 230 }}
                                    picker="week"
                                    onChange={this.handleChangeDate}
                                    placeholder={['开始日期', '结束日期']}
                                    className="range-date-picker"
                                />
                            )}
                        </FormItem>
                        <FormItem label="创建日期：" style={{ marginRight: 30 }}>
                            {getFieldDecorator('createTime')(
                                <DatePicker
                                    format={datePickerConfig.formateDate}
                                    style={{ width: 150 }}
                                />
                            )}
                        </FormItem>
                        <FormItem label="创建人：" labelWidth={52}>
                            {getFieldDecorator('createUser', {
                                initialValue: ''
                            })(
                                <Input
                                    style={{ width: 150 }}
                                    maxLength={10}
                                    placeholder="请输入创建人名称"
                                />
                            )}
                        </FormItem>
                        <div className={style.costRgiht}>
                            <Button type="primary" onClick={this.handleSearchData}>
                                查询
                            </Button>&nbsp;
                            <Button type="primary" onClick={this.resetFormAndReload}>
                                重置
                            </Button>
                        </div>
                    </FormItemGroup>
                </div>
                <div className={style['table-operations']} style={{ display: 'flex', alignItems: 'center' }}>
                    <WrapAuth path={dailyControl.import.path}>
                        <ImportData
                            action={preControl.importData}
                            withStartDate={true}
                            onChange={this.handleSearchData}
                            user={{
                                fieldName: 'createUser',
                                fieldValue: this.props.user.realName
                            }}
                        />
                    </WrapAuth>
                    <WrapAuth path={dailyControl.download.path}>
                        <Button
                            icon="download"
                            onClick={() => window.open(DOWNLOAD_URL)}
                        >
                            {'导入模板下载'}
                        </Button>
                    </WrapAuth>
                </div>
                <Spin spinning={this.state.loading}>
                    <Table
                        dataSource={list}
                        loading={false}
                        rowKey="id"
                        pagination={this.getPageConfig(pagination)}
                        borderd
                    >
                        <Column
                            title="序号"
                            key="id"
                            align="center"
                            width={60}
                            render={(text, record, index) => {
                                return index + (this.state.pagination.current - 1) * 10 + 1;
                            }}
                        />
                        <Column
                            title="日期"
                            key="startTime"
                            dataIndex="startTime"
                            align="center"
                            width={120}
                            render={time => {
                                return moment(time).format('YYYY-MM-DD');
                            }}
                        />
                        <Column
                            title="文件名"
                            key="fileName"
                            dataIndex="fileName"
                            align="center"
                        />
                        <Column
                            title="创建人"
                            key="createUser"
                            dataIndex="createUser"
                            align="center"
                            width={100}
                        />
                        <Column
                            title="创建时间"
                            key="createTime"
                            dataIndex="createTime"
                            align="center"
                            width={155}
                            render={time => {
                                return moment(time).format('YYYY-MM-DD HH:mm:ss');
                            }}
                        />
                        <Column
                            title="操作"
                            key="handler"
                            align="center"
                            width={120}
                            render={row => {
                                return (
                                    <React.Fragment>
                                        <WrapAuth path={dailyControl.delete.path}>
                                            <a
                                                className="link-handle"
                                                onClick={() => this.handleDelete(row.id)}
                                                href="javascript:;"
                                            >
                                                删除
                                            </a>
                                        </WrapAuth>
                                    </React.Fragment>
                                );
                            }}
                        />
                    </Table>
                </Spin>
            </div>
        );
    }
}

export default PreControl;
