import React from 'react';
import { Route } from 'react-router-dom';

import BaseLayout from 'layouts';
import Login from 'pages/login';

import Notice from 'pages/notice';
import NonticeAdd from 'pages/notice/add';
import NoticeEdit from 'pages/notice/edit';

import News from 'pages/news';
import NewsAdd from 'pages/news/add';
import NewsEdit from 'pages/news/edit';

import WxNews from 'pages/wx_news';
import WxNewsAdd from 'pages/wx_news/add';
import WxNewsEdit from 'pages/wx_news/edit';

import Banner from 'pages/banner';
import BannerAdd from 'pages/banner/add';
import BannerEdit from 'pages/banner/edit';

import Category from 'pages/category';
import CategoryAdd from 'pages/category/add';
import CategoryEdit from 'pages/category/edit';

import Help from 'pages/help';
import HelpAdd from 'pages/help/add';
import HelpEdit from 'pages/help/edit';

import WebTheme from 'pages/theme/web';
import WebThemeAdd from 'pages/theme/web/add';
import WebThemeEdit from 'pages/theme/web/edit';

import AppTheme from 'pages/theme/app';
import AppThemeAdd from 'pages/theme/app/add';
import AppThemeEdit from 'pages/theme/app/edit';

import SteelPriceVarieties from 'pages/TradeSteelPrice/Varieties/List';
import SteelPriceVarietiesAdd from 'pages/TradeSteelPrice/Varieties/Add';
import SteelPriceVarietiesEdit from 'pages/TradeSteelPrice/Varieties/Edit';

import SteelPricePrice from 'pages/TradeSteelPrice/Price/List';
import SteelPricePriceAdd from 'pages/TradeSteelPrice/Price/Add';
import SteelPricePriceEdit from 'pages/TradeSteelPrice/Price/Edit';

import DiffPrice from 'pages/purchase/diff';
import DiffPriceCreate from 'pages/purchase/diff/add';
import DiffPriceEdit from 'pages/purchase/diff/edit';

import DiffPortPrice from 'pages/purchase/diff-port';
import DiffPortPriceCreate from 'pages/purchase/diff-port/add';
import DiffPortPriceEdit from 'pages/purchase/diff-port/edit';

import PortsPriceInfo from 'pages/purchase/ports';
import PortsPriceInfoCreate from 'pages/purchase/ports/add';
import PortsPriceInfoEdit from 'pages/purchase/ports/edit';

import OtherPriceInfo from 'pages/purchase/other';
import OtherPriceInfoCreate from 'pages/purchase/other/add';
import OtherPriceInfoEdit from 'pages/purchase/other/edit';


import PreControl from 'pages/preControl/day';
import PreControlCreate from 'pages/preControl/day/add';
import PreControlEdit from 'pages/preControl/day/edit';


import PreControlWeek from 'pages/preControl/week';
import PreControlCreateWeek from 'pages/preControl/week/add';
import PreControlEditWeek from 'pages/preControl/week/edit';

export const basicRoutes = [
  {
    path: '/notice',
    component: Notice,
    children: [
      {
        path: 'edit/:id',
        component: NoticeEdit
      },
      {
        path: 'add',
        component: NonticeAdd
      }
    ]
  },
  {
    path: '/banner',
    component: Banner,
    children: [
      {
        path: 'edit/:id',
        component: BannerEdit
      },
      {
        path: 'add',
        component: BannerAdd
        // exact: true,
      }
    ]
  },
  {
    path: '/web-theme',
    component: WebTheme,
    children: [
      {
        path: 'edit/:id',
        component: WebThemeEdit
      },
      {
        path: 'add',
        component: WebThemeAdd
      }
    ]
  },
  {
    path: '/app-theme',
    component: AppTheme,
    children: [
      {
        path: 'edit/:id',
        component: AppThemeEdit
      },
      {
        path: 'add',
        component: AppThemeAdd
      }
    ]
  },
  {
    path: '/category',
    component: Category,
    children: [
      {
        path: 'add',
        component: CategoryAdd
      },
      {
        path: 'edit/:id',
        component: CategoryEdit
      }
    ]
  },
  {
    path: '/help',
    component: Help,
    children: [
      {
        path: 'add',
        component: HelpAdd
      },
      {
        path: 'edit/:id',
        component: HelpEdit
      }
    ]
  },
  {
    path: '/news',
    component: News,
    children: [
      {
        path: 'add',
        component: NewsAdd
      },
      {
        path: 'edit/:id',
        component: NewsEdit
      }
    ]
  },
  {
    path: '/wx_news',
    component: WxNews,
    children: [
      {
        path: 'add',
        component: WxNewsAdd
      },
      {
        path: 'edit/:id',
        component: WxNewsEdit
      }
    ]
  },
  {
    path: '/steelpricevarieties',
    component: SteelPriceVarieties,
    children: [
      {
        path: 'add',
        component: SteelPriceVarietiesAdd
      },
      {
        path: 'edit/:id',
        component: SteelPriceVarietiesEdit
      }
    ]
  },
  {
    menu: '钢价数据管理',
    path: '/steelpricedata',
    component: SteelPricePrice,
    children: [
      {
        path: 'add',
        component: SteelPricePriceAdd
      },
      {
        path: 'edit/:id',
        component: SteelPricePriceEdit
      }
    ]
  },
  {
    path: '/purchase-diff',
    component: DiffPrice,
    children: [
      {
        path: 'add',
        component: DiffPriceCreate
      },
      {
        path: 'edit/:id',
        component: DiffPriceEdit
      }
    ]
  },
  {
    path: '/purchase-diff-port',
    component: DiffPortPrice,
    children: [
      {
        path: 'add',
        component: DiffPortPriceCreate
      },
      {
        path: 'edit/:id',
        component: DiffPortPriceEdit
      }
    ]
  },
  {
    path: '/purchase-ports',
    component: PortsPriceInfo,
    children: [
      {
        path: 'add',
        component: PortsPriceInfoCreate
      },
      {
        path: 'edit/:id',
        component: PortsPriceInfoEdit
      }
    ]
  },
  {
    path: '/purchase-other',
    component: OtherPriceInfo,
    children: [
      {
        path: 'add',
        component: OtherPriceInfoCreate
      },
      {
        path: 'edit/:id',
        component: OtherPriceInfoEdit
      }
    ]
  },
  {
    path: '/preControl',
    component: PreControl,
    children: [
      {
        path: 'add',
        component: PreControlCreate
      },
      {
        path: 'edit/:id',
        component: PreControlEdit
      }
    ]
  },
  {
    path: '/preControlWeek',
    component: PreControlWeek,
    children: [
      {
        path: 'add',
        component: PreControlCreateWeek
      },
      {
        path: 'edit/:id',
        component: PreControlEditWeek
      }
    ]
  }
];

export function getRoutes(routes = [], prefix = '') {
  return routes.map(route => {
    const { children = [], path, name, menu, component, ...props } = route;
    const componentProps = {};
    if (children.length) {
      componentProps.render = () => {
        return (
          <BaseLayout>
            <Route path={path} component={component} exact />
            {getRoutes(children, path + '/')}
          </BaseLayout>
        );
      };
    } else {
      componentProps.component = component;
    }
    return (
      <Route
        {...props}
        {...componentProps}
        path={prefix + path}
        key={prefix + path}
      />
    );
  });
}

export const userRoutes = [
  {
    path: '/login',
    component: Login,
    children: []
  }
];
