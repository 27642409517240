import React from 'react';
import { Select } from 'antd';

import style from './select.module.scss';

const {
  Option,
} = Select;

class Select2 extends React.Component {
  render () {
    const {
      width,
      options = [],
      emptyText = '暂无数据',
      placeholder = '请选择',
      filterHidden = true, // 是否需要过滤掉属性为hidden的数据
      ...props
    } = this.props;

    const SelectOptions = options.filter(el => !filterHidden || !el.isHidden).map((option, index) => {
      return <Option key={option.value || 'null=' + index} value={option.value}>{option.label}</Option>;
    });

    return (
      <Select
        notFoundContent={emptyText}
        className={style.wrapper}
        style={{ width }}
        placeholder={placeholder}
        { ...props }
      >
        {SelectOptions}
      </Select>
    );
  }
}

export default Select2;
