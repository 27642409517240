import React, { useState } from 'react';
import { Modal, Upload, Button, Icon, message, Progress, DatePicker } from 'antd';
import { getToken } from 'utils';
import styles from './style.module.scss';
import moment from 'moment';

function ImportData({
    title, action = '',
    buttonProps,
    onChange,
    withCredentials = true,
    withStartDate = false,
    withEndDate = false,
    user = null,
}) {
    const [visible, setVisible] = useState(false);
    const [file, setFile] = useState(null);
    const [, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const myRef = React.createRef();

    const closeModal = () => {
        setUploading(false);
        setVisible(false);
    }

    const handleUploadChange = ({ file, fileList }) => {
        setFile(file);
        setFileList(fileList);
    }

    function clearFile() {
        setFile(null);
        setFileList([]);
    }

    const resetUpload = () => {
        clearFile();
        setUploading(false);
    }

    function uploadFile() {

        const formData = new FormData()
        const xhr = new XMLHttpRequest();

        setUploading(true);
        const fileFieldName = (withStartDate || withEndDate) ? 'excel' : 'file';
        formData.append(fileFieldName, file);
        if (withStartDate || withEndDate) {
            formData.append('startTime', startTime || '');
            formData.append('endTime', endTime || '');
        }
        if (user && user !== '') {
            formData.append(user.fieldName, user.fieldValue);
        }
        xhr.open('post', `${action}?token=${getToken()}`);
        xhr.upload.onprogress = xhr.onprogress = (event) => {
            setProgress(event.loaded / event.total * 100);
        }
        xhr.onerror = (err) => {
            visible && message.error('服务器错误');
            resetUpload();
        };
        if (withCredentials) {
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        }

        xhr.addEventListener('load', ({ target }) => {
            resetUpload();
            const res = JSON.parse(target.response);
            if (res.code === 0 || res.code === 200) {
                message.success('上传成功');
                closeModal();
                onChange();
            } else {
                visible && message.error(`上传失败${res.msg ? `，${res.msg}` : ''}`);
            }
        });
        xhr.send(formData);
    }

    function handleBeforeUpload(file) {
        setFile(file);
        return false;
    }
    function handleRemoveFile() {
        if (uploading) {
            return false;
        }
        clearFile();
    }

    let uploadButtonDisabled = false;

    if (withStartDate && (!startTime || startTime === '')) {
        uploadButtonDisabled = true;
    }
    if (withEndDate && (!endTime || endTime === '')) {
        uploadButtonDisabled = true;
    }

    function disabledDate(current) {
        return current && current >= moment().endOf('day');
    }

    function onRangePickerClicked() {
        myRef.current.picker.input.click();
    }

    return (
        <React.Fragment>
            <Button {...buttonProps} onClick={() => setVisible(true)}></Button>
            <Modal
                visible={visible}
                title={withStartDate ? (
                    <div>
                        <span>导入数据</span>
                        <span style={{
                            marginLeft: 12,
                            fontSize: '12px',
                            color: 'red'
                        }}>选择相同日期上传会自动覆盖原有数据</span>
                    </div>
                ) : <span>{title}</span>}
                maskClosable={false}
                onCancel={closeModal}
                okText="确定上传"
                onOk={uploadFile}
                okButtonProps={{ disabled: !file, loading: uploading }}
                centered
            >
                {(withStartDate && !withEndDate) && (
                    <div className={styles.dateBox}>
                        <DatePicker
                            placeholder={'请选择日期'}
                            disabledDate={disabledDate}
                            onChange={(date, dateString) => {
                                setStartTime(dateString);
                            }}
                        />
                    </div>
                )}
                {(withStartDate && withEndDate) && (
                    <div className={styles.dateBox} style={{ position: 'relative', height: 40 }}>
                        {(startTime && endTime && startTime !== '' && endTime !== '') && (
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: 999,
                                    background: '#ffffff',
                                }}
                                onClick={onRangePickerClicked}
                            >
                                {`${startTime}~${endTime}`}</div>
                        )}
                        <DatePicker
                            ref={myRef}
                            style={{
                                position: 'absolute',
                                zIndex: 998,
                                width: 170
                            }}
                            onChange={(date, dateString) => {
                                let d1 = new Date(dateString);
                                let d2 = new Date(dateString);
                                let week = d1.getDay()
                                let newD = d1.setDate(d1.getDate() + (5 - week) - 6); //设置天数 -6 天
                                let newD1 = d2.setDate(d2.getDate() + (5 - week)); //设置天数 -6 天
                                setStartTime(moment(newD).format('YYYY-MM-DD'))
                                setEndTime(moment(newD1).format('YYYY-MM-DD'))
                            }}
                            disabledDate={disabledDate}
                        />
                    </div>
                )}
                <div className={styles.upload}>
                    <Upload
                        beforeUpload={handleBeforeUpload}
                        accept=".xls,.xlsx"
                        action={action}
                        onChange={handleUploadChange}
                        showUploadList={false}
                        disabled={!!file}
                    >
                        {
                            !file ? (
                                <Button icon="uplload" style={{ width: 170 }} block disabled={uploadButtonDisabled}>点我选择文件</Button>
                            ) : (
                                    <div className={styles.file}>
                                        <span>
                                            <Icon type="paper-clip" />
                                            <span>{file.name}</span>
                                            <Icon onClick={handleRemoveFile} title="删除文件" type="close" className={styles.close} />
                                        </span>
                                        {
                                            uploading ? <Progress percent={progress} /> : null
                                        }
                                    </div>
                                )
                        }
                    </Upload>
                </div>
            </Modal>
        </React.Fragment>
    );
}

ImportData.defaultProps = {
    buttonProps: {
        children: '从Excel文件导入',
        icon: 'upload'
    },
    title: '导入数据',
    onChange: () => { }
}

export default ImportData;
