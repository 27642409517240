import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, InputNumber, message, DatePicker } from 'antd';
import CommonEdit from 'pages/common/Edit';
import reaction from 'reaction';
import { datePickerConfig, purchaseProductType } from 'config';
import Select2 from 'components/Select2';
import style from 'pages/page.module.scss';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16
  }
};

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 }
  }
};

@connect(({ user }) => ({ user }))
@Form.create()
@withRouter
class OtherPriceInfoCreate extends CommonEdit {
  mode = 'add';
  handlePublish = () => {
    this.submitData();
  };
  submitData() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          submitting: true
        });
        values.dateTime = values.dateTime.format('YYYY-MM-DD');
        values.handlerName = this.props.user.username;
        reaction.purchaseOther
          .add(values)
          .then(res => {
            this.setState({
              submitting: false
            });
            message.success('保存成功');
            this.goListPage();
          })
          .catch(err => {
            this.setState({
              submitting: false
            });
          });
      }
    });
  }
  goListPage = () => {
    reaction.purchaseOther.clear();
    this.props.history.push('/purchase-other');
  };
  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>其他大类价格信息添加</span>
        </div>
        <div className={`${style.form} page-form`}>
          <Form.Item {...formItemLayout} label="商品大类" required>
            {getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: '请选择商品大类'
                }
              ]
            })(
              <Select2 style={{ width: 195 }} options={purchaseProductType} />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="价格" required>
            {getFieldDecorator('price', {
              rules: [
                {
                  required: true,
                  message: '请输入价格'
                }
              ]
            })(<InputNumber min={1} style={{ width: 195 }} />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="时间" required>
            {getFieldDecorator('dateTime', {
              rules: [
                {
                  required: true,
                  message: '请选择时间'
                }
              ]
            })(<DatePicker format={datePickerConfig.formateDate} />)}
          </Form.Item>
          <Form.Item label="" {...formTailLayout} required>
            <Button
              type="primary"
              className={style['button']}
              onClick={this.handlePublish}
              loading={this.state.submitting}
            >
              保存
            </Button>
            <Button
              className={`${style.button} ${style['button-back']}`}
              onClick={this.goListPage}
            >
              返回
            </Button>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default OtherPriceInfoCreate;
