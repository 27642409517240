import http from 'utils/request';

export default {
  // Todo
  fetchVarietiesList(data) {
    return http.request('/iconBrand/page', data);
  },
  fetchVarietyDetail(data) {
    return http.request('/iconBrand/detail', data, { method: 'get' });
  },
  updateVariety(data) {
    return http.request('/iconBrand/update', data);
  },
  addVariety(data) {
    return http.request('/iconBrand/save', data);
  },
  delVareties(data) {
    return http.request('/iconBrand/delete', data);
  },

  fetchPriceList(data) {
    return http.request('/steelData/page', data);
  },
  fetchPriceDetail(data) {
    return http.request('/steelData/detail', data, { method: 'get' });
  },
  updatePrice(data) {
    return http.request('/steelData/update', data);
  },
  addPrice(data) {
    return http.request('/steelData/save', data);
  },
  delPrice(data) {
    return http.request('/steelData/delete', data);
  },
  fetchGoodsTypeNames(data) {
    return http.request('/iconBrand/goodsTypeNames', data);
  },
  fetchGoodsNameList(data) {
    return http.request('/iconBrand/listAllNames', data);
  }
};
