import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import uamsdk from 'zlgx-uamsdk';
import reaction from 'reaction';
// import storage from 'utils/localStorage';
import { validationLoginStatus, setToken, saveAuth } from 'utils';
import Logo from 'modules/Logo';

import style from './login.module.scss';

@withRouter
class Login extends Component {
  state = {
    isLogin: validationLoginStatus(),
  }
  fetchUserAuth() {
    reaction.user.getUserAuth().then(data => {
      let cache = data || {
        roleInfo: {
          roleAuthList: []
        }
      };
      if (!data) {
        console.error('接口请求失败，请稍后再试。。。');
      }
      saveAuth(cache.roleInfo.roleAuthList);
    });
  }
  fetchUserInfo() {
    reaction.user.initial();
  }
  goHome() {
    this.props.history.replace('/');
    this.fetchUserAuth();
    this.fetchUserInfo();
  }
  componentDidMount() {
    if (this.state.isLogin) {
      this.goHome();
    } else {
      // this.createLoginIframe();
    }
  }
  doLogin() {
    if (this.state.isLogin) {
      this.goHome();
    } else {
      uamsdk.login((data) => {
        if (!data.error) {
          setToken(data.token);
          console.log('登录成功....');
          this.goHome(data.token);
        } else {
          console.log('登录失败...', data); // eslint-disable-line
        }
      });
    }
  }
  handleLogin = () => {
    this.doLogin();
  };
  createLoginIframe() {
    uamsdk.createLoginIframe('login-wrapper', {
      height: '350px',
      // 因为iframe内部有个top100的样式，所以这里往上拉50px
      position: 'relative',
      top: '-35px'
    }, (res) => {
      console.log(res)
    });
  }
  createNormalLogin() {
    return (
      <React.Fragment>
        <div>
          <Logo></Logo>
        </div>
        <div className={style.btn}>
          <Button onClick={this.handleLogin} type="primary">立即登录</Button>
        </div>
      </React.Fragment>
    )
  }
  render () {
    return (
      <div className={style.wrapper}>
        <div className={style.content} id="login-wrapper">
          {this.createNormalLogin()}
        </div>
      </div>
    );
  }
}

export default Login;
