import React, { Component } from 'react';
import { Input } from 'antd';

import style from './textarea.module.scss';

export default class TextArea extends Component {
  render() {
    return (
      <div className={style.wrapper}>
        <Input.TextArea {...this.props}></Input.TextArea>
      </div>
    );
  }
}
