import React from 'react';
import { Form, Input, Button, TreeSelect, InputNumber, message, Radio } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CommonEdit from '../common/Edit';
import Select from 'components/Select2';
import { newPageStatusOptions, categoryTopMenus, platformsForHelp } from 'config';
import { resolveTreeNodes } from 'utils';
import reaction from 'reaction';
import Upload from 'components/Upload2';

import style from '../page.module.scss';

// const { TreeNode } = TreeSelect;

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8,
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
};

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
};

@connect(({ user, category }) => ({ user, category }))
@Form.create()
@withRouter
 class CategoryCreate extends CommonEdit {
  mode = 'add';
  state = {
    categoryType: 'NEWS',
    platform: null
  };
  submitData (data) {
    reaction.category
      .add(data)
      .then((res) => {
        this.setState({
          submitting: false,
        });
        message.success('添加成功');
        this.props.history.replace('/category');
      })
      .catch((err) => {
        this.setState({
          submitting: false,
        });
      });
  }
  handlePlatformChange = (v) => {
    this.props.form.setFieldsValue({
      parentId: undefined,
    });
    this.setState({
      platform: v
    }, () => {
      this.loadCategories();
    });
  }
  loadCategories(platform = this.state.platform, categoryType = this.state.categoryType) {
    reaction.category.fetchNodes({
      parentId: 0,
      showSub: true,
      platform,
      categoryType
    });
  }
  getTotalNodes (data) {
    return [
      {
        ...categoryTopMenus,
        children: resolveTreeNodes(data),
      },
    ];
  }
  clear () {
    reaction.category.clear();
  }
  handleCategoryTypeChange = (e) => {
    this.props.form.setFieldsValue({
      parentId: undefined,
    });
    this.setState({
      categoryType: e.target.value
    }, () => {
      this.loadCategories()
    });
  }
  render () {
    const { form: { getFieldDecorator }, category: { nodes } } = this.props;
    const treeNodes = this.getTotalNodes(nodes); // eslint-disable-line
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>栏目添加</span>
          {/* <span className={style.crm}>添加栏目<span className={style.split}>/</span><Link to="/category">栏目列表</Link></span> */}
        </div>
        <div className={`${style.form} page-form`}>
        <Form.Item label="应用模块" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('categoryType', {
              initialValue: 'NEWS'
            })(
              <Radio.Group onChange={this.handleCategoryTypeChange}>
                <Radio value="NEWS">资讯中心</Radio>
                <Radio value="HELP">帮助中心</Radio>
                <Radio value="WX_NEWS">微官网资讯</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="所属平台" {...formItemLayout} required>
            {getFieldDecorator('platform', {
              rules: [
                {
                  message: '请选择平台',
                  required: true,
                },
              ],
            })(<Select style={{ width: 220 }} onChange={this.handlePlatformChange} placeholder="请选择" options={platformsForHelp} />)}
          </Form.Item>
          <Form.Item label="父栏目名称" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('parentId', {
              inititalValue: undefined,
              rules: [
                {
                  required: true,
                  message: '请选择父栏目',
                },
              ],
            })(
              <TreeSelect disabled={!treeNodes.length} style={{ width: 220 }} treeData={treeNodes} placeholder="请选择" />
            )}
          </Form.Item>
          <Form.Item label="栏目名称" labelWidth={72} {...formItemLayout} required>
            {getFieldDecorator('name', {
              rules: [
                {
                  message: '请输入栏目名称',
                  required: true,
                  whitespace: true,
                },
              ],
              normalize: (val) => (val ? String(val).trim() : ''),
            })(<Input style={{ width: 220 }} placeholder="请输入栏目名称" maxLength={6} />)}
          </Form.Item>
          <Form.Item
            label="图标"
            {...formItemLayout}
          >
            {
              getFieldDecorator('iconUrl', {
                initialValue: '',
                rules: [{
                  message: '请上传图标',
                  required: false,
                }],
              })(
                <Upload />
              )
            }
            <p className={style.tip}>请上传JPG、PNG格式.</p>
          </Form.Item>
          <Form.Item label="排序" {...formItemLayout} required>
            {getFieldDecorator('sorted', {
              initialValue: 0,
              rules: [
                {
                  message: '请输入排序序号',
                  required: true,
                },
              ],
            })(<InputNumber max={999} min={0} style={{ width: 220 }} placeholder="请输入排序序号" />)}
          </Form.Item>
          <Form.Item label="状态" {...formItemLayout} required>
            {getFieldDecorator('status', {
              rules: [
                {
                  message: '请选择状态',
                  required: true,
                },
              ],
            })(<Select style={{ width: 220 }} placeholder="请选择" options={newPageStatusOptions} filterHidden />)}
          </Form.Item>
          <Form.Item label="" {...formTailLayout} required>
            <Button
              type="primary"
              className={style['button']}
              onClick={this.handlePublish}
              loading={this.state.submitting}
            >
              发布
            </Button>
            {/* <Button className={style.button}>返回</Button> */}
            <Link className={`${style.button} ${style['button-back']}`} to="/category">
              返回
            </Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default CategoryCreate;
