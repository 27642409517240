import React from 'react';
import { Form, Button, Modal, message, Spin, Row, Col, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import reaction from 'reaction';
import { funcType } from 'reaction/tradeSteelPrice';
import PageHeader from 'modules/PageHeader';
import Select from 'components/Select2';
import Table from 'components/Table2';
import CommonList from '../../common/List';
import tradeSteelPriceApi from 'api/tradeSteelPrice';
import { path } from 'config';

import commonStyle from '../../page.module.scss';
import style from './price.module.scss';
import WrapAuth from 'components/WrapAuth';
import { checkAuth } from 'utils';

const { confirm } = Modal;
const { RangePicker } = DatePicker;

const steelData = path.cms.steelData;

@withRouter
@connect(({ tradeSteelPrice, user }) => ({ tradeSteelPrice, user }))
@Form.create()
class PriceList extends CommonList {
  otherState = {
    selectedRowKeys: [],
    selectRows: []
  };
  componentDidMount() {
    reaction.tradeSteelPrice
      .fetchData({ id: '' }, funcType.fetchGoodsNameList)
      .then(err => {
        // console.log(err);
      });
    this.handleSearchData();
  }
  loadData(params) {
    reaction.tradeSteelPrice
      .fetchData(params, funcType.fetchPriceList)
      .then(err => {
        this.setState({
          loading: false,
          selectRow: [],
          selectedRowKeys: []
        });
      });
  }
  handleCheckboxChange = (selectedRowKeys, selectedRows) => {
    let selectRows = [];
    for (let item of selectedRows) {
      selectRows.push(item.id);
    }
    this.setState({
      selectRows,
      selectedRowKeys
    });
  };
  handleDeleteAll = () => {
    confirm({
      title: '确定批量删除所勾选数据吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        if (this.state.selectRows && this.state.selectRows.length > 0) {
          tradeSteelPriceApi
            .delPrice({
              ids: this.state.selectRows,
              handlerId: this.props.user.uid
            })
            .then(() => {
              message.success('批量删除成功');
              this.reloadData();
            });
        } else {
          message.error('请勾选需要删除的数据！');
        }
      }
    });
  };
  handleDelete(ids) {
    confirm({
      title: '确定删除该条数据吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        tradeSteelPriceApi
          .delPrice({ ids, handlerId: this.props.user.uid })
          .then(() => {
            message.success('删除成功');
            this.reloadData();
          });
      }
    });
  }

  render() {
    const {
      form: { getFieldDecorator },
      tradeSteelPrice: {
        priceList: list = [],
        pricePagination: pagination = {},
        goodsNameList = []
      }
    } = this.props;
    const newGoodsNameList = [{ value: null, label: '全部' }, ...goodsNameList];
    const columns = [
      {
        title: '商品大类',
        dataIndex: 'goodsBigTypeName',
        align: 'center',
        key: 'goodsBigTypeName'
      },
      {
        title: '商品小类',
        dataIndex: 'goodsSmallTypeName',
        align: 'center',
        key: 'goodsSmallTypeName'
      },
      {
        title: '品名',
        dataIndex: 'name',
        align: 'center',
        key: 'name'
      },

      {
        title: '价格（元/吨）',
        dataIndex: 'guidePrice',
        align: 'center',
        key: 'guidePrice'
      },
      {
        title: '日期',
        dataIndex: 'marketTime',
        align: 'center',
        key: 'marketTime',
        render: text => {
          return text.substr(0, 10);
        }
      },
      {
        title: '操作',
        dataIndex: 'id',
        align: 'center',
        key: 'id',
        render: text => (
          <React.Fragment>
            <WrapAuth path={steelData.update.path}>
              <Link to={`/steelpricedata/edit/${text}`} className="link-handle">
                编辑
              </Link>
            </WrapAuth>
            <WrapAuth path={steelData.delete.path}>
              <a
                href="javascript:;"
                className="link-handle"
                onClick={() => this.handleDelete([text])}
              >
                删除
              </a>
            </WrapAuth>
          </React.Fragment>
        )
      }
    ];
    return (
      <div className="page-inner">
        <PageHeader title="钢价数据管理">
          <WrapAuth path={steelData.add.path}>
            <Link to="/steelpricedata/add" className="page-btn">
              +&nbsp;添加钢价数据
            </Link>
          </WrapAuth>
        </PageHeader>
        <div className={commonStyle.filter}>
          <Form>
            <Row>
              <Col span={7}>
                <Form.Item
                  label="品名："
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  className={style.styleFormItem}
                >
                  {getFieldDecorator('tradeId', {
                    initialValue: null
                  })(
                    <Select
                      width="100%"
                      placeholder="请选择"
                      className="select2"
                      showSearch
                      optionFilterProp="children"
                      options={newGoodsNameList}
                      filterHidden={false}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="日期："
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 16 }}
                  className={style.styleFormItem}
                >
                  {getFieldDecorator('rangeTime')(
                    <RangePicker
                      onChange={this.handleChangeDate}
                      className={style.fixRangDateStyle}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={5} className={style.operateFormItem}>
                <Button type="primary" onClick={this.handleSearchData}>
                  查询
                </Button>
                <WrapAuth path={steelData.delete.path}>
                  <Button
                    type="primary"
                    onClick={this.handleDeleteAll}
                    style={{ marginLeft: 16 }}
                  >
                    批量删除
                  </Button>
                </WrapAuth>
              </Col>
            </Row>
          </Form>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            rowKey="id"
            dataSource={list}
            columns={columns}
            pagination={this.getPageConfig(pagination)}
            rowSelection={
              checkAuth(steelData.delete.path)
                ? {
                    onChange: this.handleCheckboxChange,
                    selectedRowKeys: this.state.selectedRowKeys
                  }
                : null
            }
          />
        </Spin>
      </div>
    );
  }
}

export default PriceList;
