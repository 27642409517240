/**
 * copy form https://github.com/lomocc/redux-create-store.git
 * 主要解决react 版本依赖和项目依赖版本不一致的问题
 * */

const redux = require('redux');
const thunk = require('redux-thunk').default;
const assign = require('object-assign');

export default function(reducer, preloadedState, enhancer){
  if(typeof reducer === 'function'){
    return assign.apply(null, arguments);
  }else{
    const middlewares = Array.prototype.slice.call(arguments, 3);
    const store = redux.applyMiddleware.apply(null, [thunk].concat(middlewares))(redux.createStore)(redux.combineReducers(reducer), preloadedState, enhancer);
    for (let key in reducer) {
      if(reducer.hasOwnProperty(key)) {
        assign(reducer[key], redux.bindActionCreators(assign({}, reducer[key]), store.dispatch));
      }
    }
    assign(reducer, {dispatch:store.dispatch});
    return store;
  }
};
