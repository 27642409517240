import http from 'utils/request';

export default {
  fetchList(data) {
    return http.request('/banner/page', data);
  },
  update(data) {
    return http.request('/banner/update', data);
  },
  add(data) {
    return http.request('/banner/save', data);
  },
  /**
   * 获取banner详情
   * @param {Number} id banner图id
   */
  detail(id) {
    return http.request('/banner/detail', { id }, { method: 'get' });
  },
  /**
   * 删除banner
   * @param {*} id bannerId
   * @param {*} handlerName 操作人
   */
  delete(id, handlerName) {
    return http.request('/banner/delete', {
      id,
      status: 'DEL',
      handlerName,
    });
  }
};
