import http from 'utils/request';
import { API_PREFIX } from 'config/env';

export default {
  /**
   * 列表，分页
   * @param {Object} data 请求参数，pagination: { current, pageSize }
   */
  fetchList(data) {
    return http.request('/priceTrendFirst/getFirstList', data);
  },
  /**
   * 修改或新增PB价差信息
   * @param {Object} data 提交数据
   */
  save(data) {
    return http.request('/priceTrendFirst/update', data);
  },
  /**
   *
   * @param {Object} data 价格数据
   */
  add(data) {
    return http.request('/priceTrendFirst/add', data);
  },
  /**
   * 获取PB价差信息详情
   * @param {Number} id PB价差信息id
   */
  detail(id) {
    return http.request('/priceTrendFirst/getById', { id });
  },
  /**
   * 删除PB价差信息
   * @param {Number} id PB价差信息id
   * @param {*} handlerName 操作人
   */
  delete(ids = [], handlerName) {
    return http.request('/priceTrendFirst/deleteList', {
      ids,
      handlerName,
    })
  },
  /**
   * 获取模版下载地址
   */
  fetchTemplateUrl() {
    return http.request('/priceTrendFirst/download', {}, { method: 'get' });
  },
  importData: `${API_PREFIX}/priceTrendFirst/import`,
};
