import React from 'react';
import { Form, Spin } from 'antd';

const formItemLayout = {
  labelCol: {
    xs: 2,
    sm: 2
  },
  wrapperCol: {
    xs: 21,
    sm: 21,
  },
}

function CommonDetail({  data = {}, labelConfig = {}, loading }) {
  const items = Object.keys(labelConfig).map((key) => {
    const labelItem = labelConfig[key];
    const isFormat = typeof labelItem !== 'string';
    const label = isFormat ? labelItem.label : labelItem;
    const value = data[key];
    return (
      <Form.Item
        label={label}
        key={key}
        {
          ...formItemLayout
        }
      >
        {isFormat ? labelItem.format(value, data) : value}
      </Form.Item>
    )
  });

  return (
    <div className="page-form">
      <Spin spinning={loading}>
        {items}
      </Spin>
    </div>
  );
}

export default CommonDetail;
