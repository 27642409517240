import React from 'react';
import {
  Form,
  Input,
  Button,
  TreeSelect,
  message,
  InputNumber,
  Checkbox
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextArea from 'components/TextArea';
import Upload from 'components/Upload2';
import CommonEdit from '../common/Edit';
import Select from 'components/Select2';
import { resolveTreeNodes } from 'utils';
import WebsiteInput from 'components/Website';
import { newPageStatusOptions, rules, platforms } from 'config';
import reaction from 'reaction';

import style from '../page.module.scss';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16
  }
};

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 }
  }
};

@connect(({ user, category }) => ({ user, category }))
@Form.create()
@withRouter
class NewsCreate extends CommonEdit {
  mode = 'add';

  loadCategories(platform = null, categoryType = 'NEWS') {
    this.clear();
    reaction.category.fetchNodes({
      parentId: 0,
      showSub: true,
      platform,
      categoryType
    });
  }
  submitData(data) {
    reaction.news
      .add(data)
      .then(res => {
        this.setState({
          submitting: false
        });
        message.success('添加成功');
        this.props.history.replace('/news');
      })
      .catch(err => {
        message.error('添加失败');
        this.setState({
          submitting: false
        });
      });
  }
  clear() {
    reaction.category.clear();
    this.resetCategoryValue(['categoryIds']);
  }
  handlePlatformChange = platform => {
    this.loadCategories(platform);
  };
  render() {
    const {
      form: { getFieldDecorator },
      category: { nodes = [] },
      user
    } = this.props;

    const { startTime, submitting, isWechatArticle } = this.state;
    const categories = resolveTreeNodes(nodes, true);
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>资讯添加</span>
        </div>
        <div className={`${style.form} page-form`}>
          {this.renderTitle(undefined, '资讯标题')}
          <Form.Item label="所属平台" {...formItemLayout} required>
            {getFieldDecorator('platforms', {
              rules: [
                {
                  required: true,
                  message: '请选择所属平台'
                }
              ]
            })(
              <Select
                placeholder="请选择"
                options={platforms}
                style={{ width: 195 }}
                filterHidden={true}
                onChange={this.handlePlatformChange}
              />
            )}
          </Form.Item>
          <Form.Item
            label="所属栏目"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {getFieldDecorator('categoryIds', {
              rules: [
                {
                  required: true,
                  message: '请选择所属栏目'
                }
              ]
            })(
              <TreeSelect
                disabled={!categories.length}
                style={{ width: 600 }}
                treeData={categories}
                multiple
                placeholder="请选择"
              />
            )}
          </Form.Item>
          <Form.Item label="状态" {...formItemLayout} required>
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: '请选择状态'
                }
              ]
            })(
              <Select
                placeholder="请选择"
                options={newPageStatusOptions}
                style={{ width: 195 }}
              />
            )}
          </Form.Item>
          <Form.Item
            label="初始浏览量"
            {...formItemLayout}
            extra="只能数字，在0-100000"
            required
          >
            {getFieldDecorator('initialClick', {
              rules: [
                {
                  required: true,
                  message: '请输入初始浏览量'
                }
              ]
            })(
              <InputNumber
                placeholder="初始浏览量"
                maxLength={10}
                min={0}
                max={100000}
                style={{ width: 195 }}
              />
            )}
          </Form.Item>
          <Form.Item label="作者" {...formItemLayout} required>
            {getFieldDecorator('author', {
              initialValue: user.realName,
              rules: [
                {
                  required: true,
                  message: '请输入发布作者',
                  whitespace: true
                }
              ]
            })(
              <Input
                placeholder="请输入发布作者"
                maxLength={10}
                style={{ width: 195 }}
              />
            )}
          </Form.Item>
          {this.renderStartTime({ title: '上架时间', key: 'startTime' })}
          {this.renderEndTime(startTime, undefined, {
            title: '下架时间',
            key: 'expireTime',
            required: false
          })}
          <Form.Item label="文章摘要" {...formItemLayout} required>
            {getFieldDecorator('digest', {
              rules: [
                {
                  required: true,
                  message: '请输入文章摘要',
                  whitespace: true
                }
              ]
            })(
              <TextArea
                placeholder="请输入文章摘要"
                style={{ width: 600 }}
                rows={3}
              />
            )}
          </Form.Item>
          <Form.Item label="缩略图" {...formItemLayout} required>
            {getFieldDecorator('image', {
              rules: [
                {
                  message: '请上传缩略图',
                  required: true
                }
              ]
            })(<Upload />)}
            <p className={style.tip}>请上传JPG、PNG格式.</p>
          </Form.Item>
          <Form.Item label="资讯类型" {...formItemLayout}>
            <Checkbox
              onChange={this.handleChangeArticleType}
              checked={isWechatArticle}
            >
              是否微信公众号文章
            </Checkbox>
          </Form.Item>
          {isWechatArticle ? (
            <Form.Item label="跳转链接" {...formItemLayout} required>
              {getFieldDecorator('linkAddress', {
                rules: [
                  {
                    required: true,
                    message: '请输入跳转链接'
                  },
                  {
                    pattern: rules.website,
                    message: '链接地址格式不正确'
                  }
                ]
              })(
                <WebsiteInput
                  style={{ width: 520 }}
                  placeholder="请输入跳转链接"
                />
              )}
            </Form.Item>
          ) : (
            this.renderEditor(undefined, '文章正文')
          )}
          <Form.Item label="" {...formTailLayout} required>
            <Button
              type="primary"
              className={style['button']}
              onClick={this.handlePublish}
              loading={submitting}
            >
              发布
            </Button>
            {/* <Button className={style.button}>返回</Button> */}
            <Link
              className={`${style.button} ${style['button-back']}`}
              to="/news"
            >
              返回
            </Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default NewsCreate;
