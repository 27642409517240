import news from './news';
import category from './category';
import user from './user';
import notice from './notice';
import help from './help';
import banner from './banner';
import appTheme from './appTheme';
import webTheme from './webTheme';
import tradeSteelPrice from './tradeSteelPrice';
import purchaseDiff from './purchase-diff';
import preControl from './preControl';
import purchaseDiffPort from './purchase-diff-port';
import purchasePorts from './purchase-ports';
import purchaseOther from './purchase-other';
export default {
  news,
  category,
  user,
  notice,
  help,
  banner,
  appTheme,
  webTheme,
  tradeSteelPrice,
  purchaseDiff,
  purchaseDiffPort,
  purchasePorts,
  purchaseOther,
  preControl
};
