import tradeSteelPrice from 'api/tradeSteelPrice';

const initState = {
  varietiesList: [],
  varietiesPagination: {},
  varietyDetail: {},

  priceList: [],
  pricePagination: {},
  priceDetail: {},

  goodsBigTypeNameList: [],
  goodsSmallTypeNameList: [],
  goodsNameList: []
};

export const types = {
  updateData: 'UPDATE_DATA'
};

export const funcType = {
  fetchVarietiesList: 'fetchVarietiesList',
  fetchVarietyDetail: 'fetchVarietyDetail',
  fetchPriceList: 'fetchPriceList',
  fetchPriceDetail: 'fetchPriceDetail',
  fetchGoodsBigTypeNameList: 'fetchGoodsBigTypeNameList',
  fetchGoodsSmallTypeNameList: 'fetchGoodsSmallTypeNameList',
  fetchGoodsNameList: 'fetchGoodsNameList'
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.updateData:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
};

reducer.fetchData = (params, func) => {
  let callFunc = func;
  return dispatch => {
    if (
      func == funcType.fetchGoodsBigTypeNameList ||
      func == funcType.fetchGoodsSmallTypeNameList
    ) {
      callFunc = 'fetchGoodsTypeNames';
    }
    return tradeSteelPrice[callFunc](params).then(data => {
      let newData = {};
      switch (func) {
        case funcType.fetchVarietiesList:
          newData = {
            varietiesList: data.list,
            varietiesPagination: data.pagination
          };
          break;
        case funcType.fetchVarietyDetail:
          newData = {
            varietyDetail: data
          };
          break;
        case funcType.fetchPriceList:
          newData = {
            priceList: data.list,
            pricePagination: data.pagination
          };
          break;
        case funcType.fetchPriceDetail:
          newData = {
            priceDetail: data
          };
          break;
        case funcType.fetchGoodsBigTypeNameList:
          newData = {
            goodsBigTypeNameList: data
          };
          break;
        case funcType.fetchGoodsSmallTypeNameList:
          newData = {
            goodsSmallTypeNameList: data
          };
          break;
        case funcType.fetchGoodsNameList:
          newData = {
            goodsNameList: data
          };
          break;
        default:
          break;
      }
      dispatch({
        type: types.updateData,
        data: newData
      });
      return data;
    });
  };
};

reducer.resetGoodsNameList = data => {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({
        type: types.updateData,
        data: {
          goodsNameList: []
        }
      });
      resolve();
    });
  };
};

reducer.resetGoodsSmallTypeNameList = data => {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({
        type: types.updateData,
        data: {
          goodsSmallTypeNameList: []
        }
      });
      resolve();
    });
  };
};

reducer.appendGoodsNameList = data => {
  return dispatch => {
    return new Promise(resolve => {
      let newList = [...initState.goodsNameList];
      newList.unshift(data);
      dispatch({
        type: types.updateData,
        data: {
          goodsNameList: newList
        }
      });
      resolve();
    });
  };
};
export default reducer;
