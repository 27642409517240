import React from 'react';
import BraftEditor from 'braft-editor';
import ColorPicker from 'braft-extensions/dist/color-picker';
import UploadVieo from './UploadVideo';
import { ContentUtils } from 'braft-utils';
// 图片类型的表情包
// import Emoticon, { defaultEmoticons } from 'braft-extensions/dist/emoticon';

import { getToken } from 'utils'
import api from 'api';

// 引入编辑器样式
import 'braft-editor/dist/index.css'
// 颜色选择器样式
import 'braft-extensions/dist/color-picker.css'
// 引入表情包扩展模块样式文件
// import 'braft-extensions/dist/emoticon.css'
// 页面样式
import style from './editor.module.scss';

// 引入表情包扩展模块和默认表情包列表
// const emoticons = defaultEmoticons.map(item => require(`braft-extensions/dist/assets/${item}`))
// const emoticonOption = {
//   // includeEditors: ['editor-id-1'], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
//   // excludeEditors: ['editor-id-2'],  // 指定该模块对哪些BraftEditor无效
//   emoticons, // 指定可用表情图片列表，默认为空
//   closeOnBlur: false, // 指定是否在点击表情选择器之外的地方时关闭表情选择器，默认false
//   closeOnSelect: false // 指定是否在选择表情后关闭表情选择器，默认false
// }

// BraftEditor.use(Emoticon(emoticonOption))

/**
 * 这个插件不知道什么鬼，引入后会导致整个表单的输入框输入的时候有节流防抖动效果。。。有点怪
 */
BraftEditor.use(ColorPicker({
  theme: 'dark', // 支持dark和light两种主题，默认为dark
  includeEditors: ['editor-1'],
}))

// const matchHtmlRegexp = /(<([\w-]+)[^>]*>([^<]+)<\/\w+>)|(<img\s[^>]+\/?>)|(<audio\s[^>]+>[^>]*<\/audio>)|(<video\s[^>]+>[^>]*<\/video>)/;

export default class Editor extends React.Component {
  state = {
    editorState: BraftEditor.createEditorState(null),
    videoUrl: '',
    imageUrl: '',
    videoIsUploadComplete: false
  }
  isLoaded = false;
  // allowedTriggerValue = true;
  UNSAFE_componentWillReceiveProps(nextProps, props) {
    if ((nextProps.value || props.value) && !this.isLoaded) {
      this.updateValue(nextProps.value);
      this.isLoaded = true;
    }
  }
  componentDidMount() {
    this.updateValue(this.props.value && this.props.value.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"').replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&'));
  }
  updateValue(value) {
    // if (matchHtmlRegexp.test(value)) {
    this.setState({
      editorState: BraftEditor.createEditorState(value),
    });
    // }
  }
  handleEditorChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.triggerChangeValue(editorState);
  }
  triggerChangeValue = (editorState) => {
    const { onChange = () => { } } = this.props;
    onChange(editorState);
  }
  getExcludeControls() {
    // 因数据库暂不支持emoji表情，所以需要去掉此功能...
    return ['emoji', 'code'];
  }
  getExtendControls() {
    return [
      {
        key: 'custom-modal',
        type: 'modal',
        text: '上传本地视频',
        modal: {
          id: 'my-moda-1',
          title: '上传本地视频',
          className: 'upload-video',
          confirmable: this.state.videoIsUploadComplete,
          closeOnBlur: false, // 点记遮罩层关闭弹窗。。。
          width: 630,
          children: <UploadVieo onChange={this.handleVideoChange} />,
          onConfirm: (e) => {
            this.setState(({ editorState }) => {
              return {
                editorState: ContentUtils.insertMedias(editorState, [
                  {
                    type: 'VIDEO',
                    url: this.state.videoUrl,
                    width: 'auto',
                    height: 'auto',
                    meta: {
                      poster: this.state.imageUrl,
                      // loop: true,
                      // autoPlay: true,
                    },
                  }
                ]),
              }
            }, () => {
              this.resetVideoUploadState();
            });
          },
          onCancel: () => {
            this.resetVideoUploadState();
          }
        }
      }
    ];
  }
  getVideoHTML() {
    const {
      videoUrl,
      imageUrl,
    } = this.state;

    return `<video controls height="auto" loop poster="${imageUrl}" width="100%">
	      <source src="${videoUrl}"></source></video>`;
  }
  resetVideoUploadState() {
    this.setState({
      videoUrl: '',
      imageUrl: '',
      videoIsUploadComplete: false,
    });
  }
  handleVideoChange = ({ data, isComplete }) => {
    this.setState({
      ...data,
      videoIsUploadComplete: isComplete
    });
  }
  getMediaConfig() {
    return {
      accepts: {
        image: 'image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg',
        video: false,
        audio: false,
      },
      uploadFn({ file, progress, error, success }) {
        const formData = new FormData()
        formData.append('file', file);
        const xhr = new XMLHttpRequest();
        xhr.open('post', `${api.common.uploadFile}?token=${getToken()}`);
        xhr.upload.onprogress = (event) => {
          progress(event.loaded / event.total * 100);
        };
        xhr.onprogress = (event) => {
          progress(event.loaded / event.total * 100);
        };
        xhr.onerror = () => {
          error('服务器错误');
        };
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.addEventListener('load', ({ target }) => {
          const res = JSON.parse(target.response);
          if (res.code === 0) {
            success({
              url: res.data,
            });
          } else {
            // message.error(res.msg || '上传失败');
            error(res.msg || '上传失败');
          }
        });
        xhr.send(formData);
      }
    }
  }
  render() {
    const { editorState } = this.state;
    return (
      <BraftEditor
        {
          ...this.props
        }
        value={editorState}
        onChange={this.handleEditorChange}
        className={style.editor}
        media={this.getMediaConfig()}
        excludeControls={this.getExcludeControls()}
        extendControls={this.getExtendControls()}
        id="editor-1"
      />
    )
  }
}
