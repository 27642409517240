import http from 'utils/request';

export default {
  fetchList(data) {
    return http.request('/help/page', data);
  },
  update(data) {
    return http.request('/help/update', data);
  },
  add(data) {
    return http.request('/help/save', data);
  },
  /**
   * 获取公告详情
   * @param {Number} id 公告id
   */
  detail(id) {
    return http.request('/help/detail', { id }, { method: 'get' });
  },
  /**
   * 删除帮助
   * @param {Number} id 公告id
   */
  delete(id, handlerName) {
    return http.request('/help/delete', {
      id,
      status: 'DEL',
      handlerName,
    })
  },
};
