import http from 'utils/request';

export default {
  login(username, password) {
    return http.request('/user/login', { username, password });
  },
  initData(data) {
    return http.request('/common/initialData', data, { baseURL: '/api/v1' });
  },
  getUserInfo(token) {
    return http.request('/system/getUserByToken', { token }, { baseURL: '/api/v1' });
  },
  /**
   * 获取用户权限信息
   */
  getUserAuth(token) {
    // return http.request('/common/authDict', { token }, { baseURL: '/api/v1' });
    return http.request('/common/initialData', { token }, { baseURL: '/api/v1' });
  },
  /**
   * 获取所有权限
   * @param {String} token 用户登录token
   */
  getAllPermission(token) {
    return http.request('/system/listAuth', { token }, { baseURL: '/api/v1' });
  }
};
