const DEBUG = process.env.NODE_ENV !== 'production';
const RELEASE = process.env.NODE_ENV === 'production';

let SSO_URL = '//uam-sso.zlgx.com/';
const hostname = window.location.hostname;

if (/(localhost|test)/.test(hostname)) {
  SSO_URL = '//uam-sso.test.zlgx.com/';
}


let env = {
  ip: '10.82.12.242',
  port: 8081,
  // ip: '10.82.13.87',
  // port: 8081,
  socketIp: 'api.dc.minshengec.cn',
  socketPort: 9281,
  socketEvent: 'notify',
  PROD: /zlgx/.test(window.location.href),
  LOCAL: /(localhost)/.test(window.location.href),
  getIp() {
    return env.ip;
  },
  getPort() {
    return env.port;
  },
  getSocketIp() {
    return env.socketIp || env.ip;
  },
  getSocketPort() {
    return env.socketPort || env.port;
  },
  init(newConfig) {
    Object.keys(newConfig).forEach(key => {
      if (newConfig[key] != null) {
        env[key] = newConfig[key];
      }
    });
  },
  // getBaseHost() {
  //   return env.getRealServerHost();
  // },
  // getRealServerHost() {
  //   if (RELEASE) {
  //     return location.origin;
  //   }
  //   if (env.LOCAL) {
  //     // return 'http://cms-test.zlgx.com';
  //     return 'http://10.82.13.83:60008';
  //   }
  // },
  DEBUG,
  SSO_URL,
  RELEASE,
  PROJECT_ID: 'cms-manage',
  uam_server_cookie_name: 'uam_sso_server_token',
  API_PREFIX: '/api/v1/cms'
};
module.exports = env;
