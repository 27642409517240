import React from 'react';
import { Form, Input, Button, DatePicker, Modal, message, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeader from 'modules/PageHeader';
import FormItem from 'components/FormItem';
import Table from 'components/Table2';
import WrapAuth from 'components/WrapAuth';
import CommonList from 'pages/common/List';
import ImportData from 'modules/ImportData';
import DownloadTemplate from 'modules/DownloadTemplate';
import { datePickerConfig, path } from 'config';
import purchaseDiff from 'api/purchase-diff';
import { resolveTimeText } from 'utils';
import reaction from 'reaction';

import style from 'pages/page.module.scss';

/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */
const { Column } = Table;
const { FormItemGroup } = FormItem;
const { confirm } = Modal;

const { RangePicker } = DatePicker;
const priceTrendFirst = path.cms.priceTrendFirst;

@connect(({ purchaseDiff, user }) => ({ purchaseDiff, user }))
@Form.create()
class DiffPriceInfo extends CommonList {
  loadData(params) {
    reaction.purchaseDiff.fetchList(params).then(err => {
      this.setState({
        loading: false
      });
    });
  }
  handleDelete(ids) {
    confirm({
      title: '确定删除此数据吗？',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        reaction.purchaseDiff
          .delete([ids], this.props.user.username)
          .then(() => {
            message.success('删除成功');
            this.reloadData(!this.props.purchaseDiff.list.length);
          });
      }
    });
  }
  render() {
    const {
      form: { getFieldDecorator },
      purchaseDiff: { list, pagination }
    } = this.props;
    return (
      <div className="page-inner">
        <PageHeader title="日照与曹妃甸PB价差">
          <WrapAuth path={priceTrendFirst.add.path}>
            <Link to="/purchase-diff/add" className="page-btn">
              +&nbsp;新增
            </Link>
          </WrapAuth>
        </PageHeader>
        <div className={style.filter}>
          <FormItemGroup>
            <FormItem label="价格日期：" style={{ marginRight: 30 }}>
              {getFieldDecorator('rangeTime')(
                <RangePicker
                  style={{ width: 230 }}
                  onChange={this.handleChangeDate}
                  placeholder={['开始日期', '结束日期']}
                  className="range-date-picker"
                />
              )}
            </FormItem>
            <FormItem label="创建日期：" style={{ marginRight: 30 }}>
              {getFieldDecorator('createTime')(
                <DatePicker
                  format={datePickerConfig.formateDate}
                  style={{ width: 150 }}
                />
              )}
            </FormItem>
            <FormItem label="创建人：" labelWidth={52}>
              {getFieldDecorator('createUser', {
                initialValue: ''
              })(
                <Input
                  style={{ width: 150 }}
                  maxLength={10}
                  placeholder="请输入创建人名称"
                />
              )}
            </FormItem>
            <div className={style.right}>
              <Button type="primary" onClick={this.handleSearchData}>
                查询
              </Button>
              &nbsp;
              <Button type="primary" onClick={this.resetFormAndReload}>
                重置
              </Button>
            </div>
          </FormItemGroup>
        </div>
        <div className={style['table-operations']}>
          <WrapAuth path={priceTrendFirst.import.path}>
            <ImportData
              action={purchaseDiff.importData}
              onChange={this.handleSearchData}
            />
          </WrapAuth>
          <WrapAuth path={priceTrendFirst.download.path}>
            <DownloadTemplate loadData={reaction.purchaseDiff.fetchTemplate} />
          </WrapAuth>
        </div>
        <Spin spinning={this.state.loading}>
          <Table
            dataSource={list}
            loading={false}
            rowKey="id"
            pagination={this.getPageConfig(pagination)}
            borderd
          >
            <Column
              title="序号"
              key="id"
              align="center"
              width={60}
              render={(text, record, index) => {
                return index + (this.state.pagination.current - 1) * 10 + 1;
              }}
            />
            <Column
              title="时间"
              key="dateTime"
              dataIndex="dateTime"
              align="center"
              width={120}
            />
            <Column
              title="日照港价格"
              key="harborPriceOne"
              dataIndex="harborPriceOne"
              align="center"
              width={80}
            />
            <Column
              title="曹妃甸港价格"
              key="harborPriceTwo"
              dataIndex="harborPriceTwo"
              align="center"
              width={80}
            />
            <Column
              title="差价"
              key="priceSpread"
              dataIndex="priceSpread"
              align="center"
              width={80}
            />
            <Column
              title="创建人"
              key="createUser"
              dataIndex="createUser"
              align="center"
              width={80}
            />
            <Column
              title="创建日期"
              key="createTime"
              dataIndex="createTime"
              align="center"
              width={125}
              render={time => resolveTimeText(time)}
            />
            <Column
              title="修改人"
              key="updateUser"
              dataIndex="updateUser"
              align="center"
              width={80}
              // render={(updateUser) => updateUser || '--'}
            />
            <Column
              title="修改日期"
              key="updateTime"
              dataIndex="updateTime"
              align="center"
              width={125}
              render={time => (time ? resolveTimeText(time) : '--')}
            />
            <Column
              title="操作"
              key="handler"
              align="center"
              render={row => {
                return (
                  <React.Fragment>
                    <WrapAuth path={priceTrendFirst.update.path}>
                      <Link
                        className="link-handle"
                        to={`/purchase-diff/edit/${row.id}`}
                      >
                        编辑
                      </Link>
                    </WrapAuth>
                    <WrapAuth path={priceTrendFirst.delete.path}>
                      <a
                        className="link-handle"
                        onClick={() => this.handleDelete(row.id)}
                        href="javascript:;"
                      >
                        删除
                      </a>
                    </WrapAuth>
                  </React.Fragment>
                );
              }}
            />
          </Table>
        </Spin>
      </div>
    );
  }
}

export default DiffPriceInfo;
