import category from 'api/category';

const initState = {
  list: [],
  nodes: [],
  pagination: {},
  detail: {},
};

export const types = {
  saveList: 'CATEGORY_LIST',
  saveNodes: 'CATEGORY_NODES',
  saveDetail: 'CATEGORY_DETAIL',
  clear: 'CLEAR',
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.saveList:
      return Object.assign({}, state, { list: action.list, pagination: action.pagination });

    case types.saveDetail:
      return Object.assign({}, state, { detail: action.detail });
    case types.clear:
      return initState;
    case types.saveNodes:
      return Object.assign({}, state, { nodes: action.nodes })
    default:
      return state;
  }
}

reducer.fetchList = (data) => {
  return (dispatch) => {
    return category.fetchListUsePage(data).then(data => {
      dispatch({
        type: types.saveList,
        list: data.list ,
        pagination: data.pagination,
      });
      return data;
    });
  }
}

reducer.fetchNodes = (data = { parentId: 0, showSub: true }) => {
  return (dispatch) => {
    return category.fetchList(data).then(nodes => {
      dispatch({
        type: types.saveNodes,
        nodes,
      });
      return nodes;
    });
  }
}

reducer.update = reducer.add = (data) => {
  return () => {
    return category.save(data);
  }
}

reducer.delete = (id, handlerName) => {
  return (dispatch) => {
    return category.delete(id, handlerName);
  }
}

reducer.fetchDetail = (id) => {
  return (dispatch) => {
    return category.detail(id).then(data => {
      // if (res.code === 0)
      dispatch({
        type: types.saveDetail,
        detail: data,
      });
      return data;
    });
  }
}

reducer.clear = () => {
  return (dispatch) => {
    dispatch({
      type: types.clear,
    });
  }
}

export default reducer;
