import ports from 'api/purchase-ports';

const initState = {
  list: [],
  pagination: {},
  detail: {},
  codesList: [],
  template: ''
};

export const types = {
  saveList: 'PURCHASE_PORTS_LIST',
  saveDetail: 'PURCHASE_PORTS_DETAIL',
  saveTemplate: 'PURCHASE_PORTS_TEMPLATE',
  fetchCodes: 'PURCHASE_PORTS_CODES ',
  clear: 'PURCHASE_PORTS_CLEAR'
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.saveList:
      return Object.assign({}, state, {
        list: action.list,
        pagination: action.pagination
      });
    case types.fetchCodes:
      return Object.assign({}, state, { codesList: action.list });
    case types.saveDetail:
      return Object.assign({}, state, { detail: action.detail });
    case types.saveTemplate:
      return Object.assign({}, state, { template: action.template });
    case types.clear:
      return initState;
    default:
      return state;
  }
};

reducer.fetchList = data => {
  return dispatch => {
    return ports.fetchList(data).then(data => {
      dispatch({
        type: types.saveList,
        list: data.list,
        pagination: data.pagination
      });
      return data;
    });
  };
};

reducer.fetchCodes = data => {
  return dispatch => {
    return ports.fetchCodesList(data).then(data => {
      dispatch({
        type: types.fetchCodes,
        list: data
      });
      return data;
    });
  };
};
reducer.add = data => {
  return () => {
    return ports.add(data);
  };
};

reducer.update = data => {
  return () => {
    return ports.save(data);
  };
};

reducer.delete = (ids, handlerName) => {
  return dispatch => {
    return ports.delete(ids, handlerName);
  };
};

reducer.fetchDetail = id => {
  return dispatch => {
    return ports.detail(id).then(data => {
      dispatch({
        type: types.saveDetail,
        detail: data || {}
      });
      return data;
    });
  };
};

reducer.fetchTemplate = () => {
  return dispatch => {
    return ports.fetchTemplateUrl().then(data => {
      dispatch({
        type: types.saveTemplate,
        template: data
      });
      return data;
    });
  };
};

reducer.clear = () => {
  return dispatch => {
    dispatch({
      type: types.clear
    });
  };
};

export default reducer;
