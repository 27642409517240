import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import RangeInput from 'components/RangeInput';
import { appPlatforms, rules } from 'config';
import Select from 'components/Select2';
import Upload from 'components/Upload2';
import CommonEdit from 'pages/common/Edit';
import { connect } from 'react-redux';
import reaction from 'reaction';

import style from 'pages/page.module.scss';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
}

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

@connect(({ user, appTheme }) => ({ user, appTheme }))
@Form.create()
@withRouter
class AppThemeEdit extends CommonEdit {
  mode = 'edit';
  submitData(data) {
    reaction.appTheme.update(data)
      .then(err => {
        this.setState({
          submitting: false,
        });
        message.success('修改成功');
        this.props.history.replace('/app-theme');
      }).catch(err => {
        this.setState({
          submitting: false,
        });
      });
  }
  loadData(id) {
    reaction.appTheme.fetchDetail(id).then(({ startTime }) => {
      this.setState({
        startTime,
        loading: false,
      });
    });
  }
  clear() {
    reaction.appTheme.clear();
  }
  getRenderContent() {
    let {
      form: {
        getFieldDecorator,
      },
      appTheme: {
        detail,
      },
    } = this.props;
    const {
      startTime
    } = this.state;
    detail = detail || {};
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>编辑app主题</span>
        </div>
        <div className={`${style.form} page-form`}>
          {
            getFieldDecorator('id', {
              initialValue: detail.id,
            })(
              <Input hidden />
            )
          }
          {
            getFieldDecorator('themeName', {
              initialValue: detail.id,
            })(
              <Input hidden />
            )
          }
          <Form.Item
            label="主题名称"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('themeName', {
                initialValue: detail.themeName,
                rules: [{
                  required: true,
                  message: '请输入主题名称',
                }],
              })(
                <Input placeholder="请输入主题名称" style={{ width: 195 }} />
              )
            }
          </Form.Item>
          <Form.Item
            label="平台"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('platform', {
                initialValue: detail.platform,
                rules: [{
                  required: true,
                  message: '请选择平台',
                }],
              })(
                <Select
                  placeholder="请选择"
                  options={appPlatforms}
                  style={{ width: 195 }}
                />
              )
            }
          </Form.Item>
          <Form.Item
            label="版本范围"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('version', {
                initialValue: detail.version,
                rules: [{
                  validator: rules.versionsValidator(),
                }],
              })(
                <RangeInput
                  placeholders={[
                    '最低版本名',
                    '最高版本名'
                  ]}
                  options={appPlatforms}
                  style={{ width: 195 }}
                />
              )
            }
            <p className={style.tip}>版本名格式a.b.c，如：1.0.0</p>
          </Form.Item>
          {
            detail.startTime && this.renderStartTime(detail.startTime)
          }
          {
            detail.expireTime && this.renderEndTime(startTime, detail.expireTime)
          }
          <Form.Item
            label="启动图"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('themeImage', {
                initialValue: detail.themeImage,
                rules: [{
                  required: true,
                  message: '请上传启动图',
                }],
              })(
                <Upload />
              )
            }
            <p className={style.tip}>请上传JPG、PNG格式.</p>
          </Form.Item>
          <Form.Item
            label="资源包"
            {...formItemLayout}
            // required
          >
            {
              getFieldDecorator('themeData', {
                initialValue: detail.themeData,
                // rules: [{
                //   required: true,
                //   message: '请上传资源包',
                // }],
              })(
                <Upload
                  listType="text"
                  accept=".zip,.rar"
                />
              )
            }
            <p className={style.tip}>请上传zip、rar格式.</p>
          </Form.Item>
          <Form.Item
            label=""
            {...formTailLayout}
            required
          >
            <Button type="primary" className={style.button} onClick={this.handlePublish} loading={this.state.submitting}>保存</Button>
            <Link className={`ant-btn ${style.button} ${style['button-back']}`} to="/app-theme">返回</Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default AppThemeEdit;
