import React from 'react';
import { Form, Button, message, Input } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'components/Select2';
import Upload from 'components/Upload2';
import CommonEdit from 'pages/common/Edit';
import reaction from 'reaction';

import style from 'pages/page.module.scss';
import { webThemePages } from 'config';
import ColorPicker from 'components/ColorPicker';

const formItemLayout = {
  labelCol: {
    xs: 8,
    sm: 8
  },
  wrapperCol: {
    xs: 16,
    sm: 16,
  },
}

const formTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

@connect(({ user }) => ({ user }))
@Form.create()
@withRouter
class WebThemeAdd extends CommonEdit {
  mode = 'add';
  submitData(data) {
    reaction.webTheme.add(data)
      .then(err => {
        this.setState({
          submitting: false,
        });
        message.success('添加成功');
        this.props.history.replace('/web-theme');
      }).catch(err => {
        this.setState({
          submitting: false,
        });
      });
  }
  render() {
    const {
      form: {
        getFieldDecorator,
      },
    } = this.props;
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <span className={style.title}>web主题添加</span>
        </div>
        <div className={`${style.form} page-form`}>
          <Form.Item
            label="主题名称"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('themeName', {
                rules: [{
                  required: true,
                  message: '请输入主题名称',
                }],
              })(
                <Input placeholder="请输入主题名称" style={{ width: 195 }} />
              )
            }
          </Form.Item>
          <Form.Item
            label="页面"
            labelWidth={72}
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('positions', {
                rules: [{
                  required: true,
                  message: '请选择页面',
                }],
              })(
                <Select
                  options={webThemePages}
                  mode="multiple"
                  style={{ width: 520 }}
                />
              )
            }
          </Form.Item>
          {
            this.renderStartTime()
          }
          {
            this.renderEndTime(this.state.startTime)
          }

          <Form.Item
            label="背景颜色"
            {...formItemLayout}
          >
            {
              getFieldDecorator('backColor', {
                initialValue: '#ffffff',
              })(
                <ColorPicker />
              )
            }
          </Form.Item>
          <Form.Item
            label="背景图片"
            {...formItemLayout}
            // required
          >
            {
              getFieldDecorator('backImage', {
                // rules: [{
                //   required: true,
                //   message: '请上传背景图片',
                // }],
              })(
                <Upload
                  accept=".jpg,.png"
                  showUploadList
                />
              )
            }
            <p className={style.tip}>请上传JPG、PNG格式.</p>
          </Form.Item>
          <Form.Item
            label="主题图片"
            {...formItemLayout}
            required
          >
            {
              getFieldDecorator('themeImage', {
                rules: [{
                  required: true,
                  message: '请上传主题图片',
                }],
              })(
                <Upload />
              )
            }
            <p className={style.tip}>请上传JPG、PNG格式.</p>
          </Form.Item>
          <Form.Item
            label=""
            {...formTailLayout}
            required
          >
            <Button type="primary" className={style.button} onClick={this.handlePublish} loading={this.state.submitting}>发布</Button>
            <Link className={`ant-btn ${style.button} ${style['button-back']}`} to="/web-theme">返回</Link>
          </Form.Item>
        </div>
      </div>
    );
  }
}

export default WebThemeAdd;
