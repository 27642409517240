// import 'es6-promise'
import axios from 'axios';
import { API_PREFIX } from '../config/env';
let noop = function(res) {
  return res;
};

axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
let http = {
  $afterHook: noop,
  $dataHook: noop,
  $urlGetter: noop,
  $tokenGetter: noop,
  $tokenIsValid: noop,
  $authHook: noop,
  $errorHook: noop,
  $loadingHook: noop,
  $codeHook: noop,
  $validateStatus: function(status) {
    return status >= 200 && status < 300;
  },
  validateStatus(validate) {
    http.$validateStatus = validate;
    return http;
  },
  url(urlGetter) {
    http.$urlGetter = urlGetter;
    return http;
  },
  token(tokenGetter) {
    http.$tokenGetter = tokenGetter;
    return http;
  },
  tokenIsValid(tokenIsValid) {
    this.$tokenIsValid = tokenIsValid;
    return http;
  },
  authHook(authHook) {
    this.$authHook = authHook;
    return http;
  },
  codeHook(codeHook) {
    this.$codeHook = codeHook;
    return http;
  },
  errorHook(errorHook) {
    this.$errorHook = errorHook;
    return http;
  },
  loadingHook(loadingHook) {
    this.$loadingHook = loadingHook;
    return http;
  },
  request(url, data = {}, options = {}) {
    let {
      withCredentials = true,
      timeout = 30000,
      method = 'post',
      showLoading = false,
      baseURL = API_PREFIX
    } = options;
    if (withCredentials) {
      data.token = http.$tokenGetter();
    }
    showLoading && http.$loadingHook(true);
    method = method.toLocaleLowerCase();
    let o = {
      method,
      withCredentials,
      url: http.$urlGetter(url),
      timeout,
      baseURL,
      data: { ...data } || {},
      validateStatus: function(status) {
        return http.$validateStatus(status);
      }
    };
    if (method === 'get') {
      o.params = {
        ...data
      };
      delete o.data;
    } else {
      o.headers = {
        'Content-Type': 'application/json'
      };
    }
    // 服务端返回的数据有的属性带有空格 回传时在这里统一去除 避免引发异常
    for (let k in o.data) {
      let v = o.data[k];
      if (typeof v === 'string') {
        o.data[k] = v.trim();
      }
    }

    return axios(o)
      .then(response => {
        return response.data;
      }, noop)
      .then(response => http.$codeHook(response), noop)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        showLoading && http.$loadingHook(false);
        http.$errorHook(url, data, err); // 错误处理
        return Promise.reject({
          respCode: 500,
          respDesc: '',
          data: []
        });
      });
  }
};

export default http;
